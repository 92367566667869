/* eslint-disable no-nested-ternary */
import { useContext, useMemo } from 'react';
import classNames from 'classnames';
import mutedAudioIcon from '../../../../../assets/micOff.svg';
import micOn from '../../../../../assets/micOn.svg';
import './Microphone.scss';
import ZoomContext from '../context/ZoomContext';
import { MediaDevice } from '../constants/VideoTypes';
import VideoRoomControl from '../../../VideoRoomControl';

interface MicrophoneButtonProps {
  isStartedAudio: boolean;
  isMuted: boolean;
  isMicrophoneForbidden?: boolean;
  // disabled?: boolean;
  audio?: string;
  onMicrophoneClick: () => void;
  // onMicrophoneMenuClick: (key: string) => void;
  className?: string;
  microphoneList?: MediaDevice[];
  // speakerList?: MediaDevice[];
  activeMicrophone?: string;
  // activeSpeaker?: string;
}
function MicrophoneButton(props: MicrophoneButtonProps) {
  const {
    isStartedAudio,
    isMuted,
    audio,
    className,
    microphoneList,
    // speakerList,
    activeMicrophone,
    // activeSpeaker,
    // disabled,
    isMicrophoneForbidden,
    onMicrophoneClick,
    // onMicrophoneMenuClick,
  } = props;

  const { permissionStatus } = useContext(ZoomContext);

  // const [showMicMenu, setShowMicMenu] = useState(false);

  // const tooltipText = isStartedAudio
  //   ? isMuted
  //     ? 'unmute'
  //     : 'mute'
  //   : 'start audio';

  const microphoneMenuList =
    microphoneList?.map((i) => ({
      label: i.label,
      value: `microphone|${i.deviceId}`,
    })) || [];

  // const speakerMenuList =
  //   speakerList?.map((i) => ({
  //     label: i.label,
  //     value: `speaker|${i.deviceId}`,
  //   })) || [];

  // const onMenuItemClick = (payload: { key: any }) => {
  //   onMicrophoneMenuClick(payload.key);
  // };

  console.log('mic', activeMicrophone, microphoneMenuList);

  const audioIcon = useMemo(() => {
    if (isStartedAudio) {
      if (isMuted) {
        return mutedAudioIcon;
      }
      return micOn;
    }
    if (isMicrophoneForbidden) {
      return mutedAudioIcon;
    }
    return mutedAudioIcon;
  }, [audio, isMuted, isMicrophoneForbidden, isStartedAudio]);

  return (
    <div className={classNames('microphone-camera-footer', className)}>
      <VideoRoomControl
        id="zoom-microphone-button"
        color="primary"
        altText={isMuted ? 'Unmute mic' : 'Mute mic'}
        icon={audioIcon}
        onClick={onMicrophoneClick}
        disabled={permissionStatus !== 'granted'}
      />
    </div>
  );
}

export default MicrophoneButton;
