import moment from 'moment';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../../utilities/hooks/useAuth';
import { PresentingIssueAuthor } from '../../../shared/constant/Clients';
import { ClientProfileDateFormat } from '../../../utilities/common/Date';
import BasicCardTemplate from '../../components/BasicCardTemplate';
import InfoTile from '../../components/InfoTile';
import UnOrderedList from '../../components/UnOrderedList';
import ClientDetailsContext from './context/ClientDetailsContext';
import ClientOverviewCardLoader from './ClientOverviewCardLoader';

export default function UserPresentingIssuesCard() {
  const { clientDetailData, isLoading } = useContext(ClientDetailsContext);

  const { user } = useAuth();
  const { t } = useTranslation();

  const clientName = clientDetailData?.profile?.name;
  const presentingIssue = clientDetailData?.presentingIssue;
  const addedBy = presentingIssue?.addedBy;
  let authorName = '';

  if (addedBy !== undefined) {
    if (addedBy === 0) {
      authorName = t('ADDED_BY_ACTOR_PROVIDER', {
        provider_name: user?.name ?? '',
      });
    } else if (addedBy === 1) {
      authorName = clientDetailData?.profile?.name || '-';
    } else {
      authorName = PresentingIssueAuthor?.[addedBy];
    }
  }

  const PISubContentItems = [];

  if (authorName) {
    PISubContentItems.push({
      label: (
        <>
          Added By <span className="text-gray-900">{authorName}</span>
        </>
      ),
    });
  }

  if (presentingIssue) {
    PISubContentItems.push({
      label: moment(presentingIssue?.createdAt).format(ClientProfileDateFormat),
    });
  }

  if (isLoading) {
    return <ClientOverviewCardLoader variant="card" />;
  }

  return (
    <BasicCardTemplate heading={t('PRESENTING_ISSUES_HEADER')} showToolTip>
      {!presentingIssue?.sessionCompleted && (
        <InfoTile
          lineClamp={2}
          variant="error"
          contentClassName="text-error-600"
          showIcon
          content={`Book a session and record ${clientName}’s presenting issues for a smooth payment process`}
        />
      )}

      {presentingIssue?.sessionCompleted && presentingIssue?.pending ? (
        <InfoTile
          lineClamp={2}
          variant="error"
          contentClassName="text-error-600"
          showIcon
          content={`Please record ${clientName}'s presenting issues in all case-notes for a smooth payment process`}
        />
      ) : null}

      {presentingIssue?.issue ? (
        <InfoTile
          variant="primary"
          content={
            <span className="text-xs font-semibold text-gray-900">
              {presentingIssue?.issue ?? '-'}
            </span>
          }
          subContent={
            <UnOrderedList
              className="text-gray-500 flex flex-wrap"
              items={PISubContentItems}
            />
          }
        />
      ) : null}
    </BasicCardTemplate>
  );
}
