import { useFetch } from '../../../../../utilities/hooks/useFetch';
import {
  getProviderAvailabilities,
  getProviderOverrides,
  getProviderServicesAndClinics,
} from '../../../../shared/service/API/availability';
import {
  IProviderAvailabilityOverrides,
  IProviderRawAvailability,
  IProviderServicesAndClinic,
} from '../../../../shared/types/Availability';

export function useProviderAvailabilities() {
  return useFetch<IProviderRawAvailability>(
    ['provider-v2-availabilities'],
    () => getProviderAvailabilities(),
    {
      refetchOnWindowFocus: false,
    },
  );
}

export function useProviderServicesAndClinics() {
  return useFetch<IProviderServicesAndClinic>(
    ['provider-v2-services-and-clinics'],
    () => getProviderServicesAndClinics(),
    {
      refetchOnWindowFocus: false,
    },
  );
}

export function useProviderOverrides() {
  return useFetch<IProviderAvailabilityOverrides>(
    ['provider-v2-overrides'],
    () => getProviderOverrides(),
    {
      refetchOnWindowFocus: false,
    },
  );
}
