export enum ProviderRole {
  COACHING = '0',
  CLINICAL = '1',
  HELPLINE = '2',
  CARENAVIGATOR = '4',
  NUTRITIONIST = '5',
  FITNESS = '6',
  FINANCIAL = '7',
  EXECUTIVE = '8',
  LEGAL = '9',
  CAREOFFSITE = '10',
  ONSITE = '12',
}
