import { ProviderRole } from './Common';

export enum ClientStatus {
  INACTIVE = '0',
  ACTIVE = '1',
  MATCHED = '2',
  BOOKED = '3',
  UNMATCHED_CLIENTS = '4',
}

export enum RiskLevelsEnum {
  NO_RISK = '0',
  LOW_RISK = '1',
  MED_RISK = '2',
  HIGH_CRITICAL_RISK = '3',
  HIGH_NON_CRITICAL_RISK = '4',
  RISK_NOT_ASSESSED = '5',
  OTHER = '6',
}

export const ClientGenderMappings: Record<string, string> = {
  m: 'MALE',
  f: 'FEMALE',
  NA: 'Gender undisclosed',
};

export enum ClientECPRelation {
  PARTNER = '0',
  PARENT = '1',
  GUARDIAN = '2',
  SIBLING = '3',
  RELATIVE = '4',
  OTHER = '5',
}

export const ClientECPRelationLabelMapping: Record<string, string> = {
  [ClientECPRelation.PARTNER]: 'Partner',
  [ClientECPRelation.PARENT]: 'Parent',
  [ClientECPRelation.GUARDIAN]: 'Guardian',
  [ClientECPRelation.SIBLING]: 'Sibling',
  [ClientECPRelation.RELATIVE]: 'Relative',
  [ClientECPRelation.OTHER]: 'Other',
};

export enum PresentingIssueAuthor {
  Provider,
  User,
  Responder, // Ranger
  CareNav,
}

const ProviderRoleCategories: Record<string, string[]> = {
  coaching: [ProviderRole.COACHING],
  holistic: [
    ProviderRole.NUTRITIONIST,
    ProviderRole.FITNESS,
    ProviderRole.FINANCIAL,
    ProviderRole.EXECUTIVE,
    ProviderRole.LEGAL,
  ],
  clinical: [
    ProviderRole.CLINICAL,
    ProviderRole.CAREOFFSITE,
    ProviderRole.ONSITE,
  ],
};

export const RiskLevelsMappings = [
  {
    label: 'noRisk',
    value: RiskLevelsEnum.NO_RISK,
    role: [
      ...ProviderRoleCategories.coaching,
      ...ProviderRoleCategories.holistic,
    ],
  },
  {
    label: 'lowRisk',
    value: RiskLevelsEnum.LOW_RISK,
    role: [
      ...ProviderRoleCategories.coaching,
      ...ProviderRoleCategories.clinical,
      ...ProviderRoleCategories.holistic,
    ],
  },
  {
    label: 'medRisk',
    value: RiskLevelsEnum.MED_RISK,
    role: [
      ...ProviderRoleCategories.coaching,
      ...ProviderRoleCategories.clinical,
      ...ProviderRoleCategories.holistic,
    ],
  },
  {
    label: 'highNonCriticalRisk',
    value: RiskLevelsEnum.HIGH_NON_CRITICAL_RISK,
    role: [
      ...ProviderRoleCategories.coaching,
      ...ProviderRoleCategories.clinical,
      ...ProviderRoleCategories.holistic,
    ],
  },
  {
    label: 'highCriticalRisk',
    value: RiskLevelsEnum.HIGH_CRITICAL_RISK,
    role: [
      ...ProviderRoleCategories.coaching,
      ...ProviderRoleCategories.clinical,
      ...ProviderRoleCategories.holistic,
    ],
  },
  {
    label: 'riskNotAssessed',
    value: RiskLevelsEnum.RISK_NOT_ASSESSED,
    role: [
      ...ProviderRoleCategories.coaching,
      ...ProviderRoleCategories.holistic,
    ],
  },
];

export enum ClientAssignmentStatusEnum {
  ASSIGNED = 'assigned',
  STARTED = 'started',
  COMPLETED = 'completed',
}

export const ClientAssignmentStatusMappings: Record<
  string,
  {
    trackStatus: number;
    cta: string | null;
    variant: string;
    label: string;
  }
> = {
  [ClientAssignmentStatusEnum.ASSIGNED]: {
    trackStatus: 0,
    cta: 'remindCTA',
    variant: 'warningOutline',
    label: 'notStarted',
  },
  [ClientAssignmentStatusEnum.STARTED]: {
    trackStatus: 1,
    cta: 'remindCTA',
    variant: 'infoOutline',
    label: 'inProgress',
  },
  [ClientAssignmentStatusEnum.COMPLETED]: {
    trackStatus: 2,
    cta: null,
    variant: 'successOutline',
    label: 'completed',
  },
};

// TODO localise
export const ClientAssignmentCategoryMapping: Record<string, string> = {
  course: 'Learning Path',
  rescue: 'Rescue Session',
  journal: 'Journal',
  toolkit: 'Exercises',
  periodic: 'Periodic',
  questionnaire: 'Questionnaire',
  custom: 'External Resources',
  video: 'Video',
  meditation: 'Meditation',
};

export const RolesToNotShowInCreditDetailsModal = [
  ProviderRole.EXECUTIVE,
  ProviderRole.CAREOFFSITE,
  ProviderRole.ONSITE,
  '11',
];
