import { useFetch } from '../../../../../utilities/hooks/useFetch';
import { getProviderMeetings } from '../../../../shared/service/API/availability';
import { ISessionListItem } from '../../ClientDetails/types/session';

export function useProviderMeetings(
  fromDate: string,
  toDate: string,
  timezone: string,
) {
  return useFetch<ISessionListItem[]>(
    ['provider-availability-v2', fromDate, toDate, timezone],
    () => getProviderMeetings(fromDate, toDate, timezone),
    {
      refetchOnWindowFocus: false,
    },
  );
}
