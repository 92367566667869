import * as React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import useAuth from './utilities/hooks/useAuth';
import useTracking from './utilities/hooks/useTracking';
import { setUserId } from './utilities/monitoring';

function ProtectedRoutes() {
  const { user, isLoggedIn } = useAuth();
  const { user: userProfile } = useTracking();
  const { pathname } = useLocation();

  const setupUserProfileForAnalytics = () => {
    const { id, createdAt, updatedAt } = user;

    userProfile.initialize(id);
    userProfile.setProperties({
      userId: id,
      created: createdAt,
      updated: updatedAt,
    });
  };

  React.useEffect(() => {
    if (isLoggedIn) {
      setupUserProfileForAnalytics();
      setUserId(user.id);
    }
  }, []);

  return isLoggedIn ? (
    <motion.div
      key={pathname}
      variants={{
        initial: {
          opacity: 0,
        },
        in: {
          opacity: 1,
        },
        out: {
          opacity: 0,
          transition: {
            duration: 0.15,
            type: 'tween',
          },
        },
      }}
      initial="initial"
      animate="in"
      exit="out"
      transition={{ ease: 'easeIn', duration: 0.35 }}
    >
      <Outlet />
    </motion.div>
  ) : (
    <Navigate to="/login" />
  );
}

export default ProtectedRoutes;
