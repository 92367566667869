import { Participant, VideoQuality } from '@zoom/videosdk';
import { useContext, useRef, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SELF_VIDEO_ID } from '../../constants/VideoConstants';
import { useCanvasDimension } from '../../hooks/useCanvasDimension';
import { useParticipantsChange } from '../../hooks/useParticipantsChange';
import { usePrevious } from '../../hooks/usePrevious';
import ZoomHeader from '../Header/Header';
import ShareView from '../ShareView';
import VideoFooter from '../VideoFooter';
import ZoomContext from '../../context/ZoomContext';
import './VideoBase.scss';

function VideoContainer() {
  const { zmClient, mediaStream, meetingData } = useContext(ZoomContext);

  const remoteRef = useRef<HTMLCanvasElement | null>(null);
  const selfVideoCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const shareViewRef = useRef<{
    selfShareRef: HTMLCanvasElement | HTMLVideoElement | null;
  }>(null);
  const [isReceiveSharing, setIsReceiveSharing] = useState(false);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const canvasDimension = useCanvasDimension(mediaStream, remoteRef);

  useParticipantsChange(zmClient, (payload) => {
    setParticipants(payload);
  });

  const remoteUser = useMemo(
    () => (participants.length >= 2 ? participants[1] : null),
    [participants],
  );
  const prevRemoteUser = usePrevious(remoteUser);

  const { t } = useTranslation();

  const localUser = zmClient.getCurrentUserInfo();

  useEffect(() => {
    const startVideo = async () => {
      if (mediaStream && remoteRef.current) {
        if (remoteUser?.bVideoOn) {
          try {
            await mediaStream.renderVideo(
              remoteRef.current,
              remoteUser.userId,
              canvasDimension.width,
              canvasDimension.height,
              0,
              0,
              VideoQuality.Video_720P,
            );
          } catch (error) {
            console.log('caught start', error);
          }
        } else if (remoteUser?.userId && prevRemoteUser?.bVideoOn) {
          try {
            await mediaStream.stopRenderVideo(
              remoteRef.current,
              remoteUser.userId,
            );

            mediaStream.detachVideo(remoteUser.userId);
          } catch (error) {
            console.log('caught stop', error, remoteRef);
          }
        }
      }
    };

    startVideo();
  }, [
    mediaStream,
    zmClient,
    remoteUser?.bVideoOn,
    remoteUser?.userId,
    canvasDimension.height,
    canvasDimension.width,
  ]);

  return (
    <div className="zoom-video-container">
      {meetingData ? <ZoomHeader meetingData={meetingData} /> : null}
      <div className="viewport">
        <ShareView
          ref={shareViewRef}
          onRecieveSharingChange={setIsReceiveSharing}
        />
        <div
          // className="one-to-one-container"
          className={`one-to-one-container ${
            isReceiveSharing ? 'video-container-in-sharing' : ''
          }`}
        >
          {/* {mediaStream?.isRenderSelfViewWithVideoElement() ? (
          <video
            id="video-canvas"
            autoPlay
            muted
            playsInline
            className="video-canvas"
          />
        ) : (
          <canvas
            className="video-canvas"
            id="video-canvas"
            width="500px"
            height="500px"
            ref={videoRef}
          />
        )} */}
          <div className="user-video">
            {/* <div className="single-video-wrap">
           
          </div> */}
            <canvas
              width="100%"
              height="100%"
              ref={remoteRef}
              className={`remote-user ${!!remoteUser}`}
            />

            <div className={`info-box ${!remoteUser}`}>
              {t('waitingForOtherParticipant')}
            </div>

            {remoteUser ? (
              <div className="name-tag">{remoteUser.displayName}</div>
            ) : null}
          </div>
          <div className="video-sidebar">
            <div className="user-video">
              {mediaStream?.isRenderSelfViewWithVideoElement() ? (
                <video id={SELF_VIDEO_ID} autoPlay muted playsInline />
              ) : (
                <canvas id={SELF_VIDEO_ID} ref={selfVideoCanvasRef} />
              )}
              {localUser && (
                <div className="name-tag">{localUser?.displayName}(You)</div>
              )}
            </div>
          </div>
        </div>
        <VideoFooter
          className="video-operations"
          selfShareCanvas={shareViewRef.current?.selfShareRef}
        />
      </div>
    </div>
  );
}

export default VideoContainer;
