import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Carousel from '../../../../components/Carousel';
import Modal from '../../../../components/Modal';
import { useClientBenefits } from '../../hooks/UseClientBenefits';
import ClientOverviewCardLoader from '../../ClientOverviewCardLoader';
import { ICLientBenefit } from '../../types/client';
import { CreditCarouselCardTextData } from '../../constants';

export function CreditDetailsModal({
  open,
  handleClose,
  clientName,
  userId,
}: {
  open: boolean;
  handleClose: () => void;
  clientName: string;
  userId?: string;
}) {
  const { data, isLoading } = useClientBenefits(userId);
  const { t } = useTranslation();

  const getCreditText = (creditData: ICLientBenefit) => {
    let creditString = '0';
    if (creditData.tier === '1') {
      creditString = t('unlimited');
    } else if (creditData.tier === '0') {
      if (creditData.poolCredits > 0) {
        if (!creditData.credit) {
          creditString = creditData.poolCredits.toString();
        } else if (creditData.credit > 0) {
          creditString = creditData.credit.toString();
        } else creditString = creditData.poolCredits.toString();
      } else if (!creditData.poolCredits && creditData.credit > 0) {
        creditString = creditData.credit.toString();
      }
    }

    return {
      expiresOn: moment(creditData.expiresAt).format('DD MMM, YYYY'),
      creditString,
    };
  };

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      className="w-[50vw]"
      title={t('POPUP_HEADER', { client_name: clientName })}
    >
      <div className="text-lg font-semibold mb-0.5">
        {t('PLAN_DETAILS_HEADER')}
      </div>
      <div className="text-sm mb-3">{t('PLAN_DETAILS_SUBTEXT')}</div>

      {isLoading || !data ? (
        <ClientOverviewCardLoader variant="card" />
      ) : (
        <Carousel<ICLientBenefit>
          data={data}
          renderItem={(item) => {
            const carouselCardDisplayData =
              CreditCarouselCardTextData[
                item.type as keyof typeof CreditCarouselCardTextData
              ];

            const carouselCardCreditData = getCreditText(item);

            return (
              <div className="grid grid-rows-8 gap-y-3 p-3 w-44 min-w-44 h-48 text-xs border border-gray-100 bg-gray-25 rounded-lg">
                <div className="row-span-2">{carouselCardDisplayData.icon}</div>
                <div className="row-span-4">
                  <div className="font-medium text-primary-700 mb-1">
                    {t(carouselCardDisplayData.title)}
                  </div>
                  <div className="mb-3">
                    {t(carouselCardDisplayData.subtitle)}
                  </div>
                </div>
                <div className="row-span-2">
                  <div className="font-semibold">
                    {carouselCardCreditData.creditString}
                  </div>
                  <div>
                    {t('VALID_TILL_EXPIRY', {
                      date: carouselCardCreditData.expiresOn,
                    })}
                  </div>
                </div>
              </div>
            );
          }}
        />
      )}
    </Modal>
  );
}
