import moment from 'moment';
import { Calendar, CalendarProps, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { IAvailabilityEventItem } from '../../../shared/types/Availability';

// TODO manage timezone here
const localizer = momentLocalizer(moment);

function AvailabilityCalendar(
  props: Omit<CalendarProps<IAvailabilityEventItem>, 'localizer'>,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Calendar {...props} localizer={localizer} />;
}

export default AvailabilityCalendar;
