import {
  useState,
  useEffect,
  useContext,
  useCallback,
  forwardRef,
} from 'react';
import { ShareStatus } from '@zoom/videosdk';
import { SHARE_CANVAS_ID } from '../constants/VideoConstants';
import './ShareBar.scss';
import ZoomContext from '../context/ZoomContext';
import Button from '../../../../components/Button';

const ShareBar = forwardRef((_props: unknown, ref: any) => {
  const { mediaStream, zmClient } = useContext(ZoomContext);
  const [status, setStatus] = useState<ShareStatus | undefined>(
    mediaStream?.getShareStatus(),
  );

  const onShareStatusChange = useCallback(() => {
    if (status !== mediaStream?.getShareStatus()) {
      setStatus(mediaStream?.getShareStatus());
    }
  }, [status, mediaStream]);
  useEffect(() => {
    zmClient.on('user-updated', onShareStatusChange);
    return () => {
      zmClient.off('user-updated', onShareStatusChange);
    };
  }, [zmClient, onShareStatusChange]);

  return (
    <div
      className={`screen-share-control-bar ${
        status === ShareStatus.End ? 'share-bar-hide' : ''
      }
`}
    >
      <div className="share-bar-tip">
        {status === ShareStatus.Sharing
          ? "You're sharing your screen"
          : 'Screen sharing is paused'}
      </div>
      {mediaStream?.isStartShareScreenWithVideoElement() ? (
        <video id={SHARE_CANVAS_ID} className="share-bar-canvas" ref={ref} />
      ) : (
        <canvas id={SHARE_CANVAS_ID} className="share-bar-canvas" ref={ref} />
      )}

      <Button
        variant="caution"
        // icon={<IconFont type="icon-stop" />}
        onClick={() => {
          mediaStream?.stopShareScreen();
        }}
        label="Stop share"
      />
    </div>
  );
});

export default ShareBar;
