import { ToolbarProps } from 'react-big-calendar';
import { IAvailabilityEventItem } from '../../../shared/types/Availability';

function AvailabilityToolbar(
  props: ToolbarProps<IAvailabilityEventItem, object>,
) {
  return (
    <div className="flex items-center justify-center p-2">
      <span className="text-sm font-bold">{props?.label ?? ''}</span>
    </div>
  );
}

export default AvailabilityToolbar;
