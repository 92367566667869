import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Popup from '../../components/Popup';
import Typography from '../../components/Typography';
import './ParticipantLeftPopup.scss';

interface IParticipantLeftPopup {
  onLeaveClick: () => void;
  onStayClick: () => void;
}

function ParticipantLeftPopup({
  onLeaveClick,
  onStayClick,
}: IParticipantLeftPopup) {
  const { t } = useTranslation();

  return (
    <Popup withBackdrop showCloseIcon={false} onClose={onStayClick}>
      <div className="participant-left-popup-container">
        <Typography weight="400" size={16}>
          {t('participantLeftSession')}
        </Typography>
        <div className="confirm-actions">
          <Button
            width="180px"
            label={t('stayHere')}
            variant="primary"
            onClick={onStayClick}
          />
          <Button
            width="180px"
            label={t('endSession')}
            variant="caution"
            onClick={onLeaveClick}
          />
        </div>
      </div>
    </Popup>
  );
}

export default ParticipantLeftPopup;
