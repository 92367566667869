import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

const variants = {
  success: {
    base: 'text-green-800 bg-green-25 border-green-400',
    icon: '',
    button: '',
  },
  warning: {
    base: 'text-warning-800 bg-warning-25 border-warning-400',
    icon: '',
    button: '',
  },
  error: {
    base: 'text-error-800 bg-error-25 border-error-400',
    icon: '',
    button: '',
  },
  rose: {
    base: 'text-red-800 bg-red-25 border-red-400',
    icon: '',
    button: '',
  },
};

interface AlertProps {
  variant?: keyof typeof variants;
  title: string;
  icon?: JSX.Element;
  button?: { icon: JSX.Element; onClick: () => void };
}
export function Alert({
  variant = 'success',
  title,
  icon,
  children,
  button,
}: PropsWithChildren<AlertProps>) {
  return (
    <div
      className={twMerge(
        'grid px-3 py-2 gap-3 border-l-2',
        button ? 'grid-cols-4' : 'grid-cols-3',
        variants[variant].base,
      )}
    >
      <div className="col-span-3">
        <div className={twMerge('flex gap-2 items-center justify-start')}>
          {icon || (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle cx="4" cy="4" r="3" fill="currentColor" />
            </svg>
          )}
          <div className="font-semibold text-md">{title}</div>
        </div>

        {children ? (
          <div className="py-4 text-sm">
            {children} {variant}
          </div>
        ) : null}
      </div>

      {button ? (
        <button
          type="button"
          className={twMerge('p-2 w-4 col-span-1', variants[variant].button)}
        >
          {button.icon}
        </button>
      ) : null}
    </div>
  );
}
