import * as React from 'react';
import './VideoRoom.scss';
import {
  connect as connectRoom,
  Room,
  RemoteParticipant,
  createLocalVideoTrack,
  createLocalTracks,
  isSupported,
} from 'twilio-video';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import VideoRoomParticipant from '../../VideoRoomParticipant';
import callIcon from '../../../../assets/call-end.svg';
import mutedAudioIcon from '../../../../assets/micOff.svg';
import mutedVideoIcon from '../../../../assets/videoOff.svg';
import messageIcon from '../../../../assets/message.svg';
import audioIcon from '../../../../assets/micOn.svg';
import videoIcon from '../../../../assets/videoOn.svg';
import settingsIcon from '../../../../assets/settings.svg';
import moreIcon from '../../../../assets/more.svg';
import VideoRoomControl from '../../VideoRoomControl';
import Popup from '../../../components/Popup/Popup';
import Typography from '../../../components/Typography';
import {
  MediaIODevice,
  VideoRoomIODevices,
} from '../../../../shared/types/Video';
import {
  detachTracks,
  getAvailableMediaDevices,
} from '../../../../utilities/common/Video';
import SelectBox from '../../../components/SelectBox';
import { getMeetingInfo as _getMeetingInfo } from '../../../../shared/redux/actions/provider';
import {
  IClientSummary,
  IMeetingInfo,
  IMeetingStatus,
} from '../../../../shared/types/response/provider';
import LeaveRoomConfirmPopup from '../../LeaveRoomConfirmPopup';
import { completeMeeting as _completeMeeting } from '../../../../shared/redux/actions/app';
import useLayout from '../../../../utilities/hooks/useLayout';
import ParticipantLeftPopup from '../../ParticipantLeftPopup';
import ClientDetail from '../../ClientDetails';
import SideDrawer from '../../../components/SideDrawer';
import UnableToJoinPopup from '../../UnableToJoinPopup';
import useTracking from '../../../../utilities/hooks/useTracking';
import {
  EventNames,
  EventActions,
  EventCategories,
} from '../../../../shared/constant/Analytics';
import { PlatformCode } from '../../../../shared/constant/App';
import useCaseNotesDetails, {
  getAllCaseNoteOptions,
  getCaseNoteFormType,
} from '../../../../utilities/hooks/useCaseNotesDetails/useCaseNotesDetails';
import {
  CaseNoteFormType,
  CaseNoteQuestionTypes,
} from '../../../../shared/constant/CoachingCaseNotes';
import { encodeCode } from '../../../../utilities/common/Encryption';
import PrimaryIssueModal from '../components';
import { ICasenoteQuestionData } from '../../CaseNotesV2/types';

interface IVideoRoomProps {
  meeting: IMeetingStatus;
  getMeetingInfo: (
    meetingId: string,
    body: { platform: string },
  ) => Promise<IMeetingInfo>;
  completeMeeting: () => void;
}

// TODO move popup states from useState to useReducer

function VideoRoom({
  meeting,
  getMeetingInfo,
  completeMeeting,
}: IVideoRoomProps) {
  const navigate = useNavigate();
  const { meetingId } = useParams();
  const { t } = useTranslation();
  const { track: trackEvent } = useTracking();
  const { updateChatWindowSettings } = useLayout();
  const [room, setRoom] = React.useState<Room | null>();
  const [remoteParticipant, setRemoteParticipant] =
    React.useState<RemoteParticipant | null>(null);
  const [isAudioEnabled, setAudioEnabled] = React.useState<boolean>(true);
  const [isVideoEnabled, setVideoEnabled] = React.useState<boolean>(true);
  const [showSettings, setShowSettings] = React.useState<boolean>(false);
  const [showLeaveConfirmationPopup, setShowLeaveConfirmationPopup] =
    React.useState<boolean>(false);
  const [selectedIODevices, setSelectedIODevices] =
    React.useState<VideoRoomIODevices>({ microphone: '', webCam: '' });
  const [showParticipantLeftPopup, setShowParticipantLeftPopup] =
    React.useState<boolean>(false);
  const [availableIODevices, setAvailableIODevices] = React.useState<
    MediaIODevice[]
  >([]);
  const [showClientDetails, setShowClientDetails] =
    React.useState<boolean>(false);
  const [showUnableToJoinPopup, setShowUnableToJoinPopup] =
    React.useState<boolean>(false);
  const [issueModalVisible, setIssueModalVisible] = React.useState(false);

  const { data: meetingData } = meeting;

  const caseNotesData = useCaseNotesDetails({
    meetingId: meeting?.data?.meetingId as string,
    // formType: formType as CaseNoteFormType,
  });

  const {
    refetchPrimaryIssueStatus,
    saveNewcaseNotes,
    newCaseNoteQuestions,
    isQuestionaireLoading,
  } = useCaseNotesDetails({
    meetingId: meeting?.data?.meetingId as string,
    afterAddingCaseNotes: () => {
      setIssueModalVisible(false);
      setShowLeaveConfirmationPopup(true);
    },
  });

  const followupCaseNotesOptions = getAllCaseNoteOptions(
    caseNotesData?.newCaseNoteAnswers?.form as string,
    meetingData?.providerRole as string,
  );

  const { issuesOptions: presentingIssueOptions } = followupCaseNotesOptions;

  const caseNoteQuestionsData = React.useMemo(() => {
    const questions: ICasenoteQuestionData = {};
    newCaseNoteQuestions?.forEach((question: any) => {
      questions[question.type] = {
        question: question.question,
        id: question.id,
      };
    });
    return questions;
  }, [isQuestionaireLoading, newCaseNoteQuestions]);

  const joinRoom = async (token: string, roomName: string) => {
    if (token) {
      const joinedRoom = await connectRoom(token, {
        name: roomName,
        audio: true,
        video: true,
      });

      return joinedRoom;
    }

    return null;
  };

  const disconnectRoom = () => {
    room?.disconnect();
    room?.localParticipant.tracks.forEach((track) => track.unpublish());
    setRoom(null);
  };

  const toggleVideo = () => {
    room?.localParticipant.videoTracks.forEach((videoTrack) => {
      if (isVideoEnabled) {
        videoTrack.track.disable();
        setVideoEnabled(false);
      } else {
        videoTrack.track.enable();
        setVideoEnabled(true);
      }
    });
  };

  React.useEffect(() => {
    const body = {
      platform: PlatformCode,
    };
    if (meetingId) {
      getMeetingInfo(meetingId, body).then(async (meetingInfo) => {
        if (meetingInfo?.token) {
          const joinedRoom = await joinRoom(
            meetingInfo.token,
            meetingInfo.meeting.locationRef,
          );
          setRoom(joinedRoom);
          trackEvent(EventNames.providerDashboard, {
            eventAction: EventActions.click,
            eventCategory: EventCategories.meetingEnter,
            eventLabel: 'meeting_enter',
          });
        } else {
          setShowUnableToJoinPopup(true);
        }
      });
    }

    getAvailableMediaDevices().then((devices) => {
      setAvailableIODevices(devices);
    });

    return () => {
      disconnectRoom();
      updateChatWindowSettings({ show: false, viewMode: 'full-view' });
    };
  }, []);

  const addParticipant = (participant: any) => {
    setRemoteParticipant(participant);
  };

  const removeParticipant = () => {
    setRemoteParticipant(null);
    setShowParticipantLeftPopup(true);
  };

  React.useEffect(() => {
    if (room) {
      setRemoteParticipant(Array.from(room.participants.values())[0]);
      room.on('participantConnected', addParticipant);
      room.on('participantDisconnected', removeParticipant);
    }
  }, [room]);

  React.useEffect(() => {
    // * workaround for the issue where client can't see the provider unless provider turns off and turn on the video.
    if (remoteParticipant?.identity) {
      room?.localParticipant.videoTracks.forEach((videoTrack) => {
        videoTrack.track.disable();
      });

      setTimeout(() => {
        room?.localParticipant.videoTracks.forEach((videoTrack) => {
          videoTrack.track.enable();
        });
      }, 1000);
    }
  }, [remoteParticipant?.identity]);

  const toggleAudio = () => {
    room?.localParticipant.audioTracks.forEach((audioTrack) => {
      if (isAudioEnabled) {
        audioTrack.track.disable();
        setAudioEnabled(false);
      } else {
        audioTrack.track.enable();
        setAudioEnabled(true);
      }
    });
  };

  const { fetchCasenoteFormType } = getCaseNoteFormType((data) => {
    const { clientId, providerRole } = meetingData;
    if (
      data.form === CaseNoteFormType.V3 ||
      data.form === CaseNoteFormType.V4
    ) {
      navigate(
        `/carenotes/${providerRole}/${encodeCode(Number(clientId))}/${
          meetingData.meetingId
        }`,
      );
    } else if (data.form === CaseNoteFormType.V1) {
      navigate(
        `/coachingnotes/${providerRole}/${encodeCode(Number(clientId))}/${
          meetingData.meetingId
        }`,
      );
    } else {
      navigate('/sessions');
    }
  });

  const endSession = () => {
    completeMeeting();
    disconnectRoom();
    fetchCasenoteFormType({
      meetingId,
      meetingDate: meetingData.meetingDate,
      isInSession: false,
    });
  };

  const onSettingsClick = () => {
    setShowSettings(true);
  };

  const onMicrophoneChange = (selectedItem: string) => {
    setSelectedIODevices((devices) => ({
      ...devices,
      microphone: selectedItem,
    }));
    if (room && selectedItem) {
      createLocalTracks({
        audio: { deviceId: selectedItem },
        video: true,
      }).then((localAudioTrack: any) => {
        const tracks: any = Array.from(
          room.localParticipant.audioTracks.values(),
        ).map((publication) => publication.track);
        room.localParticipant.unpublishTracks(tracks);
        detachTracks(tracks);

        room.localParticipant.publishTrack(localAudioTrack);
      });
    }
  };

  const onCameraChange = (selectedItem: string) => {
    setSelectedIODevices((devices) => ({ ...devices, webCam: selectedItem }));
    if (selectedItem && room) {
      createLocalVideoTrack({
        deviceId: { exact: selectedItem },
      }).then((localVideoTrack) => {
        const tracks: any = Array.from(
          room.localParticipant.videoTracks.values(),
        ).map((publication) => publication.track);
        room.localParticipant.unpublishTracks(tracks);
        detachTracks(tracks);

        room.localParticipant.publishTrack(localVideoTrack);
      });
    }
  };

  const getClientSummary = (): IClientSummary => {
    const { clientId, friendlyName, providerRole, tags, userCreatedAt } =
      meetingData;
    const summary: IClientSummary = {
      providerRole,
      createdAt: userCreatedAt,
      userId: Number(clientId),
      user: {
        friendlyName,
        tags,
        createdAt: userCreatedAt,
      },
    };

    return summary;
  };

  console.log('Browser twilio video support -> ', isSupported);

  return (
    <div className="room">
      <SideDrawer
        show={showClientDetails}
        hideHandler={() => setShowClientDetails(false)}
      >
        {!!showClientDetails && (
          <ClientDetail clientSummary={getClientSummary()} isInSession />
        )}
      </SideDrawer>
      {showLeaveConfirmationPopup && (
        <LeaveRoomConfirmPopup
          onStayHereClick={() => setShowLeaveConfirmationPopup(false)}
          onLeaveClick={endSession}
        />
      )}
      {showParticipantLeftPopup && (
        <ParticipantLeftPopup
          onStayClick={() => setShowParticipantLeftPopup(false)}
          onLeaveClick={endSession}
        />
      )}
      {showUnableToJoinPopup && (
        <UnableToJoinPopup onLeaveClick={() => navigate('/sessions')} />
      )}
      {!remoteParticipant && (
        <div className="info-container">
          <Typography size={20} weight="600" color="light">
            {t('waitingForOtherParticipant')}
          </Typography>
        </div>
      )}
      {room && (
        <VideoRoomParticipant
          key={room?.localParticipant.identity}
          localParticipant
          participant={room?.localParticipant}
        />
      )}
      {remoteParticipant && (
        <VideoRoomParticipant
          key={remoteParticipant.identity}
          participant={remoteParticipant}
        />
      )}
      {issueModalVisible && (
        <PrimaryIssueModal
          meetingId={meeting?.data.meetingId || ''}
          show={issueModalVisible}
          onClose={() => setIssueModalVisible(false)}
          onSave={(val) => {
            saveNewcaseNotes({
              meetingId: meeting?.data.meetingId,
              userId: Number(meeting?.data?.clientId),
              caseNotes: [
                {
                  questionId:
                    caseNoteQuestionsData?.[CaseNoteQuestionTypes.PrimaryGoal]
                      .id,
                  note: val.value,
                },
              ],
            });
          }}
          primaryOptions={presentingIssueOptions}
        />
      )}
      <div className="room-controls">
        <VideoRoomControl
          color="primary"
          altText="mute / unmute audio"
          icon={!isAudioEnabled ? mutedAudioIcon : audioIcon}
          onClick={toggleAudio}
        />
        <VideoRoomControl
          color="primary"
          altText="mute / unmute video"
          icon={!isVideoEnabled ? mutedVideoIcon : videoIcon}
          onClick={toggleVideo}
        />
        <VideoRoomControl
          color="primary"
          altText="chat"
          icon={messageIcon}
          onClick={() =>
            updateChatWindowSettings({
              show: true,
              viewMode: 'chat-view',
              initialUser: Number(meetingData.clientId),
            })
          }
        />
        <VideoRoomControl
          color="error"
          altText="end session"
          icon={callIcon}
          onClick={async () => {
            const primaryStatus = await refetchPrimaryIssueStatus();
            if (primaryStatus?.data?.notes?.length === 0) {
              setIssueModalVisible(true);
            } else {
              setShowLeaveConfirmationPopup(true);
            }
          }}
        />
      </div>
      <div className="top-controls">
        <VideoRoomControl
          color="primary"
          altText="settings"
          icon={settingsIcon}
          onClick={onSettingsClick}
        />
        <VideoRoomControl
          color="primary"
          altText="view details"
          icon={moreIcon}
          onClick={() => setShowClientDetails(true)}
        />
      </div>
      {showSettings && (
        <Popup onClose={() => setShowSettings(false)}>
          <div className="room-settings-container">
            <div className="left-pane">
              <Typography size={24} weight="600" color="primary">
                {t('settings')}
              </Typography>
              <div className="device-items">
                <div className="device-item">
                  <Typography size={16} weight="600" color="primary">
                    {t('camera')}
                  </Typography>
                  <SelectBox
                    value={selectedIODevices.webCam}
                    variant="box"
                    onChange={onCameraChange}
                  >
                    {availableIODevices.map((device) =>
                      device.kind === 'videoinput' ? (
                        <option key={device.deviceId} value={device.deviceId}>
                          {device.label}
                        </option>
                      ) : null,
                    )}
                  </SelectBox>
                </div>
                <div className="device-item">
                  <Typography size={16} weight="600" color="primary">
                    {t('microphone')}
                  </Typography>
                  <SelectBox
                    value={selectedIODevices.microphone}
                    variant="box"
                    onChange={onMicrophoneChange}
                  >
                    {availableIODevices.map((device) =>
                      device.kind === 'audioinput' ? (
                        <option key={device.deviceId} value={device.deviceId}>
                          {device.label}
                        </option>
                      ) : null,
                    )}
                  </SelectBox>
                </div>
              </div>
            </div>
            <div className="right-pane">
              {room && (
                <VideoRoomParticipant
                  key={room?.localParticipant.identity}
                  localParticipant
                  participant={room?.localParticipant}
                  previewMode
                />
              )}
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => ({ meeting: state.app.meeting });

const mapDispatchToProps = (dispatch: Function) => ({
  getMeetingInfo: (meetingId: string, body: { platform: string }) =>
    dispatch(_getMeetingInfo(meetingId, body)),
  completeMeeting: () => dispatch(_completeMeeting()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoRoom);
