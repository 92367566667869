import * as React from 'react';
import { useParams } from 'react-router-dom';
import TwilioRoom from './TwilioRoom';
import DailyRoom from './DailyRoom';
import { ZoomBase } from './ZoomRoom/ZoomBase';

function VideoRoom() {
  const { channel } = useParams();
  const rooms = {
    '0': <TwilioRoom />,
    '5': <DailyRoom />,
    '6': <ZoomBase />,
    default: <TwilioRoom />,
  };

  return rooms[channel as keyof typeof rooms] || rooms.default;
}

export default VideoRoom;
