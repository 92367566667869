import moment from 'moment';
import { HeaderProps } from 'react-big-calendar';

function AvailabilityHeader(props: HeaderProps) {
  const { date } = props;
  return (
    <div className="rbc-custom-day-header flex flex-col items-center text-xs">
      <span className="rbc-custom-day-header-day">
        {moment(date).format('ddd')}
      </span>
      <span className="rbc-custom-day-header-date p-2 w-[34px] h-[34px]">
        {moment(date).format('D')}
      </span>
    </div>
  );
}

export default AvailabilityHeader;
