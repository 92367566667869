import { Checkbox, Field, Label } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

interface ICheckboxWithLabelProps {
  label: string;
  checked: boolean;
  handleOnChange: (checked: boolean) => void;
  classNames?: {
    root?: string;
    checkBox?: string;
    label?: string;
  };
}
function CheckboxWithLabel({
  label,
  checked,
  classNames,
  handleOnChange,
}: ICheckboxWithLabelProps) {
  return (
    <Field
      className={twMerge(
        'flex items-center gap-2 cursor-pointer',
        classNames?.root,
      )}
    >
      <Checkbox
        className={twMerge(
          'group flex justify-center items-center size-4 rounded border bg-white data-[checked]:bg-blue-500',
          classNames?.checkBox,
        )}
        checked={checked}
        onChange={handleOnChange}
      >
        {/* <svg
          className="stroke-white opacity-0 group-data-[checked]:opacity-100"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            d="M3 8L6 11L11 3.5"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <path
            d="M10 3L4.5 8.5L2 6"
            stroke="white"
            strokeWidth="1.6666"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Checkbox>
      <Label className={twMerge('cursor-pointer', classNames?.label)}>
        {label}
      </Label>
    </Field>
  );
}

export default CheckboxWithLabel;
