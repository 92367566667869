import { useRef } from 'react';
import { Participant } from '@zoom/videosdk';

import './ShareIndication.scss';

interface ShareIndicationBarProps {
  sharUserList: Array<Participant> | undefined;
  activeSharingId: number;
}
function ShareIndicationBar(props: ShareIndicationBarProps) {
  const { sharUserList, activeSharingId } = props;
  const draggableRef = useRef(null);
  const activeUser = (sharUserList ?? []).find(
    (user) => user.userId === activeSharingId,
  );

  return (
    <div className="share-indication-bar" ref={draggableRef}>
      <p className="share-indication-tip">
        {`You are viewing ${activeUser?.displayName}'s screen`}
      </p>
    </div>
  );
}

export default ShareIndicationBar;
