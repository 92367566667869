import { Stream, VideoClient } from '@zoom/videosdk';
import React from 'react';
import { IMeetingInfo } from '../../../../../shared/types/response/provider';

interface ZoomContext {
  audio: {
    encode: boolean;
    decode: boolean;
  };
  video: {
    encode: boolean;
    decode: boolean;
  };
  share: {
    encode: boolean;
    decode: boolean;
  };
  mediaStream: typeof Stream | null;
  zmClient: typeof VideoClient;

  isReadyForCall: boolean;
  setIsReadyForCall: (newStatus: boolean) => void;

  meetingData: IMeetingInfo | null;

  permissionStatus: 'denied' | 'granted' | 'prompt';

  leaveSession: () => void;
}

export default React.createContext<ZoomContext>(null as any);
