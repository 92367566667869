import { useContext, useState } from 'react';

import HelplineLogCard from './HelplineLogCard';
import HelplineLogDetailsModal from './HelplineLogDetailsModal';
import { useHelplineRecords } from '../hooks/UseClientHelplineRecords';
import NoDataFound from '../../../components/NoDataFound';
import ClientOverviewCardLoader from '../ClientOverviewCardLoader';
import { Button } from '../../../components/Button';
import ClientDetailsContext from '../context/ClientDetailsContext';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../../shared/constant/Analytics';
import useTracking from '../../../../../utilities/hooks/useTracking';

export function HelplineLogsList({ clientId }: { clientId: string }) {
  const { clientDetailData } = useContext(ClientDetailsContext);
  const [showHelplineDetails, setShowHelplineDetails] = useState<{
    open: boolean;
    selectedIndex: number;
  }>({ open: false, selectedIndex: 0 });
  const [showAllHelplineRecords, setShowAllHelplineRecords] = useState(false);
  const { track } = useTracking();

  const { data: helplineRecords, isLoading: isHelplineRecordsLoading } =
    useHelplineRecords(
      clientDetailData?.profile?.isEAPenabled && clientId
        ? clientId
        : undefined,
    );

  const showViewAllBtn = (helplineRecords?.length ?? 0) > 8;

  if (!clientDetailData?.profile?.isEAPenabled) {
    return (
      <NoDataFound
        title="No helpline records found"
        description="Your client's logs will appear here when they call the Intellect helpline"
      />
    );
  }

  if (isHelplineRecordsLoading) {
    return <ClientOverviewCardLoader variant="past-sessions" />;
  }

  return helplineRecords?.length ? (
    <>
      <div className="flex flex-col gap-3">
        {helplineRecords
          ?.slice(0, showAllHelplineRecords ? helplineRecords?.length : 8)
          ?.map((helplineRecord, idx) => (
            <HelplineLogCard
              key={helplineRecord?.id}
              index={idx}
              helplineRecord={helplineRecord}
              handleClick={(selectedIndex: number) => {
                track(EventNames.clientView, {
                  eventAction: EventActions.click,
                  eventCategory: EventCategories.helplineLog,
                  eventLabel: 'helpline_log',
                });
                setShowHelplineDetails({
                  open: true,
                  selectedIndex,
                });
              }}
            />
          ))}
        {showViewAllBtn && !showAllHelplineRecords ? (
          <section className="col-span-12">
            <Button
              variant="secondary"
              className="w-full"
              onClick={() => setShowAllHelplineRecords(true)}
            >
              View all
            </Button>
          </section>
        ) : null}
      </div>

      <HelplineLogDetailsModal
        open={showHelplineDetails.open}
        availableRecords={helplineRecords?.map((item) => item?.id) ?? []}
        handleClose={() =>
          setShowHelplineDetails({ open: false, selectedIndex: 0 })
        }
        selectedIndex={showHelplineDetails.selectedIndex}
      />
    </>
  ) : (
    <NoDataFound
      title="No helpline records found"
      description="Your client's logs will appear here when they call the Intellect helpline"
    />
  );
}
