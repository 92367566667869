import NetworkClient from '../../service/Network';
import Config from '../../Config';
import { addItemToLocalStorage } from '../../../utilities/common/Storage';
import { StorageItems } from '../../constant/App';
import { getIrisAccessToken } from '../../service/API/iris';
import { setUserId } from '../../../utilities/monitoring';

const client = new NetworkClient();

export const authenticateUser = (email: string, password: string) => () => {
  const requestBody = { email, password };
  return client
    .doPost(Config.api.auth.login, requestBody)
    .then((response) => {
      console.log(response);
      if (response.data && response.success) {
        if (
          response.data.addOns.isProvider &&
          !response.data.addOns.isResponder &&
          !response.data.addOns.isCareNavigator
        ) {
          addItemToLocalStorage(StorageItems.USER_INFO, response.data);
          getIrisAccessToken(response.data);
          setUserId(response.data.id);
          return true;
        }

        return 'PROVIDER_EMAIL_NOT_ASSOCIATED_ERROR';
      }
      return response.details || response.error?.message;
    })
    .catch((error) => {
      console.error('Error occurred while login - ', error);
    });
};

export const requestMagicCode = async (email: string) => {
  const response = await client.doPost(Config.api.auth.otpRequest, { email });
  if (response && response.success) {
    return response?.data;
  }
  throw new Error(response?.error?.message);
};

export const resetPassword = async ({
  email,
  otp,
  password,
}: {
  email: string;
  otp: string;
  password: string;
}) => {
  const response = await client.doPost(Config.api.auth.resetPassword, {
    code: otp,
    password,
    email,
  });
  if (response && response.success) {
    return response;
  }
  throw new Error(response?.error?.message);
};
