import { toast } from 'sonner';
import { useFetch, useMutation } from '../../../../../utilities/hooks/useFetch';
import {
  addClientRiskLevel,
  editClientRiskLevel,
  getClientRiskLevels,
} from '../../../../shared/service/API/client';
import { IAddRiskLevelPayloadType, IClientRiskLevel } from '../types/client';

export function useClientRiskLevel(clientId?: string, role?: string) {
  return useFetch<IClientRiskLevel[]>(
    ['client-details-v2-risk-level', clientId, role],
    () => (clientId ? getClientRiskLevels(clientId, role) : undefined),
    {
      enabled: Boolean(clientId),
      refetchOnWindowFocus: false,
    },
  );
}

export function useAddClientRiskLevel(
  payload: IAddRiskLevelPayloadType,
  handleSuccess: () => void,
  clientId?: string,
) {
  return useMutation(
    () => (clientId ? addClientRiskLevel(clientId, payload) : undefined),
    {
      onSuccess: () => {
        toast.success('Risk level added');
        handleSuccess();
      },
      onError: () => {
        toast.error(
          'Something went wrong while adding risk level, please try after sometime.',
        );
      },
    },
  );
}

export function useEditClientRiskLevel(
  payload: Pick<IAddRiskLevelPayloadType, 'risk'>,
  handleSuccess: () => void,
  clientId?: string,
  noteId?: string,
) {
  return useMutation(
    () =>
      clientId && noteId
        ? editClientRiskLevel(clientId, noteId, payload)
        : undefined,
    {
      onSuccess: () => {
        toast.success('Risk level updated');
        handleSuccess();
      },
      onError: () => {
        toast.error(
          'Something went wrong while updating risk level, please try after sometime.',
        );
      },
    },
  );
}
