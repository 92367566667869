// label: string;

import ZoomVideo from '@zoom/videosdk';
import React, { useState, useRef, useContext } from 'react';
import './Preview.scss';
import Button from '../../../../../components/Button';
import SelectBoxV2 from '../../../../../components/SelectBoxV2';
import { useMount, useUnmount } from '../../hooks/useUnmount';
import { MediaDevice } from '../../constants/VideoTypes';
import ZoomContext from '../../context/ZoomContext';

// deviceId: string;
// const prevMicFeedbackStyle = '';
// const micFeedBackInteval: any = '';

// const localAudio = ZoomVideo.createLocalAudioTrack();
const localVideo = ZoomVideo.createLocalVideoTrack();
let allDevices = [] as any[];

const mountDevices: () => Promise<{
  mics: MediaDevice[];
  speakers: MediaDevice[];
  cameras: MediaDevice[];
}> = async () => {
  try {
    allDevices = await ZoomVideo.getDevices();
  } catch (error) {
    console.log('permission', error);
  }
  const cameraDevices: Array<MediaDeviceInfo> = allDevices.filter(
    (device) => device.kind === 'videoinput',
  );
  const micDevices: Array<MediaDeviceInfo> = allDevices.filter(
    (device) => device.kind === 'audioinput',
  );
  const speakerDevices: Array<MediaDeviceInfo> = allDevices.filter(
    (device) => device.kind === 'audiooutput',
  );
  return {
    mics: micDevices.map((item) => ({
      label: item.label,
      deviceId: item.deviceId,
    })),
    speakers: speakerDevices.map((item) => ({
      label: item.label,
      deviceId: item.deviceId,
    })),
    cameras: cameraDevices.map((item) => ({
      label: item.label,
      deviceId: item.deviceId,
    })),
  };
};

// const updateMicFeedbackStyle = () => {
//   const newVolumeIntensity = localAudio.getCurrentVolume();
//   let newMicFeedbackStyle = '';

//   if (newVolumeIntensity === 0) {
//     newMicFeedbackStyle = '';
//   } else if (newVolumeIntensity <= 0.05) {
//     newMicFeedbackStyle = 'mic-feedback__very-low';
//   } else if (newVolumeIntensity <= 0.1) {
//     newMicFeedbackStyle = 'mic-feedback__low';
//   } else if (newVolumeIntensity <= 0.15) {
//     newMicFeedbackStyle = 'mic-feedback__medium';
//   } else if (newVolumeIntensity <= 0.2) {
//     newMicFeedbackStyle = 'mic-feedback__high';
//   } else if (newVolumeIntensity <= 0.25) {
//     newMicFeedbackStyle = 'mic-feedback__very-high';
//   } else {
//     newMicFeedbackStyle = 'mic-feedback__max';
//   }
//   const micIcon: any = document.getElementById('auido-volume-feedback');
//   if (prevMicFeedbackStyle !== '' && micIcon) {
//     micIcon.classList.toggle(prevMicFeedbackStyle);
//   }

//   if (newMicFeedbackStyle !== '' && micIcon) {
//     micIcon.classList.toggle(newMicFeedbackStyle);
//   }
//   prevMicFeedbackStyle = newMicFeedbackStyle;
// };

function PreviewContainer() {
  const { mediaStream, setIsReadyForCall, permissionStatus, leaveSession } =
    useContext(ZoomContext);

  // const [isStartedAudio, setIsStartedAudio] = useState<boolean>(false);
  // const [isMuted, setIsMuted] = useState<boolean>(true);
  const [isStartedVideo, setIsStartedVideo] = useState<boolean>(false);
  const [activeMicrophone, setActiveMicrophone] = useState(
    mediaStream?.getActiveMicrophone(),
  );
  const [activeSpeaker, setActiveSpeaker] = useState(
    mediaStream?.getActiveSpeaker(),
  );
  const [activeCamera, setActiveCamera] = useState(
    mediaStream?.getActiveCamera(),
  );
  const [micList, setMicList] = useState<MediaDevice[]>(
    mediaStream?.getMicList() ?? [],
  );
  const [speakerList, setSpeakerList] = useState<MediaDevice[]>(
    mediaStream?.getSpeakerList() ?? [],
  );
  const [cameraList, setCameraList] = useState<MediaDevice[]>(
    mediaStream?.getCameraList() ?? [],
  );

  console.log(
    'MEDIASTREAM',
    mediaStream?.getActiveCamera(),
    mediaStream?.getActiveMicrophone(),
    mediaStream?.getActiveSpeaker(),
  );
  // const [outputLevel, setOutputLevel] = useState(0);
  // const [inputLevel, setInputLevel] = useState(0);
  // const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  // const [isRecordingVoice, setIsRecordingVoice] = useState(false);
  // const [isPlayingRecording, setIsPlayingRecording] = useState(false);
  const [isInVBMode /* , setIsInVBMode */] = useState(false);
  // const [isBlur, setIsBlur] = useState(false);
  // const speakerTesterRef = useRef<TestSpeakerReturn>();
  // const microphoneTesterRef = useRef<TestMicrophoneReturn>();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  // const onCameraClick = useCallback(async () => {
  //   if (isStartedVideo) {
  //     await localVideo?.stop();
  //     setIsStartedVideo(false);
  //     setIsInVBMode(false);
  //     setIsBlur(false);
  //   } else if (videoRef.current) {
  //     await localVideo?.start(videoRef.current);
  //     setIsStartedVideo(true);
  //   }
  // }, [isStartedVideo]);
  // const onMicrophoneClick = useCallback(async () => {
  //   if (isStartedAudio) {
  //     if (isMuted) {
  //       await localAudio?.unmute();
  //       // micFeedBackInteval = setInterval(updateMicFeedbackStyle, 500);
  //       setIsMuted(false);
  //     } else {
  //       await localAudio?.mute();
  //       if (micFeedBackInteval) {
  //         clearInterval(micFeedBackInteval);
  //       }
  //       setIsMuted(true);
  //     }
  //   } else {
  //     await localAudio?.start();
  //     setIsStartedAudio(true);
  //   }
  // }, [isStartedAudio, isMuted]);

  // const onMicrophoneMenuClick = async (key: string) => {
  //   const [type, deviceId] = key.split('|');
  //   if (type === 'microphone') {
  //     if (deviceId !== activeMicrophone) {
  //       await localAudio.stop();
  //       setIsMuted(true);
  //       localAudio = ZoomVideo.createLocalAudioTrack(deviceId);
  //       await mediaStream?.switchMicrophone(deviceId);
  //       await localAudio.start();
  //       setActiveMicrophone(deviceId);
  //     }
  //   } else if (type === 'leave audio') {
  //     await localAudio.stop();
  //     setIsStartedAudio(false);
  //   }
  // };
  const onSwitchCamera = async (key: string) => {
    if (localVideo) {
      if (activeCamera !== key) {
        await localVideo.switchCamera(key);
      }
    }
  };
  const onSwitchMicrophone = async (key: string) => {
    if (key !== activeMicrophone) {
      await mediaStream?.switchMicrophone(key);
      setActiveMicrophone(mediaStream?.getActiveMicrophone());
    }
  };
  // const onBlurBackground = useCallback(async () => {
  //   if (isInVBMode) {
  //     if (isBlur) {
  //       await localVideo.updateVirtualBackground(undefined);
  //       mediaStream?.updateVirtualBackgroundImage(undefined);
  //     } else {
  //       await localVideo.updateVirtualBackground('blur');
  //       mediaStream?.updateVirtualBackgroundImage('blur');
  //     }
  //     setIsBlur(!isBlur);
  //   } else if (!isBlur) {
  //     localVideo.stop();
  //     if (canvasRef.current) {
  //       localVideo.start(canvasRef.current, { imageUrl: 'blur' });
  //       mediaStream?.updateVirtualBackgroundImage('blur');
  //     }
  //     setIsInVBMode(true);
  //     setIsBlur(!isBlur);
  //   }
  // }, [isInVBMode, isBlur]);

  useMount(() => {
    mountDevices().then(async (devices) => {
      setMicList(devices.mics);
      setCameraList(devices.cameras);
      setSpeakerList(devices.speakers);
      if (devices.speakers.length > 0) {
        setActiveSpeaker(devices.speakers[0].deviceId);
      }
      if (devices.mics.length > 0) {
        setActiveMicrophone(devices.mics[0].deviceId);
      }
      if (devices.cameras.length > 0) {
        setActiveCamera(devices.cameras[0].deviceId);
        if (videoRef.current) {
          await localVideo?.start(videoRef.current, { imageUrl: 'blur' });
          setIsStartedVideo(true);
        }
      }
    });
  });
  // const onTestSpeakerClick = () => {
  //   if (microphoneTesterRef.current) {
  //     microphoneTesterRef.current.destroy();
  //     microphoneTesterRef.current = undefined;
  //   }
  //   if (isPlayingAudio) {
  //     speakerTesterRef.current?.stop();
  //     setIsPlayingAudio(false);
  //     setOutputLevel(0);
  //   } else {
  //     speakerTesterRef.current = localAudio.testSpeaker({
  //       speakerId: activeSpeaker,
  //       onAnalyseFrequency: (value) => {
  //         setOutputLevel(Math.min(100, value));
  //       },
  //     });
  //     setIsPlayingAudio(true);
  //   }
  // };
  // const onTestMicrophoneClick = () => {
  //   if (speakerTesterRef.current) {
  //     speakerTesterRef.current.destroy();
  //     speakerTesterRef.current = undefined;
  //   }
  //   if (!isPlayingRecording && !isRecordingVoice) {
  //     microphoneTesterRef.current = localAudio.testMicrophone({
  //       microphoneId: activeMicrophone,
  //       speakerId: activeSpeaker,
  //       recordAndPlay: true,
  //       onAnalyseFrequency: (value) => {
  //         setInputLevel(Math.min(100, value));
  //       },
  //       onStartRecording: () => {
  //         setIsRecordingVoice(true);
  //       },
  //       onStartPlayRecording: () => {
  //         setIsRecordingVoice(false);
  //         setIsPlayingRecording(true);
  //       },
  //       onStopPlayRecording: () => {
  //         setIsPlayingRecording(false);
  //       },
  //     });
  //   } else if (isRecordingVoice) {
  //     microphoneTesterRef.current?.stopRecording();
  //     setIsRecordingVoice(false);
  //   } else if (isPlayingRecording) {
  //     microphoneTesterRef.current?.stop();
  //     setIsPlayingRecording(false);
  //   }
  // };
  // let microphoneBtn = 'Test Microphone';
  // if (isRecordingVoice) {
  //   microphoneBtn = 'Recording';
  // } else if (isPlayingRecording) {
  //   microphoneBtn = 'Playing';
  // }
  useUnmount(() => {
    // if (isStartedAudio) {
    //   localAudio.stop();
    // }
    if (isStartedVideo) {
      localVideo.stop();
    }
  });

  console.log('active', activeCamera, activeMicrophone, activeSpeaker);

  const onSwitchSpeaker = async (value: string) => {
    if (value !== activeSpeaker) {
      await mediaStream?.switchSpeaker(value);
      setActiveSpeaker(mediaStream?.getActiveSpeaker());
    }
  };

  console.log('virtualbg', mediaStream?.isSupportVirtualBackground());

  // React.useEffect(() => {
  //   zmClient.on('device-permission-change', onPermissionChange);
  //   return () => {
  //     zmClient.off('device-permission-change', onPermissionChange);
  //   };
  // }, [zmClient, onPermissionChange]);

  return (
    <div className="preview-options-box">
      <h1>Set up your hardware</h1>
      <div className="preview-video-box">
        <video
          className={isInVBMode ? 'hide-video' : 'show-video'}
          muted
          width={480}
          ref={videoRef}
        />
        <canvas
          className={isInVBMode ? 'show-video' : 'hide-video'}
          width={480}
          ref={canvasRef}
        />
      </div>
      {/* <div className="video-footer video-operations video-operations-preview">
            <div>
              <MicrophoneButton
                isStartedAudio={isStartedAudio}
                isMuted={isMuted}
                onMicrophoneClick={onMicrophoneClick}
                onMicrophoneMenuClick={onMicrophoneMenuClick}
                microphoneList={micList}
                speakerList={speakerList}
                activeMicrophone={activeMicrophone}
                activeSpeaker={activeSpeaker}
              />
              <CameraButton
                isStartedVideo={isStartedVideo}
                onCameraClick={onCameraClick}
                onSwitchCamera={onSwitchCamera}
                onBlurBackground={onBlurBackground}
                cameraList={cameraList}
                activeCamera={activeCamera}
              />
            </div>
          </div> */}
      <div className="input-options">
        <div>
          <div className="input-label">Camera</div>
          <SelectBoxV2
            selectedValue={activeCamera || ''}
            values={cameraList.map((camera) => ({
              label: camera.label,
              value: camera.deviceId,
            }))}
            onChange={(value) => onSwitchCamera(value)}
          />
        </div>
        <div>
          <div className="input-label">Speaker</div>
          <SelectBoxV2
            selectedValue={activeSpeaker || ''}
            values={speakerList.map((speaker) => ({
              label: speaker.label,
              value: speaker.deviceId,
            }))}
            onChange={(value) => onSwitchSpeaker(value)}
          />
        </div>
        <div>
          <div className="input-label">Microphone</div>
          <SelectBoxV2
            selectedValue={activeMicrophone || ''}
            values={micList.map((mic) => ({
              label: mic.label,
              value: mic.deviceId,
            }))}
            onChange={(value) => onSwitchMicrophone(value)}
          />
        </div>

        {/* <div className="blur-option">
          <div className="input-label">Blur background?</div>
          <input
            type="checkbox"
            checked={isBlur}
            onClick={() => onBlurBackground()}
          />
        </div> */}
      </div>
      {/*  <div className="audio-test">
          <div className="audio-test-wrap">
            <h3>Speaker Test</h3>
            <div className="speaker-action">
              <Button
                type="primary"
                onClick={onTestSpeakerClick}
                className="speaker-btn"
              >
                {isPlayingAudio ? 'Stop' : 'Test Speaker'}
              </Button>

              <Select
                onChange={(value) => {
                  setActiveSpeaker(value);
                }}
                value={activeSpeaker}
                className="speaker-list"
              >
                {speakerList.map((item) => (
                  <Option value={item.deviceId} key={item.deviceId}>
                    {item.label}
                  </Option>
                ))}
              </Select> 
            </div>
            <div className="speaker-output">
              <span className="speaker-label">Output level</span>
              <Progress percent={outputLevel} showInfo={false} />
            </div>
          </div>
           <div className="audio-test-wrap">
            <h3>Microphone Test</h3>
            <div className="speaker-action">
              <Button
                type="primary"
                onClick={onTestMicrophoneClick}
                className="speaker-btn"
              >
                {microphoneBtn}
              </Button>
              <Select
                onChange={(value) => {
                  setActiveMicrophone(value);
                }}
                value={activeMicrophone}
                className="speaker-list"
              >
                {micList.map((item) => (
                  <Option value={item.deviceId} key={item.deviceId}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="speaker-output">
              <span className="speaker-label">Input level</span>
              <Progress percent={inputLevel} showInfo={false} />
            </div> 
          </div> 
        </div> */}
      <div className="confirmation-options">
        <Button
          variant="primary"
          label="Join call"
          disabled={permissionStatus !== 'granted'}
          onClick={() => setIsReadyForCall(true)}
        />
        <Button
          onClick={() => leaveSession()}
          variant="caution"
          label="Go back"
        />
      </div>
    </div>
  );
}

export default PreviewContainer;
