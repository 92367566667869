import { getCurrentLocale } from '../../i18n';
import { IProviderData } from '../../shared/types/response/provider';

interface ITranslationData {
  languageId: string;
}

interface ITranslationItems {
  translationItems: Array<ITranslationData>;
}

interface ITranslationData {
  bioTranslation: ITranslationItems;
}

function getProfileTranslations(providerData: IProviderData) {
  // Initial
  const selectedLanguage = getCurrentLocale();

  // Translation Name
  const name =
    providerData?.providerNameTranslation?.translationItems?.find(
      (item: { languageId: string }) => item.languageId === selectedLanguage,
    )?.text || providerData?.name;

  // Bio Translation
  const bio =
    providerData?.bioTranslation?.translationItems?.find(
      (translationValue: any) =>
        translationValue?.languageId === selectedLanguage,
    )?.text || providerData?.bio;

  // Profile Attributes
  const attributes = providerData?.attributes.map((a) => ({
    ...a,
    name:
      a.nameTranslation.translationItems.find(
        (translationValue: any) =>
          translationValue?.languageId === selectedLanguage,
      )?.text || a.name,
  }));
  return {
    name,
    bio,
    attributes,
  };
}

export default getProfileTranslations;
