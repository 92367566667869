import { twMerge } from 'tailwind-merge';
import { ReactComponent as EditIcon } from '../../../assets/editIcon.svg';
import { ReactComponent as LockIcon } from '../../../assets/Lock.svg';
import { Button } from '../../components/Button';
import Tooltip from '../../components/Tooltip';

interface IRiskLevelEditIconProps {
  iconBgColorClass?: string;
  iconColor?: string;
  onClick?: () => void;
  isLocked?: boolean;
}

function RiskLevelEditIcon({
  onClick,
  isLocked = false,
  iconBgColorClass = 'bg-gray-200',
  iconColor = '#475467',
}: IRiskLevelEditIconProps) {
  return isLocked ? (
    <>
      <div
        className={twMerge(
          `w-8 h-8 p-2 rounded-lg bg-[${iconBgColorClass}] hover:bg-[${iconBgColorClass}] focus:bg-[${iconBgColorClass}]`,
          iconBgColorClass,
        )}
        data-tooltip-id="beacon-client-risk-level-lock"
      >
        <LockIcon stroke={iconColor} className="w-4 h-4 " />
      </div>
      <Tooltip
        id="beacon-client-risk-level-lock"
        content="You client has unmatched with you. To update the risk, please go to session case-notes"
        placement="bottom"
      />
    </>
  ) : (
    <Button
      className={twMerge(
        `w-8 h-8 p-2 rounded-lg bg-[${iconBgColorClass}] hover:bg-[${iconBgColorClass}] focus:bg-[${iconBgColorClass}]`,
        iconBgColorClass,
      )}
      onClick={onClick}
    >
      <EditIcon stroke={iconColor} className="w-4 h-4 " />
    </Button>
  );
}

export default RiskLevelEditIcon;
