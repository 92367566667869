import { useContext } from 'react';
import { ReactComponent as NoEAPAccessIcon } from '../../../../assets/NoEAPAccess.svg';
import { Button } from '../../../components/Button';
import Modal from '../../../components/Modal';
import ClientDetailsContext from '../context/ClientDetailsContext';

interface IHelplineNoEAPAccess {
  open: boolean;
  handleClose: () => void;
}
function HelplineNoEAPAccess({ open, handleClose }: IHelplineNoEAPAccess) {
  const { clientName } = useContext(ClientDetailsContext);

  return (
    <Modal open={open} handleClose={handleClose} title="" className="w-5/12">
      <div className="flex justify-center items-center w-full flex-col gap-2">
        <div>
          <NoEAPAccessIcon />
        </div>
        <div className="text-displayXs font-semibold text-gray-900">
          EAP services are not available to {clientName}
        </div>
        <div className="max-w-md text-center text-sm text-gray-500">
          Book a session with them instead!
        </div>
        <div className="w-full mt-10">
          <Button className="w-full" onClick={handleClose}>
            Okay
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default HelplineNoEAPAccess;
