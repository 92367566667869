import moment from 'moment';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { ReactComponent as CopyIcon } from '../../../assets/copy.svg';
import profileCover from '../../../assets/UserInfoCardBg.svg';
import {
  ClientGenderMappings,
  ClientStatus,
} from '../../../shared/constant/Clients';
import { getProviderRole } from '../../../utilities/common/Clients';
import { ClientMatchedOnDateFormat } from '../../../utilities/common/Date';
import Avatar from '../../components/Avatar';
import { Button } from '../../components/Button';
import { Tag } from '../../components/Tag';
import UnOrderedList from '../../components/UnOrderedList';
import ClientOverviewCardLoader from './ClientOverviewCardLoader';
import ClientDetailsContext from './context/ClientDetailsContext';
import useLayout from '../../../../utilities/hooks/useLayout';
import UserStatusChip from './UserStatusChip';
import { ProviderRole } from '../../../shared/constant/Common';
import { BookSessionModal } from './BookSessionModal/BookSessionModal';

export default function UserInfoCard() {
  const { t } = useTranslation();
  const { updateChatWindowSettings } = useLayout();
  const {
    clientDetailData,
    isLoading,
    decodedClientId,
    encodedClientId,
    clientName,
    serviceType,
  } = useContext(ClientDetailsContext);

  const clientStatus = clientDetailData?.profile?.status;

  const handleCopyIconClick = () => {
    navigator.clipboard.writeText(encodedClientId as string);
    toast.success(t('COPY_CASE_ID_SUCCESS_PROMPT'));
  };

  const [showBookSessionModal, setShowBookSessionModal] = useState(false);

  const ClientPersonalInfo = useMemo(() => {
    const { age = null, gender = null } = clientDetailData?.profile ?? {};

    const items = [
      {
        label: clientDetailData?.profile?.createdAt
          ? t('MATCHED_ON_DATE', {
              date: moment(clientDetailData?.profile?.createdAt).format(
                ClientMatchedOnDateFormat,
              ),
            })
          : '-',
      },
    ];

    if (age) {
      items.push({
        label: t(age === 'NA' ? 'AGE_UNDISCLOSED' : `${age} years`),
      });
    }

    if (gender && ClientGenderMappings?.[gender]) {
      items.push({
        label: ClientGenderMappings?.[gender],
      });
    }

    return items;
  }, [clientDetailData?.profile]);

  const ClientOrgDetails = useMemo(
    () => [
      {
        label: getProviderRole(clientDetailData?.profile?.providerRole ?? '')
          ?.label,
      },
      {
        label: clientDetailData?.profile?.isB2CUser
          ? t('B2C_CLIENT_ORG_STATE')
          : clientDetailData?.profile?.companyName ?? t('ORG_UNDISCLOSED'),
      },
      {
        label: (
          <Tag
            variant="gray"
            className="flex items-center justify-start gap-1 rounded text-xs font-medium hover:bg-gray-200 active:bg-gray-300"
            onClick={handleCopyIconClick}
          >
            {t('CASE_ID', { clientId: encodedClientId })}
            <CopyIcon className="cursor-pointer w-2 h-2" />
          </Tag>
        ),
      },
    ],
    [clientDetailData?.profile, encodedClientId],
  );

  const onBookSessionClick = () => {
    // if (clientDetailData.isHold) {
    //   toast.info(t('matchedWithClinical', { clientName: friendlyName }));
    // } else {
    // track(EventNames.clientView, {
    //   eventAction: EventActions.click,
    //   eventCategory: EventCategories.bookSession,
    //   eventLabel: 'book_session',
    //   featureVersion: 'v1',
    // });
    setShowBookSessionModal(true);
    // }
  };

  return isLoading ? (
    <ClientOverviewCardLoader variant="avatar" />
  ) : (
    <section className="w-full bg-white box-border flex flex-col gap-4 border rounded-lg border-gray-200 border-solid">
      <section className="relative">
        <img
          src={profileCover}
          alt="profileCover"
          className="rounded-t-lg w-full"
        />
        <Avatar clientName={clientName} className="absolute top-6 left-4" />
      </section>
      <section className="flex flex-col gap-2 p-4">
        <section className="flex items-center justify-start gap-2">
          <section className=" text-xl font-semibold truncate">
            {clientName}
          </section>
          {clientStatus && (
            <section className=" ">
              <UserStatusChip clientStatus={clientStatus} />
            </section>
          )}
        </section>
        <UnOrderedList className="flex flex-wrap" items={ClientPersonalInfo} />
        <section>
          <UnOrderedList
            className="text-gray-500 font-normal flex"
            liClassName="min-w-0 [&:nth-child(2)]:max-w-[35%]"
            items={ClientOrgDetails}
          />
        </section>
        {clientStatus !== ClientStatus.UNMATCHED_CLIENTS && (
          <section className="flex flex-col gap-3">
            {serviceType !== ProviderRole.CAREOFFSITE &&
            serviceType !== ProviderRole.ONSITE ? (
              <Button className="w-full" onClick={onBookSessionClick}>
                {t('BOOK_SESSION')}
              </Button>
            ) : null}

            <Button
              className="w-full"
              variant="secondary"
              onClick={() =>
                updateChatWindowSettings({
                  show: true,
                  initialUser: decodedClientId ? +decodedClientId : null,
                })
              }
            >
              {t('VIEW_MESSAGES')}
            </Button>
          </section>
        )}
      </section>
      {clientDetailData ? (
        <BookSessionModal
          clientData={clientDetailData}
          open={showBookSessionModal}
          handleClose={() => setShowBookSessionModal(false)}
        />
      ) : null}
    </section>
  );
}
