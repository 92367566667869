import React from 'react';
import { useInView } from 'react-intersection-observer';
import Modal from '../../../components/Modal';
import { useSessionsList } from '../hooks/UseSessionsList';
import SessionCard from './SessionCard';
import ClientOverviewCardLoader from '../ClientOverviewCardLoader';

interface PastSessionsProps {
  open: boolean;
  handleClose: () => void;
  clientId: string;
  role?: string;
}
export default function PastSessionsModal({
  clientId,
  role,
  open,
  handleClose,
}: PastSessionsProps) {
  const { ref, inView } = useInView();

  const { data, fetchNextPage, isFetchingNextPage } = useSessionsList(
    clientId,
    role,
    2,
  );

  React.useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title="Past Sessions"
      subtitle="Last session on 14 Feb 2024, 3:15 PM"
      className="w-6/12"
    >
      <section className="mt-8 flex flex-col gap-4 overflow-scroll">
        {data?.pages.map((page) =>
          page.data.map((session) => <SessionCard data={session} />),
        )}

        <div ref={ref} className="h-4">
          {isFetchingNextPage ? (
            <ClientOverviewCardLoader variant="card" />
          ) : null}
        </div>
      </section>
    </Modal>
  );
}
