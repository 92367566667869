import ContentLoader from 'react-content-loader';
import { twMerge } from 'tailwind-merge';

type ClientOverviewLoaderVariants =
  | 'avatar'
  | 'card'
  | 'credits'
  | 'tabs'
  | 'past-sessions'
  | 'personal-insights';
interface ClientOverviewCardLoaderProps {
  className?: string;
  variant: ClientOverviewLoaderVariants;
  hideCardLayout?: boolean;
}
export default function ClientOverviewCardLoader({
  className,
  variant,
  hideCardLayout = false,
}: ClientOverviewCardLoaderProps) {
  return (
    <section
      className={twMerge(
        !hideCardLayout
          ? 'w-full bg-white p-4 box-border flex flex-col gap-3 shadow items-start justify-center border border-solid border-gray-200 rounded-lg '
          : '',
        className,
      )}
    >
      {variant === 'card' && (
        <ContentLoader
          speed={2}
          height={150}
          width="100%"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="3" ry="3" width="20%" height="15" />
          <rect x="0" y="25" rx="3" ry="3" width="100%" height="65" />
          <rect x="0" y="105" rx="3" ry="3" width="100%" height="65" />
        </ContentLoader>
      )}

      {variant === 'credits' && (
        <ContentLoader
          speed={2}
          height={150}
          width="100%"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="3" ry="3" width="100%" height="65" />
          <rect x="0" y="95" rx="3" ry="3" width="20%" height="45" />
          <rect x="25%" y="95" rx="3" ry="3" width="20%" height="45" />
        </ContentLoader>
      )}

      {variant === 'tabs' && (
        <ContentLoader
          speed={2}
          height={100}
          width="100%"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="3" ry="3" width="50%" height="35" />
          <rect x="0" y="45" rx="3" ry="3" width="20%" height="30" />
          <rect x="25%" y="45" rx="3" ry="3" width="20%" height="30" />
          <rect x="50%" y="45" rx="3" ry="3" width="20%" height="30" />
          <rect x="75%" y="45" rx="3" ry="3" width="20%" height="30" />
        </ContentLoader>
      )}

      {variant === 'past-sessions' && (
        <ContentLoader
          speed={2}
          height="576"
          width="100%"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="3" ry="3" width="100%" height="75" />
          <rect x="0" y="90" rx="3" ry="3" width="100%" height="75" />
          <rect x="0" y="180" rx="3" ry="3" width="100%" height="75" />
          <rect x="0" y="270" rx="3" ry="3" width="100%" height="75" />
          <rect x="0" y="360" rx="3" ry="3" width="100%" height="30" />
        </ContentLoader>
      )}

      {variant === 'avatar' && (
        <ContentLoader
          speed={2}
          height={250}
          width="100%"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="3" ry="3" width="100%" height="80" />

          <rect
            x="0"
            y="90"
            rx="3"
            ry="3"
            width="50%"
            height="15"
            className=""
          />
          <rect x="0" y="115" rx="3" ry="3" width="100%" height="15" />
          <rect x="0" y="140" rx="3" ry="3" width="100%" height="15" />
          <rect x="0" y="165" rx="3" ry="3" width="100%" height="80" />
        </ContentLoader>
      )}

      {variant === 'personal-insights' && (
        <ContentLoader
          speed={2}
          height={495}
          width="100%"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="3" ry="3" width="20%" height="25" />
          <rect x="25%" y="35" rx="3" ry="3" width="50%" height="150" />
          <rect x="0" y="195" rx="3" ry="3" width="100%" height="65" />
          <rect x="0" y="270" rx="3" ry="3" width="100%" height="65" />
          <rect x="0" y="345" rx="3" ry="3" width="100%" height="65" />
          <rect x="0" y="420" rx="3" ry="3" width="100%" height="65" />
        </ContentLoader>
      )}
    </section>
  );
}
