import { twMerge } from 'tailwind-merge';
import {
  IAvailabilityEventItem,
  SlotCategories,
} from '../../../shared/types/Availability';

const variants: Record<string, string> = {
  [SlotCategories.VIRTUAL]: 'bg-cyan-100/50',
  [SlotCategories.IN_PERSON]: 'bg-purple-100/50',
};

function AvailabilityWorkingHoursEvent({
  event,
}: {
  event: IAvailabilityEventItem;
}) {
  const slotCategory = event?.slotInfo?.category ?? SlotCategories.VIRTUAL;

  return (
    <div
      className={twMerge(
        'w-full h-full font-medium text-xs',
        variants?.[slotCategory],
      )}
    />
  );
}

export default AvailabilityWorkingHoursEvent;
