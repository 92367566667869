import { ReactComponent as NoDataFoundIcon } from '../../assets/NoDataFound.svg';

interface INoDataFound {
  title: string | JSX.Element;
  description: string | JSX.Element;
}

function NoDataFound({ title, description }: INoDataFound) {
  return (
    <div className="flex justify-center items-center w-full flex-col gap-2">
      <div>
        <NoDataFoundIcon />
      </div>
      <div className="text-displayXs font-semibold text-gray-900">{title}</div>
      <div className="max-w-[70%] text-center text-sm text-gray-500">
        {description}
      </div>
    </div>
  );
}

export default NoDataFound;
