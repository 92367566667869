export const encodeCode = (inputCode: number) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const base = characters.length;
  let code = '';
  let tempUserId = inputCode;
  while (tempUserId > 0) {
    const remainder = tempUserId % base;
    code = characters[remainder] + code;
    tempUserId = Math.floor(tempUserId / base);
  }
  // Padding if needed
  while (code.length < 6) {
    code = characters[0] + code;
  }
  return code;
};

export default function decodeCode(inputCode: string) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const base = characters.length;
  let number = 0;
  // Iterate through each character in the code
  for (let i = 0; i < inputCode.length; i += 1) {
    const char = inputCode[i];
    const charValue = characters.indexOf(char);
    number = number * base + charValue;
  }
  return number;
}
