import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { Button } from '../../../components/Button';
import { unMatchedStatusCode } from '../../Clients/constants';
import ClientOverviewCardLoader from '../ClientOverviewCardLoader';
import ClientDetailsContext from '../context/ClientDetailsContext';
import {
  useClientAssignments,
  useSendAssignmentReminder,
} from '../hooks/UseClientAssignments';

import useAuth from '../../../../../utilities/hooks/useAuth';
import { AssignmentCard } from './AssignmentCard';
import NoDataFound from '../../../components/NoDataFound';

export function AssignmentsList({ clientId }: { clientId: string }) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { clientDetailData } = useContext(ClientDetailsContext);
  const [showAllAssignments, setShowAllAssignments] = useState(false);

  const isClientUnmatched =
    clientDetailData?.profile?.status === unMatchedStatusCode;

  const [reminderSentAssignments, setReminderSentAssignments] = useState<
    string[]
  >([]);

  const { data: assignments, isLoading: isAssignmentLoading } =
    useClientAssignments(clientId);

  const sendReminderSuccess = (response: string, assignmentId: string) => {
    if (response === 'success') {
      setReminderSentAssignments((prev) => [...prev, assignmentId]);
      toast.success(t('sentReminder'));
    }
  };

  const { mutate: sendReminder } = useSendAssignmentReminder(
    sendReminderSuccess,
    clientId,
  );

  if (isAssignmentLoading) {
    return (
      <section className="grid grid-cols-12 gap-4">
        <ClientOverviewCardLoader variant="card" className="col-span-6" />
        <ClientOverviewCardLoader variant="card" className="col-span-6" />
        <ClientOverviewCardLoader variant="card" className="col-span-6" />
        <ClientOverviewCardLoader variant="card" className="col-span-6" />
      </section>
    );
  }

  const showViewAllBtn = (assignments?.length ?? 0) > 8;

  return assignments?.length ? (
    <>
      <div className="text-md font-medium mb-4">Assigned resources</div>
      <section className="grid grid-cols-12 gap-4">
        {assignments
          ?.slice(0, showAllAssignments ? assignments?.length : 8)
          ?.map((assignment) => (
            <AssignmentCard
              key={assignment?._id}
              assignment={assignment}
              isClientUnmatched={isClientUnmatched}
              reminderSentAssignments={reminderSentAssignments}
              sendReminder={sendReminder}
              user={user}
            />
          ))}

        {showViewAllBtn && !showAllAssignments ? (
          <section className="col-span-12">
            <Button
              variant="secondary"
              className="w-full"
              onClick={() => setShowAllAssignments(true)}
            >
              View all
            </Button>
          </section>
        ) : null}
      </section>
    </>
  ) : (
    <NoDataFound
      title=" No resources assigned yet"
      description="Help support your client's progress by assigning them a resource"
    />
  );
}
