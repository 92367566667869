import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Popup from '../../components/Popup';
import Typography from '../../components/Typography';
import './LeaveRoomConfirmPopup.scss';

interface ILeaveRoomConfirmPopup {
  onLeaveClick: () => void;
  onStayHereClick: () => void;
}

function LeaveRoomConfirmPopup({
  onLeaveClick,
  onStayHereClick,
}: ILeaveRoomConfirmPopup) {
  const { t } = useTranslation();
  return (
    <Popup showCloseIcon={false} withBackdrop={false} onClose={onStayHereClick}>
      <div className="leave-confirm-container">
        <Typography weight="400" size={16}>
          {t('leaveTheRoom')}
        </Typography>
        <div className="confirm-actions">
          <Button
            width="180px"
            label={t('stayHere')}
            variant="primary"
            onClick={onStayHereClick}
          />
          <Button
            width="180px"
            label={t('leaveRoom')}
            variant="caution"
            onClick={onLeaveClick}
          />
        </div>
      </div>
    </Popup>
  );
}

export default LeaveRoomConfirmPopup;
