import { RiskLevelsEnum } from './Clients';

export const variants = {
  primary: 'bg-primary-25 border-primary-100 text-gray-700',
  info: 'bg-primary-25 border-blueLight-100 text-blueLight-700',
  infoOutline: 'border border-blueLight-700 text-blueLight-700',
  warning: 'bg-orange-100 text-orange-700',
  warningOutline: 'border border-orange-700 text-orange-700',
  error: 'bg-rose-100 text-rose-700',
  errorOutline: 'border border-error-600 text-error-600',
  success: 'bg-greenLight-100 text-greenLight-700',
  successOutline: 'border border-success-700 text-success-700',
  purple: 'bg-purple-100 text-purple-700',
  gray: 'bg-gray-100 text-gray-700',
  grayOutline: 'border border-gray-500 text-gray-500',
};

export const statusBaseTooltipIconColors: Record<string, string> = {
  '0': '#344054',
  '1': '#067647',
  '2': '#4A0FC4',
  '3': '#B54708',
  '4': '#B42318',
};

export const infoTileVariants = {
  primary: 'bg-primary-50 border-primary-100 font-semibold',
  error: 'bg-error-50 border-error-50',
  gray: 'bg-gray-50 border-gray-100',
};

export const riskColorVariants: Record<
  string,
  {
    backgroundColor: string;
    hoverColor: string;
    textColor: string;
    subTextColor: string;
    iconColor: string;
    borderColor: string;
    iconBg: string;
    label: string;
    value: RiskLevelsEnum;
    description: string;
  }
> = {
  [RiskLevelsEnum.NO_RISK]: {
    backgroundColor: 'bg-cyan-25',
    hoverColor: 'hover:bg-cyan-25',
    textColor: 'text-cyan-900',
    subTextColor: 'text-cyan-800',
    iconColor: '#164C63',
    borderColor: 'border-cyan-500',
    iconBg: 'bg-cyan-100',
    label: 'NO_RISK',
    value: RiskLevelsEnum.NO_RISK,
    description: 'NO_RISK_DESCRIPTION',
  },
  [RiskLevelsEnum.LOW_RISK]: {
    backgroundColor: 'bg-greenLight-25',
    hoverColor: 'hover:bg-greenLight-25',
    textColor: 'text-greenLight-800',
    subTextColor: 'text-cyan-700',
    iconColor: '#3B7C0F',
    borderColor: 'border-greenLight-400',
    iconBg: 'bg-moss-100',
    label: 'LOW_RISK',
    value: RiskLevelsEnum.LOW_RISK,
    description: 'LOW_RISK_DESCRIPTION',
  },
  [RiskLevelsEnum.MED_RISK]: {
    backgroundColor: 'bg-warning-25',
    hoverColor: 'hover:bg-warning-25',
    textColor: 'text-warning-800',
    subTextColor: 'text-warning-700',
    iconColor: '#B54708',
    borderColor: 'border-warning-400',
    iconBg: 'bg-warning-100 bg-opacity-[48%]',
    label: 'Medium risk',
    value: RiskLevelsEnum.MED_RISK,
    description: 'MEDIUM_RISK_DESCRIPTION',
  },
  [RiskLevelsEnum.HIGH_NON_CRITICAL_RISK]: {
    backgroundColor: 'bg-error-25',
    hoverColor: 'hover:bg-error-25',
    textColor: 'text-error-800',
    subTextColor: 'text-error-700',
    iconColor: '#B42318',
    borderColor: 'border-error-400',
    iconBg: 'bg-error-100 bg-opacity-[72%]',
    label: 'High risk: Non-critical',
    value: RiskLevelsEnum.HIGH_NON_CRITICAL_RISK,
    description: 'HIGH_RISK_NON_CRITICAL_DESCRIPTION',
  },
  [RiskLevelsEnum.HIGH_CRITICAL_RISK]: {
    backgroundColor: 'bg-rose-25',
    hoverColor: 'hover:bg-rose-25',
    textColor: 'text-rose-800',
    subTextColor: 'text-rose-700',
    iconColor: '#C01048',
    borderColor: 'border-rose-400',
    iconBg: 'bg-rose-100',
    label: 'HIGH_RISK_CRITICAL',
    value: RiskLevelsEnum.HIGH_CRITICAL_RISK,
    description: 'HIGH_RISK_CRITICAL_DESCRIPTION',
  },
  [RiskLevelsEnum.RISK_NOT_ASSESSED]: {
    backgroundColor: 'bg-purple-25',
    hoverColor: 'hover:bg-purple-25',
    textColor: 'text-purple-800',
    subTextColor: 'text-purple-700',
    iconColor: '#5925DC',
    borderColor: 'border-purple-500',
    iconBg: 'bg-purple-100',
    label: 'RISK_NOT_ASSESSED',
    value: RiskLevelsEnum.RISK_NOT_ASSESSED,
    description: 'RISK_NOT_ASSESSED_DESCRIPTION',
  },
};
