import { twMerge } from 'tailwind-merge';
import { motion } from 'framer-motion';

function AvailabilityMeetingFilterBox({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.4 }}
      className={twMerge(
        'px-10 py-2 text-xs w-full flex flex-col gap-2',
        className,
      )}
    >
      {children}
    </motion.div>
  );
}

export default AvailabilityMeetingFilterBox;
