import { useContext, useState } from 'react';
import BasicCardTemplate from '../../components/BasicCardTemplate';
import { Button } from '../../components/Button';
import InfoTile from '../../components/InfoTile';
import ClientDetailsContext from './context/ClientDetailsContext';
import { useClientEmergencyContacts } from './hooks/UseClientEmergencyContacts';
import { ClientECPRelationLabelMapping } from '../../../shared/constant/Clients';
import ClientOverviewCardLoader from './ClientOverviewCardLoader';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../shared/constant/Analytics';
import useTracking from '../../../../utilities/hooks/useTracking';

export default function UserEmergencyContactCard() {
  const { decodedClientId, isLoading } = useContext(ClientDetailsContext);
  const [fetchEcps, setFetchEcps] = useState(false);
  const { track } = useTracking();

  const { data, isLoading: isECLoading } = useClientEmergencyContacts(
    fetchEcps,
    decodedClientId,
  );

  const ecps = data?.ecp;

  return isLoading || isECLoading ? (
    <ClientOverviewCardLoader variant="card" />
  ) : (
    <BasicCardTemplate heading="Emergency Contact" showToolTip={false}>
      {!fetchEcps && (
        <>
          <InfoTile
            lineClamp={2}
            variant="gray"
            contentClassName="text-gray-900"
            content="Please confirm that your client is in a high-risk situation to access their emergency contact details"
          />
          <Button
            className="w-full"
            variant="secondary"
            onClick={() => {
              track(EventNames.clientView, {
                eventAction: EventActions.click,
                eventCategory: EventCategories.emergencyContacts,
                eventLabel: 'emergency_contacts',
              });
              setFetchEcps(true);
            }}
          >
            Confirm and access details
          </Button>
        </>
      )}

      {fetchEcps && !ecps?.length ? (
        <InfoTile
          lineClamp={2}
          variant="gray"
          content="Your client hasn’t added any emergency contacts yet"
        />
      ) : null}

      {fetchEcps && ecps?.length
        ? ecps?.map((item) => (
            <InfoTile
              key={item.id}
              lineClamp={2}
              variant="gray"
              content={
                <span className="text-xs font-semibold text-gray-900">
                  {ClientECPRelationLabelMapping?.[item.relation]}
                </span>
              }
              subContent={
                <span className="text-xs text-gray-700">{item?.contact}</span>
              }
            />
          ))
        : null}
    </BasicCardTemplate>
  );
}
