/* eslint-disable no-param-reassign */
import ZoomVideo, {
  ConnectionState,
  ReconnectReason,
  VideoClient,
} from '@zoom/videosdk';
import React, { useReducer, useState } from 'react';
import produce from 'immer';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { MediaStream } from '../constants/VideoTypes';
import Loader from '../../../../components/Loader';
import VideoContainer from './Video/VideoBase';
import useAuth from '../../../../../utilities/hooks/useAuth';
import useMeeting from '../../hooks/useMeetingJoin';
import { IMeetingInfo } from '../../../../../shared/types/response/provider';
import { filterDuplicatesUser } from '../utils/filterDuplicatesUser';
import { getExploreName, zoomUnsupportedBrowsers } from '../utils/platform';
import { ZoomWebEndpoint } from '../constants/VideoConstants';
import ZoomContext from '../context/ZoomContext';
import PreviewContainer from './Preview/Preview';
import { ZoomPermissionError } from './ZoomPermissionError/ZoomPermissionError';

const mediaShape = {
  audio: {
    encode: false,
    decode: false,
  },
  video: {
    encode: false,
    decode: false,
  },
  share: {
    encode: false,
    decode: false,
  },
};
const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case 'audio-encode': {
      draft.audio.encode = action.payload;
      break;
    }
    case 'audio-decode': {
      draft.audio.decode = action.payload;
      break;
    }
    case 'video-encode': {
      draft.video.encode = action.payload;
      break;
    }
    case 'video-decode': {
      draft.video.decode = action.payload;
      break;
    }
    case 'share-encode': {
      draft.share.encode = action.payload;
      break;
    }
    case 'share-decode': {
      draft.share.decode = action.payload;
      break;
    }
    case 'reset-media': {
      Object.assign(draft, { ...mediaShape });
      break;
    }
    default:
      break;
  }
}, mediaShape);

interface ZoomRoomProps {
  zmClient: typeof VideoClient;
}

function ZoomRoom({ zmClient }: ZoomRoomProps) {
  const {
    user: { name },
  } = useAuth();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { meetingId } = useParams();

  const [loading, setIsLoading] = React.useState(true);
  const [isReadyForCall, setIsReadyForCall] = React.useState(false);
  const [permissionStatus, setPermissionStatus] = React.useState<
    'denied' | 'granted' | 'prompt'
  >('prompt');
  const [meetingData, setMeetingData] = useState<IMeetingInfo | null>(null);
  const [getUserMediaError, setGetUserMediaError] = useState(false);

  const [isFailover, setIsFailover] = React.useState<boolean>(false);
  const [mediaStream, setMediaStream] = React.useState<MediaStream | null>(
    null,
  );
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);

  const leaveSession = () => {
    zmClient.leave().then(() => {
      ZoomVideo.destroyClient();
      setMeetingData(null);
      navigate('/sessions');
    });
  };

  const { isLoading, mutate: joinMeeting } = useMeeting({
    onSuccess: (meetingResponse) => {
      setMeetingData(meetingResponse);
    },
    onError: (error: Error) => {
      toast.error(error.message);
      navigate(-1);
    },
  });

  React.useEffect(() => {
    const checkSystemRequirements = async () => {
      if (meetingId) {
        // Check system requirements
        if (
          Object.values(ZoomVideo.checkSystemRequirements()).every(Boolean) &&
          !zoomUnsupportedBrowsers.includes(await getExploreName())
        ) {
          joinMeeting({ meetingId, channel: 6 });
        } else {
          toast.error(t('browserNotSupported_Zoom'), {
            duration: 5000,
          });

          navigate('/sessions');
        }
      }
    };

    checkSystemRequirements();
  }, [meetingId]);

  React.useEffect(() => {
    const init = async () => {
      if (meetingData?.token) {
        await zmClient.init('en-US', 'CDN', {
          webEndpoint: ZoomWebEndpoint,
          stayAwake: true,
          patchJsMedia: true,
          leaveOnPageUnload: false,
        });

        try {
          zmClient
            .join(
              meetingData.meeting.locationRef,
              meetingData.token,
              name,
              undefined,
              parseInt(meetingData.meeting.duration, 10) / 60,
            )
            .then(() => {
              const participants = zmClient.getAllUser();
              // const currentUserIdentity =
              //   zmClient.getCurrentUserInfo()?.userIdentity;

              console.log(
                'effectchange',
                meetingData?.token,
                meetingData?.meeting.locationRef,
                name,
                ZoomWebEndpoint,
                zmClient,
                participants,
                participants.map((p) => p.userIdentity),
              );

              const { hasDuplicates } = filterDuplicatesUser(participants);

              if (hasDuplicates) {
                console.log('effectchange navigating');

                toast.error(t('MEETING_FULL_ERR'), {
                  duration: 5000,
                });
                leaveSession();
              } else {
                const stream = zmClient.getMediaStream();
                console.log('Joining the session...', stream);
                setMediaStream(stream);
                setIsLoading(false);
              }
            })
            .catch((e) => {
              toast.error(e.message);
            });
        } catch (e: any) {
          setIsLoading(false);
          console.log('effectchange join error', e);
        }
      }
    };
    init();
    return () => {
      console.log('effectchange leaving the session...');
      ZoomVideo.destroyClient();
      // navigate(-1);
    };
  }, [
    meetingData?.token,
    meetingData?.meeting.locationRef,
    name,
    ZoomWebEndpoint,
  ]);

  const onConnectionChange = React.useCallback(
    (payload: any) => {
      if (payload.state === ConnectionState.Reconnecting) {
        setIsLoading(true);
        setIsFailover(true);
        const { reason } = payload;
        if (reason === ReconnectReason.Failover) {
          toast.error('Session disconnected.');
          navigate(-1);
        }
      } else if (payload.state === ConnectionState.Connected) {
        if (isFailover) {
          setIsLoading(false);
        }

        console.log('getSessionInfo', zmClient.getSessionInfo());
      } else if (payload.state === ConnectionState.Closed) {
        dispatch({ type: 'reset-media' });
        if (payload.reason === 'ended by host') {
          // Modal.warning({
          //   title: 'Meeting ended',
          //   content: 'This meeting has been ended by host',
          // });

          toast.error('The session was ended by the host.');
          navigate(-1);
        }
      }
    },
    [isFailover, zmClient],
  );

  const onMediaSDKChange = React.useCallback((payload: any) => {
    const { action, type, result } = payload;
    dispatch({ type: `${type}-${action}`, payload: result === 'success' });
  }, []);

  const onPermissionChange = (payload: {
    name: 'microphone' | 'camera';
    state: 'denied' | 'granted' | 'prompt';
  }) => {
    console.log('permission', payload);

    setPermissionStatus(payload.state);
    if (
      payload.state === 'denied' ||
      (isReadyForCall && payload.state === 'prompt')
    ) {
      setGetUserMediaError(true);
    }
  };

  React.useEffect(() => {
    zmClient.on('connection-change', onConnectionChange);
    zmClient.on('media-sdk-change', onMediaSDKChange);
    zmClient.on('device-permission-change', onPermissionChange);
    return () => {
      zmClient.off('connection-change', onConnectionChange);
      zmClient.off('media-sdk-change', onMediaSDKChange);
      zmClient.off('device-permission-change', onPermissionChange);
    };
  }, [zmClient, onConnectionChange, onMediaSDKChange]);

  const zoomContextData = React.useMemo(
    () => ({
      zmClient,
      mediaStream,
      ...mediaState,
      isReadyForCall,
      setIsReadyForCall,
      meetingData,
      permissionStatus,
      leaveSession,
    }),
    [
      zmClient,
      mediaStream,
      mediaState,
      isReadyForCall,
      setIsReadyForCall,
      meetingData,
      permissionStatus,
      leaveSession,
    ],
  );

  if (getUserMediaError) {
    return <ZoomPermissionError />;
  }

  if (loading || isLoading) {
    return <Loader />;
  }

  return (
    <ZoomContext.Provider value={zoomContextData}>
      {isReadyForCall ? <VideoContainer /> : <PreviewContainer />}
    </ZoomContext.Provider>
  );
}

export default ZoomRoom;
