import moment from 'moment';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { ReactComponent as MeetingInPersonIcon } from '../../../assets/MeetingInPerson.svg';
import { ReactComponent as MeetingOnsiteIcon } from '../../../assets/MeetingOnsite.svg';
import { ReactComponent as MeetingVirtualIcon } from '../../../assets/MeetingVirtual.svg';
import {
  IAvailabilityEventItem,
  MeetingCategories,
} from '../../../shared/types/Availability';

const variants = {
  [MeetingCategories.VIRTUAL]: 'bg-cyan-200 text-cyan-800 border-l-cyan-500',
  [MeetingCategories.IN_PERSON]:
    'bg-violet-200 text-violet-800 border-l-violet-500',
  [MeetingCategories.ONSITE]:
    'bg-warning-200 text-warning-800 border-l-warning-500',
};

function AvailabilityEvent({ event }: { event: IAvailabilityEventItem }) {
  const MeetingIcon = useMemo(() => {
    switch (event?.meeting?.category) {
      case MeetingCategories.ONSITE:
        return MeetingOnsiteIcon;
      case MeetingCategories.IN_PERSON:
        return MeetingInPersonIcon;
      case MeetingCategories.VIRTUAL:
      default:
        return MeetingVirtualIcon;
    }
  }, [event]);

  const is30MinMeeting = useMemo(() => {
    const startDate = moment(event?.start);
    const endDate = moment(event?.end);

    return endDate.diff(startDate, 'minutes') <= 30;
  }, [event]);

  return (
    <div className="block w-full h-full border border-white rounded">
      <div
        role="button"
        tabIndex={0}
        className={twMerge(
          'w-full h-full rounded font-medium text-xs border-l-4 flex flex-col p-1.5 translate-all duration-500',
          event?.meeting?.category ? variants[event?.meeting?.category] : '',
          // 'focus:outline focus:outline-[#1570ef]', // outline if needed
          'focus:shadow-md',
        )}
      >
        <div className="flex gap-1 items-center justify-between">
          <span className="text-xs font-semibold truncate">
            {event?.meeting?.participantName}
          </span>
          <span>
            <MeetingIcon />
          </span>
        </div>
        {!is30MinMeeting && (
          <span className="text-xs font-medium truncate">
            {moment(event?.start).format('HH:mm A')?.toString()}
          </span>
        )}
      </div>
    </div>
  );
}

export default AvailabilityEvent;
