import { t } from 'i18next';
import { padStart } from 'lodash';
import { ReactComponent as DocFileIcon } from '../../assets/DocFile.svg';
import { ReactComponent as JPGFileIcon } from '../../assets/JPGFile.svg';
import { ReactComponent as LinkFileIcon } from '../../assets/LinkFile.svg';
import { ReactComponent as PDFFileIcon } from '../../assets/PDFFile.svg';
import { variants } from '../../shared/constant/Colors';
import { ProviderRole } from '../../shared/constant/Common';
import { ICredit } from '../../view/screens/ClientDetails/types/client';

export const getClientStatus = (status: string) => {
  const clientStatusColors: Record<
    string,
    { label: string; tooltipLabel: string; variant: keyof typeof variants }
  > = {
    '0': {
      label: 'inactive',
      tooltipLabel: 'Your client is inactive', // TODO confirm this with bhavya
      variant: 'gray',
    },
    '1': {
      label: 'active',
      tooltipLabel: 'ACTIVE_TOOLTIP',
      variant: 'success',
    },
    '2': {
      label: 'matched',
      tooltipLabel: 'MATCHED_TOOLTIP',
      variant: 'purple',
    },
    '3': {
      label: 'booked',
      tooltipLabel: 'BOOKED_TOOLTIP',
      variant: 'warning',
    },
    '4': {
      label: 'UNMATCHED_CLIENTS',
      tooltipLabel: 'UNMATCHED_TOOLTIP',
      variant: 'error',
    },
  };

  return clientStatusColors?.[status];
};

export const getProviderRole = (providerRole: string) => {
  const providerRoles: Record<string, { label: string }> = {
    [ProviderRole.COACHING]: {
      label: 'Coaching',
    },
    [ProviderRole.CLINICAL]: {
      label: 'Clinical',
    },
    [ProviderRole.HELPLINE]: {
      label: 'Helpline',
    },
    [ProviderRole.CARENAVIGATOR]: {
      label: 'Care Navigator',
    },
    [ProviderRole.NUTRITIONIST]: {
      label: 'Nutritionist',
    },
    [ProviderRole.FITNESS]: {
      label: 'Fitness',
    },
    [ProviderRole.FINANCIAL]: {
      label: 'Financial',
    },
    [ProviderRole.EXECUTIVE]: {
      label: 'Executive',
    },
    [ProviderRole.LEGAL]: {
      label: 'Coaching',
    },
    [ProviderRole.CAREOFFSITE]: {
      label: 'In-person',
    },
    [ProviderRole.ONSITE]: {
      label: 'Onsite',
    },
  };

  return providerRoles?.[providerRole] ?? 'Provider';
};

export const getFileExtension = (fileName: string): string => {
  const dotIndex = fileName.lastIndexOf('.');
  return dotIndex !== -1 ? fileName.substring(dotIndex + 1) : '';
};

export const getCustomAssignmentImgIcon = (fileName: string) => {
  const ext =
    fileName !== '' ? getFileExtension(fileName).toLowerCase() : 'link';

  switch (ext) {
    case 'jpg':
    case 'jpeg':
    case 'png':
      return JPGFileIcon;
    case 'pdf':
      return PDFFileIcon;
    case 'doc':
    case 'docx':
      return DocFileIcon;
    case 'link':
      return LinkFileIcon;
    default:
      return null;
  }
};

export const getClientCreditString = (credits: ICredit) => {
  const availableCredits = credits.credit;
  if (credits?.tier === '1') {
    return t('unlimited');
  }
  if (credits?.credit === 0 && credits?.canBook === true) {
    return t('creditPool');
  }
  if (
    (credits?.credit === 1 && credits?.canBook === true) ||
    (credits?.credit > 1 && credits?.canBook === true) ||
    (credits?.credit === 0 && credits?.canBook === false)
  ) {
    return availableCredits
      ? padStart(availableCredits.toString(), 2, '0')
      : '0';
  }
  if (credits?.credit > 0 && credits?.canBook === false) {
    return '0';
  }

  return availableCredits ? padStart(availableCredits.toString(), 2, '0') : '0';
};
