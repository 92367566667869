import * as React from 'react';
import './Header.css';
import intellectLogo from '../../../../../../assets/IntellectLogo.svg';
import { IMeetingInfo, IClientSummary } from '../../../../../../shared/types/response/provider';
import Button from '../../../../../components/Button';
import SideDrawer from '../../../../../components/SideDrawer';
import ClientDetail from '../../../../ClientDetails';

export default function ZoomHeader({
  meetingData,
}: {
  meetingData: IMeetingInfo;
}) {
  const [showClientDetails, setShowClientDetails] = React.useState(false);

  const getClientSummary = () => {
    const {
      meeting: { providerRole, participants },
    } = meetingData;
    const summary: IClientSummary = {
      providerRole,
      createdAt: participants?.[0].createdAt,
      userId: participants?.[0].userId,
      user: {
        friendlyName: participants?.[0].friendlyName,
        tags: participants?.[0].tags,
        createdAt: participants?.[0].createdAt,
      },
    };

    return summary;
  };

  return (
    <header>
      <SideDrawer
        show={showClientDetails}
        hideHandler={() => setShowClientDetails(false)}
      >
        {!!showClientDetails && (
          <ClientDetail clientSummary={getClientSummary()} isInSession />
        )}
      </SideDrawer>
      <div className="header-section">
        <img src={intellectLogo} className="logo" alt="intellect logo" />
        <Button
          onClick={() => setShowClientDetails(true)}
          label="Client details"
          variant="primary"
        />
      </div>
    </header>
  );
}
