import React from 'react';
import './CalenderView.scss';
import moment from 'moment';
import { motion } from 'framer-motion';
import Typography from '../../components/Typography';
import rightIcon from '../../../assets/right.svg';
import leftIcon from '../../../assets/left.svg';
import {
  LeaveData,
  SessionData,
} from '../../../shared/types/response/provider';
import { CalenderDay, CalenderIndicator } from '../../../shared/types/calender';
import {
  VideoRoomSDKs,
  VirtualRoomSDKTypes,
} from '../../../shared/constant/App';
import { useProviderServices } from '../../../utilities/hooks/fetchHooks/provider';
import { getCalendarIndicators } from '../../../utilities/common/Provider';
import { ProviderRole } from '../../../shared/constant/Common';

interface ICalenderViewProps {
  sessions: SessionData[];
  onMonthChange: (month: string) => void;
  onCalenderDayClick: (day: string) => void;
  leaves: LeaveData[];
}

function generateContinuousDaysForCalenderView(
  noOfDays: number,
  indicator: CalenderIndicator,
): Array<CalenderDay> {
  const days: CalenderDay[] = [];
  for (let day = 1; day <= noOfDays; day += 1) {
    days.push({ day, indicator });
  }

  return days;
}

function getDaysFromDate(datesArray: { displayDate: string }[]): number[] {
  let displayDate: number[] = [];
  const displayDates = datesArray.map((session) => session.displayDate);
  const momentDates: moment.Moment[] = displayDates.map((date) => moment(date));
  displayDate = momentDates.map((momentDate) => momentDate.date());

  return displayDate;
}

function overrideIndicators(
  calenderDays: CalenderDay[],
  daysToOverride: number[],
  overridingIndicator: CalenderIndicator,
): CalenderDay[] {
  const newCalenderDays: CalenderDay[] = [];
  calenderDays.forEach((calenderDay) => {
    if (daysToOverride.includes(calenderDay.day)) {
      const newCalenderDay = {
        day: calenderDay.day,
        indicator: overridingIndicator,
      };
      newCalenderDays.push(newCalenderDay);
    } else {
      newCalenderDays.push(calenderDay);
    }
  });

  return newCalenderDays;
}

function getCalenderValues(
  calenderViewDate: moment.Moment,
  providerSessions: SessionData[],
  leaves: LeaveData[],
): Array<CalenderDay> {
  let calenderValues = [];
  const noOfWeekdays = 7;
  const todayDate = moment();
  // ! need to confirm date.startOf('month').isoWeekday(); is working as expected for edge cases.
  // TODO can improve this flow by having a pipeline for indicator flow
  const weekDay = calenderViewDate.startOf('month').isoWeekday();
  const noOfDaysCurrentMonth = calenderViewDate.daysInMonth();

  const inPersonDays = [] as SessionData[];
  const virtualDays = [] as SessionData[];
  const onsiteDays = [] as SessionData[];
  const virtualAndInpersonDays = [] as SessionData[];
  const virtualAndOnsiteDays = [] as SessionData[];
  const inPersonAndOnsiteDays = [] as SessionData[];
  const virtualAndInpersonAndOnsiteDays = [] as SessionData[];

  providerSessions.forEach((session) => {
    const virtualSessionsExist = providerSessions.some(
      (s) =>
        s.displayDate === session.displayDate &&
        VirtualRoomSDKTypes.includes(s.type),
    );
    const inPersonSessionsExist = providerSessions.some(
      (s) =>
        s.displayDate === session.displayDate &&
        s.type === VideoRoomSDKs.f2f &&
        s.providerRole !== ProviderRole.ONSITE,
    );
    const onsiteSessionsExist = providerSessions.some(
      (s) =>
        s.displayDate === session.displayDate &&
        s.type === VideoRoomSDKs.f2f &&
        s.providerRole === ProviderRole.ONSITE,
    );

    if (virtualSessionsExist && inPersonSessionsExist && onsiteSessionsExist) {
      virtualAndInpersonAndOnsiteDays.push(session);
    } else if (virtualSessionsExist && inPersonSessionsExist) {
      virtualAndInpersonDays.push(session);
    } else if (inPersonSessionsExist && onsiteSessionsExist) {
      inPersonAndOnsiteDays.push(session);
    } else if (virtualSessionsExist && onsiteSessionsExist) {
      virtualAndOnsiteDays.push(session);
    } else if (inPersonSessionsExist) {
      inPersonDays.push(session);
    } else if (onsiteSessionsExist) {
      onsiteDays.push(session);
    } else {
      virtualDays.push(session);
    }
  });
  const leaveDays = getDaysFromDate(leaves);

  let currentMonthDays = generateContinuousDaysForCalenderView(
    noOfDaysCurrentMonth,
    'default',
  );

  currentMonthDays = overrideIndicators(
    currentMonthDays,
    getDaysFromDate(virtualDays),
    'virtual',
  );
  currentMonthDays = overrideIndicators(
    currentMonthDays,
    getDaysFromDate(inPersonDays),
    'in-person',
  );
  currentMonthDays = overrideIndicators(
    currentMonthDays,
    getDaysFromDate(onsiteDays),
    'onsite',
  );
  currentMonthDays = overrideIndicators(
    currentMonthDays,
    getDaysFromDate(virtualAndInpersonAndOnsiteDays),
    'virtual-inperson-onsite',
  );
  currentMonthDays = overrideIndicators(
    currentMonthDays,
    getDaysFromDate(virtualAndInpersonDays),
    'inperson-virtual',
  );
  currentMonthDays = overrideIndicators(
    currentMonthDays,
    getDaysFromDate(virtualAndOnsiteDays),
    'virtual-onsite',
  );
  currentMonthDays = overrideIndicators(
    currentMonthDays,
    getDaysFromDate(inPersonAndOnsiteDays),
    'inperson-onsite',
  ); // * applying 'scheduled' indication for calender days
  currentMonthDays = overrideIndicators(
    currentMonthDays,
    leaveDays,
    'on-leave',
  ); // * applying 'on-leave' indication for calender days

  if (todayDate.month() === calenderViewDate.month()) {
    currentMonthDays = overrideIndicators(
      currentMonthDays,
      [todayDate.date()],
      'today',
    ); // * applying 'today' indication for calender days
  }

  if (weekDay === 7) {
    //  * No need to add top padding if the weekday starts from sunday.
    calenderValues = [...currentMonthDays];
  } else {
    const noOfDaysInPreviousMonth = calenderViewDate
      .subtract(1, 'month')
      .daysInMonth();
    const previousMonthDays = generateContinuousDaysForCalenderView(
      noOfDaysInPreviousMonth,
      'disabled',
    );

    const topPaddingLength = noOfWeekdays - (noOfWeekdays - weekDay);
    const topPadding = previousMonthDays.splice(-topPaddingLength);

    calenderValues = [...topPadding, ...currentMonthDays];
  }

  const bottomPaddingLength =
    noOfWeekdays - (calenderValues.length % noOfWeekdays);
  if (bottomPaddingLength !== noOfWeekdays) {
    // * No need to add bottom padding if the month ending day is on saturday
    const bottomPadding = generateContinuousDaysForCalenderView(
      bottomPaddingLength,
      'disabled',
    );
    calenderValues = [...calenderValues, ...bottomPadding];
  }

  return calenderValues;
}

function CalenderView({
  sessions,
  onMonthChange,
  onCalenderDayClick,
  leaves,
}: ICalenderViewProps) {
  const [currentMonth, setCurrentMonth] = React.useState(() =>
    moment().toISOString(),
  );
  const [calenderValues, setCalenderValues] = React.useState<CalenderDay[]>([]);
  const [selectedDay, setSelectedDay] = React.useState<number>(() =>
    moment().date(),
  );
  const maximumPossibleUpcomingSessionsMonthsLookup = 2;

  const { data: providerServices } = useProviderServices();

  React.useEffect(() => {
    onMonthChange(currentMonth);
    // ? can memoization applied for getCalenderValues()?
    setCalenderValues(
      getCalenderValues(moment(currentMonth), sessions, leaves),
    );
  }, [currentMonth]);

  React.useEffect(() => {
    setCalenderValues(
      getCalenderValues(moment(currentMonth), sessions, leaves),
    );
  }, [sessions, leaves]);

  const onPreviousMonthClick = () => {
    const today = moment();
    const selectedMonth = moment(currentMonth);

    // disabling showing slots history
    if (!today.isSame(selectedMonth, 'months')) {
      setCurrentMonth((currentMonthState) =>
        moment(currentMonthState).subtract(1, 'month').toISOString(),
      );
    }
  };

  const onNextMonthClick = () => {
    const today = moment();
    const selectedMonth = moment(currentMonth);
    const maximumSelectableMonth = today.add(
      maximumPossibleUpcomingSessionsMonthsLookup - 1,
      'months',
    );
    if (selectedMonth.isBefore(maximumSelectableMonth)) {
      setCurrentMonth((currentMonthState) =>
        moment(currentMonthState).add(1, 'month').toISOString(),
      );
    }
  };

  const onCalenderViewDayClick = (
    event: React.MouseEvent<Element, MouseEvent>,
    calenderDay: CalenderDay,
  ): void => {
    const { day, indicator } = calenderDay;
    if (indicator !== 'disabled') {
      setSelectedDay(day);
      onCalenderDayClick(moment(currentMonth).set('date', day).toISOString());
    }
  };

  return (
    <div className="calenderview-container">
      <div className="controls">
        <motion.div
          className="control-item"
          whileHover={{ scale: 1.5 }}
          whileTap={{ scale: 0.9 }}
          onClick={onPreviousMonthClick}
        >
          <img src={leftIcon} alt="previous month" />
        </motion.div>
        <Typography color="primary" weight="600" size={20}>
          {moment(currentMonth).format('MMM YYYY')}
        </Typography>
        <motion.div
          className="control-item"
          whileHover={{ scale: 1.5 }}
          whileTap={{ scale: 0.9 }}
          onClick={onNextMonthClick}
        >
          <img src={rightIcon} alt="next month" />
        </motion.div>
      </div>
      <div className="calenderview">
        {moment.weekdaysShort().map((day) => (
          <Typography size={14} weight="600" color="primary" key={day}>
            {day}
          </Typography>
        ))}
        {calenderValues.map((item, index) => (
          <div
            className={`day ${
              item.day === selectedDay && item.indicator !== 'disabled'
                ? 'selected'
                : ''
            }`}
            key={`${item.day}-${item.indicator}`}
            onClick={(event) => onCalenderViewDayClick(event, item)}
            role="button"
            tabIndex={index}
          >
            <div className={`item ${item.indicator}`}>
              <Typography
                weight="600"
                size={16}
                color={item.indicator === 'today' ? 'light' : 'primary'}
              >
                {item.day}
              </Typography>
            </div>
          </div>
        ))}
      </div>
      <div className="indicators">
        {providerServices
          ? getCalendarIndicators(providerServices.services).map(
              (indicator) => (
                <div
                  className="indicator"
                  key={indicator.label + JSON.stringify(indicator.style)}
                >
                  <div className="color-circle" style={indicator.style} />
                  <Typography size={12} weight="400" color="primary">
                    {indicator.label}
                  </Typography>
                </div>
              ),
            )
          : null}
      </div>
    </div>
  );
}

export default CalenderView;
