import { PropsWithChildren, ReactNode } from 'react';
import Modal from '../../../components/Modal';
import tickAnim from '../../../../assets/tickAnimation.mp4';
import { Button } from '../../../components/Button';

interface BookSuccessModalProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  subtitle?: string;

  /** The photo/video shown at the top. Defaults to a tick animation. */
  image?: ReactNode;

  submitButtonText?: string;
}
export function ResultModal({
  open,
  handleClose,
  title,
  subtitle,
  children,
  image,
  submitButtonText = 'Okay',
}: PropsWithChildren<BookSuccessModalProps>) {
  return (
    <Modal open={open} handleClose={handleClose}>
      <div className="w-[50vw] overflow-auto flex flex-col items-center">
        {image ?? (
          <video autoPlay muted className="w-52 h-44">
            <source src={tickAnim} type="video/mp4" />
          </video>
        )}
        <div className="mb-8 mt-4 text-center w-2/3">
          <div className="text-displaySm mb-2 font-semibold text-gray-900">
            {title}
          </div>
          {subtitle ? (
            <div className="text-xl text-gray-600">{subtitle}</div>
          ) : null}
        </div>

        {children}
      </div>
      <Button className="mt-8 w-full" onClick={handleClose}>
        {submitButtonText}
      </Button>
    </Modal>
  );
}
