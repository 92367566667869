import { clone, isEqual } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  EventNames,
  EventActions,
  EventCategories,
} from '../../../../shared/constant/Analytics';
import { OverrideVariant } from '../../../../shared/constant/DailyOverideVariant';
import {
  IProviderDaySlot,
  ISlotRange,
} from '../../../../shared/types/response/provider';
import { APIDateFormat } from '../../../../utilities/common/Date';
import { useProviderServices } from '../../../../utilities/hooks/fetchHooks/provider';
import useMonthlyDailyOverrides from '../../../../utilities/hooks/useMonthlyDailyOverride/useMonthlyDailyOverrides';
import useOutOfOffice from '../../../../utilities/hooks/useOutOfOffice/useOutOfOffice';
import useTracking from '../../../../utilities/hooks/useTracking';
import { useUpdateSlots } from '../../../../utilities/hooks/useUpdateSlots/useUpdateSlots';
import InformationModal from '../../../../view/app-components/InformationModal';
import LeaveDateMarker from '../../../../view/app-components/LeaveDateMarker';
import Banner from '../../../../view/components/Banner';
import Loader from '../../../../view/components/Loader';
import Typography from '../../../../view/components/Typography';
import { useFetch } from '../../../../utilities/hooks/useFetch';
import { getProviderSlotsV2 } from '../../../../shared/redux/actions/provider';
import fetchClient from '../../../../utilities/hooks/useFetch/fetchClient';
import WorkingHoursSlot from './WorkingHoursSlot';

export function WorkingHours() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { track } = useTracking();
  const { isOnLeave, leaveCheckLoading, fetchOnLeave } = useOutOfOffice();
  const { data: overriddenDates } = useMonthlyDailyOverrides(
    moment().format('YYYY/MM'),
  );

  const [showLeaveMarker, setShowLeaveMarker] = useState(false);

  const { data: slots } = useFetch<Record<string, IProviderDaySlot>>(
    ['provider-slots'],
    () => dispatch(getProviderSlotsV2()),
  );

  const showOOOBanner =
    JSON.stringify(slots) !== '{}' ||
    !!overriddenDates?.filter(
      (item) => item?.[1] === OverrideVariant.BothOverride,
    ).length;
  const [outOfOfficeDisclaimer, setOutOfOfficeDisclaimer] =
    React.useState<boolean>(false);

  const { data: providerServices, isLoading: providerServicesLoading } =
    useProviderServices();

  const { mutateAsync: saveSlots } = useUpdateSlots({
    refreshData: () => fetchClient.invalidateQueries(['provider-slots']),
  });

  const onRemoveSlot = async (day: string, removedSlot: ISlotRange) => {
    const updatedSlots = clone(slots) || {};
    updatedSlots[Number(day)].slotsRange = updatedSlots[
      Number(day)
    ].slotsRange.filter((slot) => !isEqual(slot, removedSlot));

    await saveSlots({
      slotMap: updatedSlots,
      clinicsList: providerServices?.clinics || [],
    });
  };

  const onAddSlot = async (day: string, newSlot: ISlotRange) => {
    let updatedSlots = clone(slots) || {};
    if (updatedSlots[Number(day)]) {
      updatedSlots[Number(day)].slotsRange.push(newSlot);
    } else {
      updatedSlots = {
        ...updatedSlots,
        [day]: { dayDisplay: '', slots: {}, slotsRange: [newSlot], day },
      };
    }

    const response = await saveSlots({
      slotMap: updatedSlots,
      clinicsList: providerServices?.clinics || [],
    });

    return response;
  };

  const onClearSlots = async (day: string) => {
    const updatedSlots = { ...slots };
    if (updatedSlots[Number(day)]) {
      updatedSlots[Number(day)].slotsRange = [];
      saveSlots({
        slotMap: updatedSlots,
        clinicsList: providerServices?.clinics || [],
      });
      if (slots && Object.keys(slots).length === 1) {
        setOutOfOfficeDisclaimer(true);
      }
    }
  };

  const triggerMarkOnLeave = () => {
    track(EventNames.workingHours, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.onLeave,
      eventLabel: 'on_leave',
      featureVersion: 'v1',
      eventSource: 'on_leave',
    });
    setShowLeaveMarker(true);
  };

  React.useEffect(() => {
    fetchOnLeave(moment().format(APIDateFormat));
    track(EventNames.workingHours, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.workingHours,
      eventLabel: 'working_hours',
      featureVersion: 'v1',
    });
  }, []);

  if (leaveCheckLoading || providerServicesLoading || !providerServices) {
    return <Loader />;
  }

  return (
    <div>
      {showOOOBanner && (
        <Banner
          primaryContent={
            isOnLeave?.leave?.onLeave
              ? t('BANNER_STATE_OOO')
              : t('BANNER_STATE_AVAILABLE')
          }
          onClick={triggerMarkOnLeave}
          buttonLabel={t('MARK_AS_ON_LEAVE')}
          bannerVariant={isOnLeave?.leave?.onLeave ? 'on-leave' : 'available'}
          fontSize={14}
          uniqueKey="BANNER_DISCLAIMER"
          toolTipText={t('BANNER_DISCLAIMER')}
          toolTipClass="banner-tooltip"
        />
      )}
      <div className="working-hours-data">
        <div className="slots-header-container">
          <div className="table-header">
            <Typography
              withColor="#263E58"
              size={12}
              weight="600"
              color="primary"
            >
              {t('day')}
            </Typography>
          </div>
          <div className="table-header">
            <Typography
              withColor="#263E58"
              size={12}
              weight="600"
              color="primary"
            >
              {t('LOCATION')}
            </Typography>
          </div>
          <div className="table-header">
            <Typography
              withColor="#263E58"
              size={12}
              weight="600"
              color="primary"
            >
              {t('from')}
            </Typography>
          </div>
          <div className="table-header">
            <Typography
              withColor="#263E58"
              size={12}
              weight="600"
              color="primary"
            >
              {t('to')}
            </Typography>
          </div>
          <div className="table-header" />
        </div>
        <div className="slots-container">
          {moment.weekdays().map((weekday, index) => (
            <WorkingHoursSlot
              day={weekday}
              key={weekday}
              data={
                slots?.[index] || {
                  day: index.toString(),
                  dayDisplay: '',
                  slots: {},
                  slotsRange: [],
                }
              }
              onRemoveSlot={onRemoveSlot}
              onAddSlot={onAddSlot}
              onSlotsClear={onClearSlots}
              providerServices={providerServices}
            />
          ))}
        </div>
      </div>
      {showLeaveMarker && (
        <LeaveDateMarker
          show={showLeaveMarker}
          closeHandler={() => setShowLeaveMarker(false)}
          onSucessClick={() => {}}
        />
      )}
      <InformationModal
        headerText={t('MARK_AS_ON_LEAVE')}
        bodyText={t('MARK_AS_ON_LEAVE_POPUP_SUBTEXT')}
        onClose={() => setOutOfOfficeDisclaimer(false)}
        show={outOfOfficeDisclaimer}
        leftBtnLabel={t('EXIT')}
        rightBtnLabel={t('MARK_AS_ON_LEAVE')}
        borderRadius="100px"
        leftBtnVariant="secondary"
        fontSize={14}
        fontWeight="600"
        width="221px"
        height="45px"
        headerFontSize={24}
        contentFontSize={16}
        customBtnContainerStyle="custom-style"
        leftBtnClick={() => setOutOfOfficeDisclaimer(false)}
        rightBtnClick={() => {
          setShowLeaveMarker(true);
          setOutOfOfficeDisclaimer(false);
          track(EventNames.workingHours, {
            eventAction: EventActions.click,
            eventCategory: EventCategories.onLeave,
            eventLabel: 'on_leave',
            featureVersion: 'v1',
            eventSource: 'schedule_removed',
          });
        }}
      />
    </div>
  );
}
