import { Participant } from '@zoom/videosdk';

export const filterDuplicatesUser = (
  data: Participant[],
): { filteredData: Participant[]; hasDuplicates: boolean } => {
  const { filteredData, hasDuplicates } = data.reduce(
    (acc, item) => {
      if (item.userIdentity) {
        if (acc.customUserIds.has(item.userIdentity)) {
          acc.hasDuplicates = true;
        } else {
          acc.customUserIds.add(item.userIdentity);
          acc.filteredData.push(item);
        }
      }
      return acc;
    },
    {
      filteredData: [] as Participant[],
      customUserIds: new Set<string>(),
      hasDuplicates: false,
    },
  );
  return { filteredData, hasDuplicates };
};
