import * as React from 'react';
import './VideoRoomControl.scss';
import { motion } from 'framer-motion';

interface IVideoRoomControlProps {
  color: 'primary' | 'error';
  icon: string;
  altText: string;
  onClick: () => void;
  id?: string;
  disabled?: boolean;
}

function VideoRoomControl({
  color,
  icon,
  altText,
  onClick,
  id,
  disabled,
}: IVideoRoomControlProps) {
  return (
    <motion.button
      className={`room-control ${color}`}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={onClick}
      id={id}
      disabled={disabled}
    >
      <img src={icon} alt={altText} className="icon" />
    </motion.button>
  );
}

export default VideoRoomControl;
