/* eslint-disable react/prop-types */
import * as React from 'react';
import './Tile.css';
import { DailyVideo } from '@daily-co/daily-react';
import Username from '../Username/Username';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/**
 *
 * @param {viewType} viewType 'full'| 'tile'
 * @returns
 */
export default function Tile({ id, isScreenShare, isLocal, viewType }) {
  // let containerCssClasses = isScreenShare ? 'tile-screenshare' : 'tile-video';

  // if (isLocal) {
  //   containerCssClasses += ' self-view';
  // } else {
  //   containerCssClasses += ' remote-participant-view';
  // }

  return (
    <div className={`${viewType} ${isScreenShare && 'screenShared'}`}>
      <DailyVideo
        automirror
        sessionId={id}
        type={viewType === 'screen' ? 'screenVideo' : 'video'}
      />
      <Username
        id={id}
        isLocal={isLocal}
        isScreenShare={viewType === 'screen'}
      />
    </div>
  );
}
