import moment from 'moment';
import lodash from 'lodash';
import { IProviderDaySlot, ISlots } from '../../shared/types/Availability';

export const ClientProfileDateFormat = 'DD MMM yyyy, hh:mm A';
export const SessionTimeFormat = 'hh:mm A';
export const ClientMatchedOnDateFormat = 'DD MMM, yyyy';
export const ClientHelplineRecordDateFormat = 'DD MMM yyyy';
export const ClientHelplineRecordTimeFormat = 'hh:mm A';

export const HelplineMeetingDateFormat = 'DD MMM, YYYY ddd';
export const HelplineMeetingJaDateFormat = 'MM,DD,YYYY,dddd';
export const HelplineMeetingTimeFormat = 'hh:mm a';
export const HelplineMeetingJaTimeFormat = 'HH:mm';

export const getTimeFromMilliSeconds = (milliSeconds: number, format: string) =>
  moment.unix(milliSeconds).format(format);

const formatNumberTo2Digits = (input: number) => `0${input}`.slice(-2);

const convertDayMapToSlotRange = (
  input: Record<string, Omit<IProviderDaySlot, 'slotsRange'>>,
) => {
  const weekDays = lodash.clone(input) as Record<string, IProviderDaySlot>;

  Object.keys(weekDays).forEach((key: string) => {
    weekDays[key].slotsRange = [];
    Object.entries(weekDays[key].slots).forEach(
      ([locationIdKey, slotsForLocationId]) => {
        if (slotsForLocationId.length) {
          weekDays[key].slots[locationIdKey] = slotsForLocationId.sort(
            (a: number, b: number) => a - b,
          );

          let current = slotsForLocationId[0];
          let last = slotsForLocationId[0];

          slotsForLocationId.forEach((cur: number, index: number) => {
            if (index === 0) {
              // skip
            } else if (last === cur - 1) {
              last = cur;
            } else {
              weekDays[key].slotsRange.push({
                locationId: locationIdKey === 'virtual' ? null : locationIdKey,
                slots: [current, last],
              });

              current = cur;
              last = cur;
            }
          });

          weekDays[key].slotsRange.push({
            locationId: locationIdKey === 'virtual' ? null : locationIdKey,
            slots: [current, last],
          });
        }
      },
    );
  });

  return weekDays;
};

/**
 * Generates 30 minutes time
 * @param slot
 * @returns {string}
 */
export const slotToTime = (slot: number) =>
  `${formatNumberTo2Digits(
    +Math.floor((+slot * 30) / 60).toFixed(0),
  )}:${formatNumberTo2Digits((+slot * 30) % 60)}`;

export const getSlotsByWeekDay = (slotDays: ISlots[], timeZone: string) => {
  const offset = moment.tz(timeZone).utcOffset();

  const weekDays = slotDays.reduce(
    (
      res: Record<string, Omit<IProviderDaySlot, 'slotsRange'>>,
      cur: ISlots,
    ) => {
      if (cur.slots) {
        cur.slots.split(',').forEach((slot: string) => {
          const time = moment(
            `${cur.dayOfWeek} ${slotToTime(+slot)}`,
            'd HH:mm',
          ).add(offset, 'minute');

          const day = time.format('d');
          const locationId = cur.locationId || 'virtual';

          if (!res[day]) {
            res[day] = {
              day,
              dayDisplay: time.format('dddd'),
              slots: { [locationId]: [], virtual: [] },
            };
          }

          if (!res[day].slots[locationId]) {
            res[day].slots[locationId] = [];
          }

          res[day].slots[locationId].push(
            +time.format('HH') * 2 +
              +Math.floor(+time.format('mm') / 30).toFixed(0),
          );
        });
      }
      return res;
    },
    {},
  );
  return convertDayMapToSlotRange(weekDays);
};

export const getSlotsByDate = (slotDays: any[], timeZone: string) => {
  const offset = moment.tz(timeZone).utcOffset();

  const weekDays = slotDays.reduce((res: any, cur: any) => {
    if (cur.slots) {
      cur.slots.split(',').forEach((slot: string) => {
        const time = moment(
          `${cur.date} ${slotToTime(+slot)}`,
          'YYYY/MM/DD HH:mm',
        ).add(offset, 'minute');

        const formattedTime = time.format('YYYY/MM/DD');
        const locationId = cur.locationId || 'virtual';

        if (!res[formattedTime]) {
          res[formattedTime] = {
            day: formattedTime,
            dayDisplay: formattedTime,
            slots: { [locationId]: [], virtual: [] },
            id: cur.id,
            variant: cur.variant,
            localDate: cur?.localDate ?? '',
            slotIds: cur?.id ? [cur.id] : [],
          };
        }

        if (!res[formattedTime].slots[locationId]) {
          res[formattedTime].slots[locationId] = [];
        }

        res[formattedTime].slots[locationId].push(
          +time.format('HH') * 2 +
            +Math.floor(+time.format('mm') / 30).toFixed(0),
        );

        if (
          res?.[formattedTime]?.slotIds &&
          cur?.id &&
          !res?.[formattedTime]?.slotIds?.includes(cur?.id)
        ) {
          res[formattedTime].slotIds.push(cur.id);
        }
      });
    }
    return res;
  }, {});
  return convertDayMapToSlotRange(weekDays);
};
