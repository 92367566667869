import { twMerge } from 'tailwind-merge';
import RiskLevelEditIcon from './RiskLevelEditIcon';

interface RiskLabelProps {
  onClick?: () => void;
  isLocked?: boolean;
}

function RiskEmptyState({ onClick, isLocked = false }: RiskLabelProps) {
  return (
    <section
      className={twMerge(
        'w-full p-3 flex items-center justify-between border-l-2 border-bl-[5px] border-solid rounded-r-lg',
        'bg-gray-50',
        'border-gray-500',
      )}
    >
      <section className={twMerge('w-3/5 text-xs', 'text-gray-900')}>
        No risk level added! Please add one now to set priority for this client
      </section>
      <RiskLevelEditIcon onClick={onClick} isLocked={isLocked} />
    </section>
  );
}

export default RiskEmptyState;
