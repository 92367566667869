import { useContext } from 'react';
import BasicCardTemplate from '../../components/BasicCardTemplate';
import { Tag } from '../../components/Tag';
import Tooltip from '../../components/Tooltip';
import ClientDetailsContext from './context/ClientDetailsContext';
import InfoTile from '../../components/InfoTile';
import ClientOverviewCardLoader from './ClientOverviewCardLoader';

export default function UserTagsCard() {
  const { clientDetailData, isLoading } = useContext(ClientDetailsContext);

  const clientTags = clientDetailData?.tags ?? [];

  if (isLoading) {
    return <ClientOverviewCardLoader variant="card" />;
  }

  return (
    <BasicCardTemplate heading="Tags" showToolTip={false}>
      {/* <section className="grid grid-cols-2 gap-2"> */}
      {!clientTags?.length ? (
        <InfoTile lineClamp={2} variant="gray" content="No tags added yet!" />
      ) : (
        <section className="flex flex-wrap gap-2">
          {clientTags.map((tag, index) => (
            <>
              <Tag
                key={tag}
                className="truncate max-w-[165px] text-xs text-center"
                variant="primary"
                tooltipId={`${tag}-${index}`}
              >
                {tag}
              </Tag>
              <Tooltip id={`${tag}-${index}`} content={tag} />
            </>
          ))}
        </section>
      )}
    </BasicCardTemplate>
  );
}
