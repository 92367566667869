const productionHostName = 'partner.intellect.co';

export const getCurrentPath = (): string => window.location.pathname; // TODO check RRv6 APIs that supports classes
export const beautifyUrl = (url: string, replacements: string[]): string => {
  let beautifiedUrl = url;
  for (let i = 0; i < replacements.length; i += 1) {
    beautifiedUrl = beautifiedUrl.replace('{?}', replacements[i]);
  }

  return beautifiedUrl;
};

export const gotoRoute = (path: string) => {
  window.location.href = path;
};

export const refreshApp = () => {
  window.location.reload();
};

// TODO isProduction() relies on url, needs to tweak build scripts to make it rely on node env
export const isProduction = () =>
  window.location.hostname === productionHostName;
