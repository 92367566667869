import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { IFollowUpSessionsFormSections } from '../../FollowUpSession.type';
import Typography from '../../../../components/Typography';
import TextArea from '../../../../components/TextEditor';
import { MAX_CHAR_LIMIT_TEXTAREA } from '../../../../../shared/constant/generics';
import ReferCarer from '../../ReferCarer';
import { externalUrls } from '../../../../../shared/Config';
import { Recommendations } from '../../../../../shared/constant/CoachingCaseNotes';
import FollowUpSessionCaseNotesControls from '../../FollowUpSessionCaseNotes.controls';
import './Recommendation.scss';
import { IOptions } from '../../../../../shared/types/CaseNotes';
import Heading from '../../Heading';

interface IResponseToIntervention {
  headerContent?: React.ReactNode;
  recommendationsOptions: IOptions[];
}

function ResponseToIntervention({
  watch,
  setValue,
  headerContent,
  recommendationsOptions,
  question,
  trackOnBlur,
  trackOnFocus,
}: IFollowUpSessionsFormSections & IResponseToIntervention) {
  const { t } = useTranslation();
  const selectedOptions: Array<IOptions | any> = watch('recommendations') || [];
  const selectedOpt = React.useMemo(
    () => selectedOptions.map(({ id }) => Number(id)),
    [watch('recommendations')],
  );
  const mappedOpt = React.useMemo(
    () =>
      FollowUpSessionCaseNotesControls.getMappedOptionsWithId(
        recommendationsOptions,
      ),
    [recommendationsOptions],
  );
  const [additionalRec, setAdditionalRec] = React.useState<{
    showRefer: boolean;
    showText: boolean;
  }>({
    showRefer: false,
    showText: false,
  });
  React.useEffect(() => {
    if (JSON.stringify(selectedOptions) !== '{}') {
      const addOpt = {
        showRefer: additionalRec.showRefer,
        showText: additionalRec.showText,
      };
      const hasReferral = selectedOptions.find(
        ({ value }) => value === Recommendations.Referral,
      );
      const reqAddSupport = selectedOptions.find(
        ({ value }) => value === Recommendations.NeedsAdditionalSupport,
      );
      addOpt.showRefer = Boolean(hasReferral);
      addOpt.showText = Boolean(reqAddSupport);
      setAdditionalRec(() => addOpt);
    }
  }, [watch('recommendations')]);
  const handleShowRefer = () => {
    window.open(externalUrls.referral.typeformLink, '_blank', 'noreferrer');
  };
  const handleChange =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const isCheckBoxChecked = e.target.checked;
      e.stopPropagation();
      if (setValue) {
        if (isCheckBoxChecked) {
          if (JSON.stringify(selectedOptions) === '{}') {
            setValue('recommendations', [mappedOpt[key]]);
          } else {
            setValue('recommendations', [...selectedOptions, mappedOpt[key]]);
          }
        } else {
          const filteredData = (selectedOptions || []).filter(
            (opt) => opt.id !== key,
          );

          setValue('recommendations', filteredData);
        }
      }
    };

  return (
    <div className="recommendation">
      <section className="primary recommendation-section">
        {headerContent || (
          <Heading headerText={question?.question} toolTipID="8" />
        )}
        <div
          className="check-list"
          onFocus={trackOnFocus}
          onBlur={() => trackOnBlur?.(question?.id)}
        >
          {(recommendationsOptions || []).map(({ label, id }) => (
            <div key={`${label}-${id}`} className="checkboxField">
              <input
                type="checkbox"
                checked={selectedOpt.includes(Number(id))}
                onChange={handleChange(id)}
                id={id}
                value={label}
              />
              <label htmlFor={id}>
                <Typography size={14} weight="500" withColor="#636C73">
                  {label}
                </Typography>
              </label>
            </div>
          ))}
        </div>
        <div>
          {additionalRec.showRefer && (
            <ReferCarer
              onClick={handleShowRefer}
              from="rmd"
              showRedirectArrow
              description={t('REFERRAL_INSTRUCTION')}
              btnLabel={`${t('REFERRAL_CTA', { arrowcode: '' })}`}
            />
          )}
          {additionalRec.showText && (
            <motion.div
              initial={{ opacity: 0, translateY: '-100%' }}
              animate={{ opacity: 1, translateY: '0px' }}
              exit={{ opacity: 0, translateY: '-100%' }}
              transition={{ duration: 0.5 }}
              key="recommendations"
            >
              <TextArea
                parametersValue={[]}
                defaultTemplate={watch('recommendationText') as string}
                onTextValueChange={(value) => {
                  if (setValue) setValue('recommendationText', value);
                }}
                maxCharacterLimit={MAX_CHAR_LIMIT_TEXTAREA}
                placeHolderText={t('ADDITIONAL_SUPPORT_DESC')}
                showMaxCharText={false}
              />
            </motion.div>
          )}
        </div>
      </section>
    </div>
  );
}

export default ResponseToIntervention;
