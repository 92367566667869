import classNames from 'classnames';
import { useContext } from 'react';
import videoOff from '../../../../../assets/videoOff.svg';
import videoOn from '../../../../../assets/videoOn.svg';
import ZoomContext from '../context/ZoomContext';
import VideoRoomControl from '../../../VideoRoomControl';

interface CameraButtonProps {
  isStartedVideo: boolean;
  // isBlur?: boolean;
  onCameraClick: () => void;
  // onSwitchCamera: (deviceId: string) => void;
  // onBlurBackground?: () => void;
  className?: string;
  // cameraList?: MediaDevice[];
  // activeCamera?: string;
}
function CameraButton(props: CameraButtonProps) {
  const {
    isStartedVideo,
    className,
    // cameraList,
    // isBlur,
    onCameraClick,
    // onSwitchCamera,
    // onBlurBackground,
    // activeCamera,
  } = props;

  const { permissionStatus } = useContext(ZoomContext);

  // const [showCamMenu, setShowCamMenu] = useState(false);

  // const onMenuItemClick = (payload: { key: any }) => {
  //   if (payload.key === 'blur') {
  //     onBlurBackground?.();
  //   } else {
  //     onSwitchCamera(payload.key);
  //   }
  // };
  // const cameraMenuList =
  //   cameraList?.map((item) => ({
  //     label: item.label,
  //     value: item.deviceId,
  //   })) || [];
  // mediaStream?.isSupportVirtualBackground()
  //   ? getAntdItem('Blur My Background', 'blur', isBlur && <CheckOutlined />)
  //   : getAntdItem('Mask My Background', 'blur'),

  return (
    <div className={classNames('camera-footer', className)}>
      {/* {isStartedVideo && menuItems ? (
        <Dropdown.Button
          className="vc-dropdown-button"
          size="large"
          menu={getAntdDropdownMenu(menuItems, onMenuItemClick)}
          onClick={onCameraClick}
          trigger={['click']}
          icon={<UpOutlined />}
          placement="topRight"
        >
          <VideoCameraOutlined />
        </Dropdown.Button>
      ) : (
        <Tooltip title={`${isStartedVideo ? 'stop camera' : 'start camera'}`}>
          <Button
            className={classNames('vc-button', className)}
            icon={
              isStartedVideo ? (
                <VideoCameraOutlined />
              ) : (
                <VideoCameraAddOutlined />
              )
            }
            ghost
            shape="circle"
            size="large"
            onClick={onCameraClick}
          />
        </Tooltip>
      )} */}
      <div className={classNames('microphone-camera-footer', className)}>
        {/* {isStartedAudio ? (
        <DropdownButton
          className="vc-dropdown-button"
          size="large"
          menu={getAntdDropdownMenu(menuItems, onMenuItemClick)}
          onClick={onMicrophoneClick}
          trigger={['click']}
          icon={<UpOutlined />}
          placement="topRight"
          disabled={disabled}
        >
          {audioIcon}
        </DropdownButton>
      ) : (
        <Tooltip title={tooltipText}>
          <Button
            className="vc-button"
            icon={audioIcon}
            size="large"
            ghost
            shape="circle"
            onClick={onMicrophoneClick}
          />
        </Tooltip>
      )} */}

        <VideoRoomControl
          id="zoom-camera-button"
          color="primary"
          altText={isStartedVideo ? 'Unmute mic' : 'Mute mic'}
          icon={isStartedVideo ? videoOn : videoOff}
          onClick={onCameraClick}
          disabled={permissionStatus !== 'granted'}
        />
        {/* {cameraMenuList.length ? (
          <button type="button" onClick={() => setShowCamMenu(true)}>
            <img src={settings} alt="show mic options" width="16px" />
          </button>
        ) : null}

        <Tooltip
          anchorSelect="#zoom-camera-button"
          place="top"
          content={isStartedVideo ? 'stop camera' : 'start camera'}
        />

        {showCamMenu ? (
          <Popup withBackdrop onClose={() => setShowCamMenu(false)}>
            <div className="mic-camera-menu-popup">
              {cameraMenuList.length ? (
                <div>
                  <Typography size={16} weight="600">
                    Camera
                  </Typography>
                  <SelectBoxV2
                    selectedValue={activeCamera || ''}
                    values={cameraMenuList}
                    onChange={(value) => onMenuItemClick({ key: value })}
                  />
                </div>
              ) : null}
            </div>
          </Popup>
        ) : null} */}
      </div>
    </div>
  );
}
export default CameraButton;
