import { twMerge } from 'tailwind-merge';
import { Tag } from './Tag';
import { variants } from '../../shared/constant/Colors';
import { ReactComponent as QuestionMarkIcon } from '../../assets/QuestionMark.svg';
import Tooltip from './Tooltip';

interface IStatusChip {
  value: string;
  variant?: keyof typeof variants;
  label: string;
  tooltipLabel?: string;
  iconStroke?: string;
  className?: string;
}
function StatusChip({
  value,
  variant = 'primary',
  className,
  label,
  tooltipLabel,
  iconStroke = 'currentColor',
}: IStatusChip) {
  return (
    <Tag
      variant={variant}
      className={twMerge(
        'flex items-center justify-center gap-1 px-2.5 py-1.5 leading-[10px]',
        className,
      )}
      tooltipId={`beacon-${value}`}
    >
      {label}

      <QuestionMarkIcon stroke={iconStroke} />
      <Tooltip id={`beacon-${value}`} content={tooltipLabel || label} />
    </Tag>
  );
}

export default StatusChip;
