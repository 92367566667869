import moment from 'moment';
import { ReactComponent as ClockIcon } from '../../../assets/clock.svg';
import { ReactComponent as CloseIcon } from '../../../assets/close.svg';
import { ReactComponent as LocationIcon } from '../../../assets/location.svg';
import { ReactComponent as PlaneIcon } from '../../../assets/Plane.svg';
import { Button } from '../../components/Button';
import DateBadge from '../../components/DateBadge';
import Popper from '../../components/Popper/Popper';
import UnOrderedList from '../../components/UnOrderedList';

function MeetingInfoPopup({
  anchorEl,
  handleClose,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  meetingId,
}: {
  anchorEl: Element | null;
  handleClose: () => void;
  meetingId?: string;
}) {
  // TODO meeting details API call, call only when meetingId & anchorEL is available

  return (
    anchorEl && (
      <Popper anchorEl={anchorEl} handleClose={handleClose} placement="left">
        <div className="w-[24vw] max-w-[90vw] max-h-[90vh] rounded-2xl bg-white relative mr-2 shadow-xl p-4 flex flex-col gap-6">
          <div className="w-full flex items-start justify-between font-semibold leading-7 text-gray-900 w-100">
            <div className="w-full flex-8 flex gap-2 truncate">
              <DateBadge timeStamp={moment().unix()} />
              <div className="flex flex-col truncate min-w-0">
                Jane/ Jack Liu
                <UnOrderedList
                  items={[
                    {
                      label: '09:00 AM - 09:30 AM',
                      startIcon: (
                        <ClockIcon className="w-3 h-3 text-primary-700" />
                      ),
                    },
                    {
                      label: 'Onsite',
                      startIcon: (
                        <PlaneIcon className="w-3 h-3 text-cyan-700" />
                      ),
                    },
                  ]}
                />
                <UnOrderedList
                  items={[
                    {
                      label: 'Intellect Clinic @Tanjong',
                      startIcon: (
                        <LocationIcon className="w-3 h-3 text-orange-600" />
                      ),
                    },
                  ]}
                />
              </div>
            </div>

            <Button
              variant="ghost"
              className="flex-2 w-8 h-8 p-2 focus:bg-gray-200"
              onClick={handleClose}
              autoFocus={false}
            >
              <CloseIcon className="text-gray-600" />
            </Button>
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-md font-semibold">Session Details</span>
            <div className="w-[90%] flex flex-col gap-2">
              <div className="flex">
                <span className="flex-1 text-xs text-gray-500 font-medium">
                  Booked By
                </span>
                <span className="flex-1 text-xs font-medium">
                  Care Navigator
                </span>
              </div>
              <div className="flex">
                <span className="flex-1 text-xs text-gray-500 font-medium">
                  Client
                </span>
                <span className="flex-1 text-xs font-medium">
                  Ivar Ragnarsson
                </span>
              </div>
              <div className="flex">
                <span className="flex-1 text-xs text-gray-500 font-medium">
                  Session Status
                </span>
                <span className="flex-1 text-xs font-medium">Confirmed</span>
              </div>
              <div className="flex">
                <span className="flex-1 text-xs text-gray-500 font-medium">
                  Presenting issue
                </span>
                <span className="flex-1 text-xs font-medium">Pending</span>
              </div>
              <div className="flex">
                <span className="flex-1 text-xs text-gray-500 font-medium">
                  Client Not arrived yet?
                </span>
                <span className="flex-1 text-xs font-medium">Mark No Show</span>
              </div>
            </div>
          </div>

          <hr />
          <div className="flex flex-col gap-2">
            <Button className="w-full">Start Session</Button>
            <Button className="w-full" variant="secondary">
              View case notes
            </Button>
          </div>
        </div>
      </Popper>
    )
  );
}

export default MeetingInfoPopup;
