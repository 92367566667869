import Config from '../../../../shared/Config';
import NetworkClient from '../../../../shared/service/Network';

const client = new NetworkClient();

const errorMessages = {
  generic: 'Unable to fetch client details, please try again!',
};

export const getProviderMeetings = async (
  fromDate: string,
  toDate: string,
  timezone: string,
) => {
  const response = await client.doGet(
    Config.apiV2.availability.getMeetings,
    {
      timezone,
    },
    { from: fromDate, to: toDate },
  );
  if (response && response.data) {
    return response?.data?.meetings;
  }

  throw new Error(errorMessages.generic);
};

export const getProviderAvailabilities = async () => {
  const response = await client.doGet(
    Config.apiV2.availability.getAvailability,
  );
  if (response && response?.data) {
    return response?.data;
  }

  throw new Error(errorMessages.generic);
};

export const getProviderServicesAndClinics = async () => {
  const response = await client.doGet(
    Config.apiV2.availability.getServicesAndClinics,
  );
  if (response && response?.data) {
    return response?.data;
  }

  throw new Error(errorMessages.generic);
};

export const getProviderOverrides = async () => {
  const response = await client.doGet(Config.apiV2.availability.getOverrides);
  if (response && response?.data) {
    return response?.data;
  }

  throw new Error(errorMessages.generic);
};
