import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { isArray } from 'lodash';
import moment, { Moment, weekdays } from 'moment';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SlotInfo, View, Views } from 'react-big-calendar';
import { useNavigate } from 'react-router-dom';
import { getTimeZone } from '../../../../utilities/common/Date';
import { ReactComponent as ChevronDown } from '../../../assets/ChevronDown.svg';
import { ReactComponent as GoogleIcon } from '../../../assets/GoogleIcon.svg';
import { ReactComponent as IntellectOnlyLogo } from '../../../assets/IntellectOnlyLogo.svg';
import { ReactComponent as OutlookIcon } from '../../../assets/OutlookIcon.svg';
import {
  AvailabilityEventType,
  IAvailabilityEventItem,
  IProviderDaySlot,
  IProviderRawOverriddenSlots,
  IProviderTransformedOverrides,
  MeetingCategories,
  ProviderOverrideVariant,
  SlotCategories,
} from '../../../shared/types/Availability';
import {
  getSlotsByDate,
  getSlotsByWeekDay,
} from '../../../utilities/common/Date';
import {
  encodeQueryStrings,
  useQueryString,
} from '../../../utilities/hooks/useQueryString';
import AvailabilityCalendar from '../../app-components/AvailabilityCalendar/AvailabilityCalendar';
import AvailabilityEvent from '../../app-components/AvailabilityCalendar/AvailabilityEvent';
import AvailabilityHeader from '../../app-components/AvailabilityCalendar/AvailabilityHeader';
import AvailabilityToolbar from '../../app-components/AvailabilityCalendar/AvailabilityToolbar';
import AvailabilityWorkingHoursEvent from '../../app-components/AvailabilityCalendar/AvailabilityWorkingHoursEvent';
import MeetingInfoPopup from '../../app-components/MeetingInfoPopup/MeetingInfoPopup';
import { Button } from '../../components/Button';
import CheckboxWithLabel from '../../components/CheckboxWithLabel';
import { DatePicker } from '../../components/DatePicker';
import { Select } from '../../components/Select';
import {
  useProviderAvailabilities,
  useProviderOverrides,
  useProviderServicesAndClinics,
} from './hooks/UseAvailabilities';
import { useProviderMeetings } from './hooks/UseMeetings';
import AvailabilityMeetingFilterBox from '../../app-components/AvailabilityCalendar/AvailabilityMeetingFilterBox';

const ProviderTimezone = getTimeZone();

moment.tz.setDefault(ProviderTimezone);

function getMeetingCategory(meetingProvideRole: string) {
  switch (meetingProvideRole) {
    case '10':
      return MeetingCategories.IN_PERSON;
    case '12':
      return MeetingCategories.ONSITE;
    case '0':
    case '1':
    default:
      return MeetingCategories.VIRTUAL;
  }
}

function EventComponents({ event }: { event: IAvailabilityEventItem }) {
  switch (event?.type) {
    case AvailabilityEventType.WORKING_HOURS_SLOT:
    case AvailabilityEventType.OVERRIDDEN_SLOT:
      return <AvailabilityWorkingHoursEvent event={event} />;
    case AvailabilityEventType.MEETING:
    default:
      return <AvailabilityEvent event={event} />;
  }
}

const dateStringBuilder = (startDate: Moment, endDate: Moment) => ({
  fromDate: startDate.format('YYYY-MM-DD'),
  toDate: endDate.add(1, 'd').format('YYYY-MM-DD'),
});

function Availability() {
  const history = useNavigate();
  const queryString: Record<string, any> = useQueryString({
    arrayFormat: 'separator',
    arrayFormatSeparator: ',',
  });

  // --------------- STATE MANAGEMENT ---------------
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  );

  const [dateString, setDateString] = useState<{
    fromDate: string;
    toDate: string;
  }>(
    queryString?.fromDate && queryString?.toDate
      ? { fromDate: queryString.fromDate, toDate: queryString.toDate }
      : dateStringBuilder(moment().startOf('week'), moment().endOf('week')),
  );

  const [calendarSelectedDate, setCalendarSelectedDate] = useState(
    moment().toDate(),
  );

  const [selectedCalendarView, setSelectedCalendarView] = useState<View>(
    queryString?.calendarView || 'week',
  );

  const [selectedMeeting, setSelectedMeeting] = useState<string | undefined>(
    undefined,
  );

  const [filters, setFilters] = useState<{
    virtual: boolean;
    inPerson: string[];
    onSite: boolean;
    leaves: boolean;
  }>({
    virtual: true,
    inPerson: ['1'],
    onSite: true,
    leaves: true,
  });

  const [workingHoursSlots, setWorkingHoursSlots] = useState<
    Record<string, IProviderDaySlot>
  >({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rawOverriddenSlots, setRawOverriddenSlots] = useState<
    IProviderRawOverriddenSlots[]
  >([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rawBlockedSlots, setRawBlockedSlots] = useState<
    IProviderRawOverriddenSlots[]
  >([]);
  const [overriddenSlots, setOverriddenSlots] = useState<
    IProviderTransformedOverrides[]
  >([]);
  const [blockedSlots, setBlockedSlots] = useState<
    IProviderTransformedOverrides[]
  >([]);
  const [holidays, setHolidays] = useState<IProviderTransformedOverrides[]>([]);

  // --------------- NETWORK CALLS ---------------
  const { data: providerMeetings } = useProviderMeetings(
    moment(dateString.fromDate).format('YYYY/MM/DD').toString(),
    moment(dateString.toDate).format('YYYY/MM/DD').toString(),
    ProviderTimezone,
  );
  const { data: availability } = useProviderAvailabilities();
  const { data: overrides } = useProviderOverrides();
  const { data: servicesAndClinics } = useProviderServicesAndClinics();

  // --------------- DATA SETTERS & LISTENERS ---------------
  useEffect(() => {
    const params: Record<string, any> = {
      ...queryString,
      ...dateString,
    };

    history({ search: encodeQueryStrings(params) }, { replace: true });
  }, [dateString]);

  useEffect(() => {
    setDateString(
      dateStringBuilder(
        moment(calendarSelectedDate).startOf('week'),
        moment(calendarSelectedDate).endOf('week'),
      ),
    );
  }, [calendarSelectedDate]);

  useEffect(() => {
    if (availability?.slots) {
      setWorkingHoursSlots(
        getSlotsByWeekDay(availability?.slots, ProviderTimezone),
      );
    }
  }, [availability]);

  useEffect(() => {
    if (overrides?.overrides) {
      const rawSlots = overrides?.overrides ?? [];
      const OverriddenSlots = rawSlots?.filter(
        (item: any) =>
          ![
            ProviderOverrideVariant.Leave,
            ProviderOverrideVariant.Block,
            ProviderOverrideVariant.BlockExternal,
          ]?.includes(item?.variant),
      );
      const BlockedSlots = rawSlots?.filter(
        (item: any) => item?.variant === ProviderOverrideVariant.Block,
      );

      const HolidaysSlots = rawSlots?.filter(
        (item: any) => item?.variant === ProviderOverrideVariant.Leave,
      );
      setRawOverriddenSlots(OverriddenSlots ?? []);
      setRawBlockedSlots(BlockedSlots ?? []);

      const transformedOverriddens: IProviderTransformedOverrides[] =
        Object.values(
          getSlotsByDate(OverriddenSlots, ProviderTimezone),
        ) as any[];

      const transformedBlocked: IProviderTransformedOverrides[] = Object.values(
        getSlotsByDate(BlockedSlots, ProviderTimezone),
      ) as any[];

      const transformedHolidays: IProviderTransformedOverrides[] =
        Object.values(getSlotsByDate(HolidaysSlots, ProviderTimezone)) as any[];

      setOverriddenSlots(transformedOverriddens);
      setBlockedSlots(transformedBlocked);
      setHolidays(transformedHolidays);
    }
  }, [overrides]);

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      inPerson: servicesAndClinics?.clinics?.map((item) => item?.id) ?? [],
    }));
  }, [servicesAndClinics?.clinics]);

  // --------------- HANDLERS ---------------
  const handleSelectSlot = (slotInfo: SlotInfo) => {
    console.log('slotInfo:', slotInfo);
  };

  const handleSelectEvent = (
    event: IAvailabilityEventItem,
    e: SyntheticEvent<HTMLElement>,
  ) => {
    e.preventDefault();
    setSelectedMeeting(event?.meeting?.id);
    if (event?.type === AvailabilityEventType.MEETING) {
      setReferenceElement(e.currentTarget);
    }
  };

  const handleTodayBtnClick = () => setCalendarSelectedDate(moment().toDate());

  const handleViewChange = (data: string) =>
    setSelectedCalendarView(data as View);

  // --------------- BUSINESS LOGIC ---------------
  /**
   * Prepares availability data according to given week starting dates
   * weeklyDates: {string[]} [2024-08-25]
   */
  const prepareAvailableSlotsData = useCallback(
    (weeklyDates: string[], selectedLocationId = '') =>
      weeklyDates.reduce((result: IAvailabilityEventItem[], date) => {
        const slotsData = weekdays().reduce(
          (slotsResult: IAvailabilityEventItem[], weekday, index) => {
            const momentDate = moment(date).day(weekday).startOf('day');
            // Note: removed ...holidays as in partial leaves provider is available in rest of the timings according to their slots
            const isOverriddenSlotExist = [...overriddenSlots]?.some(
              (item) =>
                moment(item?.day, 'YYYY/MM/DD')
                  .format('YYYY/MM/DD')
                  .toString() ===
                momentDate.clone().format('YYYY/MM/DD').toString(),
            );

            if (
              workingHoursSlots?.[index]?.slotsRange?.length &&
              !isOverriddenSlotExist
            ) {
              const data: {
                start: Date;
                end: Date;
                title: string;
                type: AvailabilityEventType;
                location: string | null;
                slotInfo: { slot: IProviderDaySlot; category: SlotCategories };
              }[] = [];

              [...(workingHoursSlots?.[index]?.slotsRange ?? [])]
                .sort((a, b) => (a?.slots?.[0] > b?.slots?.[0] ? 1 : -1))
                .forEach((slot) => {
                  const timeRange = {
                    startTime: slot?.slots?.[0] ?? '',
                    endTime: slot?.slots?.[1]
                      ? (slot?.slots?.[1] ?? 0) + 1
                      : '',
                  };

                  if (
                    selectedLocationId === '' ||
                    slot?.locationId === selectedLocationId ||
                    (slot?.locationId === null && selectedLocationId === '0')
                  ) {
                    const obj = {
                      start: momentDate
                        .clone()
                        .add(timeRange.startTime * 30, 'minutes')
                        .toDate(),
                      end: momentDate
                        .clone()
                        .add(+(timeRange?.endTime ?? 1) * 30, 'minutes')
                        .subtract(timeRange?.endTime === 48 ? 1 : 0, 'minutes')
                        .toDate(),
                      title: 'Available',
                      type: AvailabilityEventType.WORKING_HOURS_SLOT,
                      slotInfo: {
                        slot: workingHoursSlots?.[index],
                        category: slot?.locationId
                          ? SlotCategories.IN_PERSON
                          : SlotCategories.VIRTUAL,
                      },
                      location: slot?.locationId ?? null,
                    };
                    data.push(obj);
                  }
                });

              slotsResult.push(...data);
            }

            return slotsResult;
          },
          [],
        );

        result.push(...slotsData);
        return result;
      }, []),
    [workingHoursSlots, dateString, overriddenSlots],
  );

  const WorkingHoursBGEvents = useMemo(
    () => prepareAvailableSlotsData([dateString.fromDate]),
    [workingHoursSlots, dateString, overriddenSlots, blockedSlots],
  ); // TODO more dependency here

  const OverriddenBGEvents = useMemo(
    () =>
      [...overriddenSlots]?.reduce(
        (result: IAvailabilityEventItem[], override) => {
          const momentDate = moment(override?.day, 'YYYY/MM/DD').startOf('day');
          const isOverriddenSlotExist = holidays?.some(
            (item) =>
              moment(item?.day, 'YYYY/MM/DD')
                .format('YYYY/MM/DD')
                .toString() ===
              momentDate.clone().format('YYYY/MM/DD').toString(),
          );

          const SameDayBlockedSlots = blockedSlots?.filter(
            (item) =>
              moment(item?.day, 'YYYY/MM/DD')
                .format('YYYY/MM/DD')
                .toString() ===
              momentDate.clone().format('YYYY/MM/DD').toString(),
          );

          console.log('SameDayBlockedSlots:', SameDayBlockedSlots);

          if (
            override?.slotsRange?.length &&
            !(
              isOverriddenSlotExist &&
              (
                [
                  ProviderOverrideVariant.Override,
                  ProviderOverrideVariant.OverrideInPerson,
                ] as string[]
              ).includes(override.variant)
            )
          ) {
            const data: {
              start: Date;
              end: Date;
              title: string;
              type: AvailabilityEventType;
              location: string | null;
              slotInfo: {
                slot: IProviderTransformedOverrides;
                category: SlotCategories;
              };
            }[] = [];

            [...(override?.slotsRange ?? [])]
              .sort((a, b) => (a?.slots?.[0] > b?.slots?.[0] ? 1 : -1))
              .forEach((slot) => {
                const timeRange: {
                  startTime: number | string;
                  endTime: number | string;
                } = {
                  startTime: slot?.slots?.[0] ?? '',
                  endTime: slot?.slots?.[1] ?? '',
                };
                // is current timerange appearing in blocked range
                // [
                //   {
                //     locationId: null,
                //     slots: [9, 12],
                //   },
                //   {
                //     locationId: null,
                //     slots: [17, 20],
                //   },
                //   {
                //     locationId: null,
                //     slots: [26, 32],
                //   },
                // ]
                // SameDayBlockedSlots?.[0]?.slotsRange?.forEach(
                //   (blockedRange) => {
                //     // [3,12] =>> [9,12]
                //     const [blockedStart,blockedEnd] = blockedRange
                //     if(timeRange?.startTime <= blockedStart)
                //   },
                // );

                timeRange.endTime = timeRange?.endTime
                  ? +(timeRange.endTime ?? 0) + 1
                  : '';
                // (slot?.slots?.[1] ?? 0) + 1
                console.log('timerange:', timeRange);

                const location = slot?.locationId ?? null;
                const isOverridden = (
                  [
                    ProviderOverrideVariant.Override,
                    ProviderOverrideVariant.OverrideInPerson,
                  ] as string[]
                ).includes(override?.variant);

                const obj = {
                  start: moment(override.day, 'YYYY/MM/DD')
                    .startOf('day')
                    .add(+(timeRange.startTime ?? 1) * 30, 'minutes')
                    .toDate(),
                  end: moment(override.day, 'YYYY/MM/DD')
                    .startOf('day')
                    .add(+(timeRange?.endTime ?? 1) * 30, 'minutes')
                    .subtract(timeRange?.endTime === 48 ? 1 : 0, 'minutes')
                    .toDate(),
                  title: `${isOverridden ? 'Overridden' : 'Blocked'}`,
                  type: AvailabilityEventType.OVERRIDDEN_SLOT,
                  location,
                  slotInfo: {
                    slot: override,
                    category: slot?.locationId
                      ? SlotCategories.IN_PERSON
                      : SlotCategories.VIRTUAL,
                  },
                };

                data.push(obj);
              });

            result.push(...data);
          }

          return result;
        },
        [],
      ),
    [overriddenSlots, blockedSlots, holidays, dateString],
  );
  // console.log('overrides:', overrides);
  console.log('overriddenSlots:', overriddenSlots);
  // console.log('Overridden BG Events:', OverriddenBGEvents);
  console.log('Blocked BG Events:', blockedSlots);

  const ProviderMeetings: IAvailabilityEventItem[] = useMemo(
    () =>
      providerMeetings
        ?.filter((meeting) => {
          if (!meeting) return false; // Guard clause for undefined or null meeting

          const { providerRole } = meeting;

          if (filters.virtual && ['0', '1'].includes(providerRole)) {
            return true;
          }

          if (filters.onSite && providerRole === '12') {
            return true;
          }

          // TODO include location ids here
          if (
            filters.inPerson?.length &&
            providerRole === '10' &&
            filters.inPerson?.includes(meeting?.location?.locationId)
          ) {
            return true;
          }

          return false;
        })
        ?.map((meeting) => ({
          start: moment.unix(+meeting.scheduledStartTime).toDate(),
          end: moment.unix(+meeting.scheduledEndTime).toDate(),
          type: AvailabilityEventType.MEETING,
          meeting: {
            id: meeting.id,
            category: getMeetingCategory(meeting.providerRole),
            participantName: meeting.friendlyName,
          },
        })) ?? [],
    [providerMeetings, filters],
  );

  return (
    <article
      className="flex flex-col m-[36px] gap-[1rem]"
      style={{ width: 'calc(100% - 72px)', fontFamily: 'Inter, sans-serif' }} // TODO temporary
    >
      <section className="flex flex-col gap-4">
        <section className="w-full flex">
          <div className="flex-1 flex flex-col">
            <span className="text-displayXs font-semibold">Availability</span>
            <span className="text-sm font-normal text-gray-600 max-w-[60%]">
              Manage your time easily! View your upcoming sessions and available
              slots, and make changes with ease
            </span>
          </div>
          <div className="flex-1 flex gap-2 justify-end">
            <Button variant="secondary" onClick={handleTodayBtnClick}>
              Today
            </Button>
            <Select
              options={[
                { label: 'Week', value: Views.WEEK },
                { label: 'Day', value: Views.DAY },
              ]}
              selected={selectedCalendarView}
              onSelect={handleViewChange}
            />
            <Button
              variant="primary"
              className="bg-primary-50 text-primary-600 border border-primary-100 hover:bg-primary-200/50"
            >
              Working hours
            </Button>
          </div>
        </section>

        <section className="flex items-start justify-start gap-4">
          <section className="flex-3 flex flex-col gap-4">
            <section>
              <DatePicker
                enabledDays={[]}
                selected={calendarSelectedDate}
                onSelect={(newDate) =>
                  newDate && setCalendarSelectedDate(newDate)
                }
                classNames={{
                  root: 'bg-transparent z-10 text-xs',
                  day: '[&_button]:w-8 [&_button]:h-8 bg-transparent rounded-[50%] ',
                }}
              />
            </section>
            <section>
              <span className="text-sm font-bold">My calendars</span>
              <Disclosure as="div" className="p-4 tex-xs" defaultOpen>
                <DisclosureButton className="group flex w-full gap-1 items-center justify-start text-xs font-medium">
                  <ChevronDown className="text-gray-400 group-data-[hover]:text-gray-400/50 group-data-[open]:rotate-180 transition-all" />
                  <IntellectOnlyLogo />
                  <span>Intellect calendar</span>
                </DisclosureButton>
                <DisclosurePanel>
                  <AvailabilityMeetingFilterBox className="mt-2">
                    <CheckboxWithLabel
                      label="Virtual"
                      classNames={{
                        root: 'font-medium',
                        checkBox: 'data-[checked]:bg-cyan-500',
                      }}
                      checked={filters?.virtual}
                      handleOnChange={(checked) =>
                        setFilters((prev) => ({ ...prev, virtual: checked }))
                      }
                    />
                  </AvailabilityMeetingFilterBox>
                  <Disclosure as="div" className="px-4 tex-xs" defaultOpen>
                    <DisclosureButton className="group flex w-full gap-1 items-center justify-start text-xs font-medium">
                      <ChevronDown className="text-gray-400 group-data-[hover]:text-gray-400/50 group-data-[open]:rotate-180 transition-all" />
                      <AvailabilityMeetingFilterBox className="mt-0 p-0">
                        <CheckboxWithLabel
                          label="In-person"
                          classNames={{
                            root: 'font-medium',
                            checkBox: 'data-[checked]:bg-purple-500',
                          }}
                          checked={!!filters?.inPerson?.length}
                          handleOnChange={(checked) =>
                            setFilters((prev) => ({
                              ...prev,
                              inPerson: checked
                                ? servicesAndClinics?.clinics?.map(
                                    (item) => item?.id,
                                  ) ?? []
                                : [],
                            }))
                          }
                        />
                      </AvailabilityMeetingFilterBox>
                    </DisclosureButton>
                    <DisclosurePanel>
                      <AvailabilityMeetingFilterBox>
                        {servicesAndClinics?.clinics?.map((item) => (
                          <CheckboxWithLabel
                            key={item?.id}
                            label={item?.name}
                            classNames={{
                              root: 'font-medium',
                              checkBox: 'data-[checked]:bg-purple-500',
                              label: 'truncate max-w-24',
                            }}
                            checked={filters?.inPerson?.includes(item?.id)}
                            handleOnChange={(checked) =>
                              setFilters((prev) => ({
                                ...prev,
                                inPerson: checked
                                  ? [...(prev?.inPerson ?? []), item?.id]
                                  : [
                                      ...(prev?.inPerson?.filter(
                                        (locs) => locs !== item?.id,
                                      ) ?? []),
                                    ],
                              }))
                            }
                          />
                        ))}
                      </AvailabilityMeetingFilterBox>
                    </DisclosurePanel>
                  </Disclosure>

                  <AvailabilityMeetingFilterBox>
                    <CheckboxWithLabel
                      label="Onsite"
                      classNames={{
                        root: 'font-medium',
                        checkBox: 'data-[checked]:bg-warning-500',
                      }}
                      checked={filters?.onSite}
                      handleOnChange={(checked) =>
                        setFilters((prev) => ({ ...prev, onSite: checked }))
                      }
                    />
                    <CheckboxWithLabel
                      label="Leaves"
                      classNames={{
                        root: 'font-medium',
                        checkBox: 'data-[checked]:bg-gray-800',
                      }}
                      checked={filters?.leaves}
                      handleOnChange={(checked) =>
                        setFilters((prev) => ({ ...prev, leaves: checked }))
                      }
                    />
                  </AvailabilityMeetingFilterBox>
                </DisclosurePanel>
              </Disclosure>
            </section>
            <section>
              <span className="text-sm font-bold">Connect a calendar</span>

              <section className="mt-2 flex flex-col gap-2">
                <Button variant="secondary" className="w-full">
                  <div className="flex justify-center items-center gap-1">
                    <GoogleIcon />
                    Google
                  </div>
                </Button>
                <Button variant="secondary" className="w-full">
                  <div className="flex justify-center items-center gap-1">
                    <OutlookIcon />
                    Outlook
                  </div>
                </Button>
              </section>
            </section>
          </section>
          <section className="flex-7 w-full">
            <AvailabilityCalendar
              date={calendarSelectedDate}
              defaultDate={dateString?.fromDate || moment().toDate()}
              onSelectSlot={handleSelectSlot}
              onRangeChange={(obj) => {
                if (isArray(obj)) {
                  setDateString(
                    dateStringBuilder(
                      moment(obj[0]),
                      moment(obj[obj.length - 1]).endOf('day'),
                    ),
                  );
                } else {
                  setDateString(
                    dateStringBuilder(moment(obj.start), moment(obj.end)),
                  );
                }
              }}
              selectable
              backgroundEvents={[
                ...WorkingHoursBGEvents,
                ...OverriddenBGEvents,
              ]}
              events={ProviderMeetings}
              view={selectedCalendarView}
              views={['day', 'week']}
              components={{
                toolbar: AvailabilityToolbar,
                header: AvailabilityHeader,
                event: EventComponents,
              }}
              startAccessor="start"
              endAccessor="end"
              onSelectEvent={handleSelectEvent}
              formats={{ timeGutterFormat: 'HH:mm', dayFormat: 'ddd DD' }}
              scrollToTime={moment().toDate()}
            />
          </section>
        </section>
      </section>
      <MeetingInfoPopup
        anchorEl={referenceElement}
        handleClose={() => setReferenceElement(null)}
        meetingId={selectedMeeting}
      />
    </article>
  );
}

export default Availability;
