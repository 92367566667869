import { getMeetingDetails } from '../../../../shared/service/API/meeting';
import { IMeetingInfo } from '../../../../shared/types/response/provider';
import { useMutation } from '../../../../utilities/hooks/useFetch';

function useMeeting({
  onSuccess,
  onError,
}: {
  onSuccess: (meetingInfo: IMeetingInfo) => void;
  onError: (error: Error) => void;
}) {
  return useMutation(
    ({
      meetingId,
      channel,
    }: {
      meetingId: string;
      channel: string;
    }) => getMeetingDetails(meetingId, channel),
    {
      onSuccess,
      onError: (error) => onError(error as Error),
    },
  );
}

export default useMeeting;
