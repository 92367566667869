import * as React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './FloatingChatWindowV2.scss';
import FloatingWidget from '../../../../view/components/FloatingWidget/FloatingWidget';
import Loader from '../../../../view/components/Loader';
import searchIcon from '../../../../assets/search.svg';
import Typography from '../../../../view/components/Typography';
import TextBox from '../../../../view/components/TextBox';
import { useChatBroker } from '../../../broker/useChatBroker';
import Tabs from '../../../../view/components/Tabs';
import Tab from '../../../../view/components/Tab';
import ChatContact from '../ChatContactV2';
import LiveChat from '../LiveChatV2';
import {
  OldChatContactFormat,
  RecentChatMessageFormat,
} from '../../../../utilities/common/Date';
import { AllConversationListType } from '../../../data-types/ChatBrokerTypes';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../shared/constant/Analytics';
import useTracking from '../../../../utilities/hooks/useTracking';

export interface FloatingChatWindowV2Props {
  show: boolean;
  initialUser?: number | null;
  viewMode?: 'full-view' | 'chat-view';
}

function FloatingChatWindowV2({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  show,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initialUser,
  viewMode = 'chat-view',
}: FloatingChatWindowV2Props) {
  const { t } = useTranslation();
  const { track } = useTracking();
  const [selectedChat, setSelectedChat] =
    React.useState<AllConversationListType>({} as AllConversationListType);

  const { isLoading, allConversationList, liveChatData } = useChatBroker();

  const [clientsSearchText, setClientsSearchText] = React.useState('');

  const [parentDimensions, setParentDimensions] = React.useState<DOMRect>();
  const pageNo = React.useRef<number>(1);

  const userConversationList = React.useMemo(() => {
    if (!clientsSearchText) {
      return allConversationList;
    }
    return {
      recent: allConversationList?.recent.filter((contact) =>
        contact.friendlyName.toLowerCase().startsWith(clientsSearchText),
      ),
      oldChat: allConversationList?.oldChat.filter((contact) =>
        contact.friendlyName.toLowerCase().startsWith(clientsSearchText),
      ),
    };
  }, [allConversationList, clientsSearchText]);

  const parentMeasuredRef = React.useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setParentDimensions(node.getBoundingClientRect());
    }
  }, []);

  const getLastMessagedTimeLabel = (
    currentDate: moment.Moment,
    lastMessagedDate: moment.Moment,
  ): string => {
    const isSameDay = currentDate.startOf('day') <= lastMessagedDate;
    const isYesterday =
      currentDate.subtract('1', 'day').startOf('day') <= lastMessagedDate;
    if (isSameDay) {
      return lastMessagedDate.format(RecentChatMessageFormat);
    }
    if (isYesterday) {
      return t('yesterday');
    }

    return lastMessagedDate.format(OldChatContactFormat);
  };

  function onConversationSelect(conversation: AllConversationListType) {
    setSelectedChat(conversation);
    pageNo.current = 1;
    liveChatData.getSelectedUserMessages({
      selectedConversationId: conversation.conversationId,
      channelId: conversation.channelId,
      pageNo: pageNo.current,
      channelType: conversation.channelType,
    });
  }

  React.useEffect(() => {
    if (initialUser && allConversationList) {
      const userChatList = [
        ...allConversationList.recent,
        ...allConversationList.oldChat,
      ];
      const [filteredUser] = userChatList.filter(
        (user) => user.userId === initialUser,
      );
      setSelectedChat(filteredUser);
      onConversationSelect(filteredUser);
    }
  }, [initialUser, allConversationList]);

  return (
    <FloatingWidget initialPosition={{ right: '2rem', bottom: '5rem' }}>
      <section
        className={`chat-window-container ${viewMode}`}
        ref={parentMeasuredRef}
      >
        {isLoading && (
          <Loader useFullPage={false} dimension={parentDimensions} />
        )}
        {viewMode === 'full-view' && (
          <section className="contacts-container">
            <section className="contacts-header-container">
              <section className="contacts-header">
                <Typography size={24} weight="600" color="primary">
                  {t('chats')}
                </Typography>
              </section>
              <section className="search-bar-container">
                <TextBox
                  placeholder={t('search')}
                  icon={searchIcon}
                  variant="box"
                  animate={false}
                  value={clientsSearchText}
                  onChange={(e) => setClientsSearchText(e.target.value)}
                />
              </section>
            </section>
            <section className="contacts-tabs-container">
              <Tabs
                tabs={[
                  { key: 'recent', label: t('recent') },
                  { key: 'oldChats', label: t('archived') },
                ]}
              >
                <Tab childrenFor="recent">
                  {userConversationList?.recent?.map((conversation) => (
                    <ChatContact
                      key={conversation.conversationId}
                      name={conversation.friendlyName}
                      message={conversation.lastMessage || t('SHARED_FILE')}
                      messagesUnread={conversation.unreadCount}
                      messageConsumptionStatus={
                        conversation.unreadCount ? 'not-consumed' : 'consumed'
                      }
                      messagedTime={getLastMessagedTimeLabel(
                        moment(),
                        moment(conversation.lastMessageDate),
                      )}
                      selected={
                        selectedChat.conversationId ===
                        conversation.conversationId
                      }
                      onClick={() => {
                        onConversationSelect(conversation);
                        track(EventNames.messages, {
                          eventAction: EventActions.click,
                          eventCategory: EventCategories.viewUserChat,
                          eventLabel: 'view_user_chat',
                          eventValue: conversation?.userId.toString(),
                        });
                      }}
                    />
                  ))}
                </Tab>
                <Tab childrenFor="oldChats">
                  {userConversationList?.oldChat?.map((conversation) => (
                    <ChatContact
                      key={conversation.conversationId}
                      name={conversation.friendlyName}
                      message={conversation.lastMessage || t('SHARED_FILE')}
                      messagesUnread={conversation.unreadCount}
                      messageConsumptionStatus={
                        conversation.unreadCount ? 'not-consumed' : 'consumed'
                      }
                      messagedTime={getLastMessagedTimeLabel(
                        moment(),
                        moment(conversation.lastMessageDate),
                      )}
                      selected={
                        selectedChat.conversationId ===
                        conversation.conversationId
                      }
                      onClick={() => {
                        onConversationSelect(conversation);
                        track(EventNames.messages, {
                          eventAction: EventActions.click,
                          eventCategory: EventCategories.viewUserChat,
                          eventLabel: 'view_user_chat',
                          eventValue: conversation?.userId.toString(),
                        });
                      }}
                    />
                  ))}
                </Tab>
              </Tabs>
            </section>
          </section>
        )}
        <section className={`chat-container ${viewMode}`}>
          <LiveChat
            userMetaData={selectedChat}
            getUnReadCount={liveChatData.getSelectedUserUnreadCount}
            selectedUserMsg={liveChatData.selectedUserData}
            isLoading={liveChatData.isLoading}
            getSelectedUserMessages={liveChatData.getSelectedUserMessages}
            pageNoRef={pageNo}
            updateMessageConsumptionInfo={
              liveChatData.updateMsgConsumptionStatus
            }
            isNextMessageLoaded={liveChatData.isNextMessageLoaded}
          />
        </section>
      </section>
    </FloatingWidget>
  );
}

export default FloatingChatWindowV2;
