import { useState, useCallback, useContext, useEffect } from 'react';
import classNames from 'classnames';
import {
  MutedSource,
  AudioChangeAction,
  VideoCapturingState,
  MobileVideoFacingMode,
  ShareStatus,
  MediaDevice,
} from '@zoom/videosdk';
import { toast } from 'sonner';
import CameraButton from './CameraButton';
import MicrophoneButton from './Microphone';
import './VideoFooter.scss';
import { SELF_VIDEO_ID } from '../constants/VideoConstants';
import messageIcon from '../../../../../assets/message.svg';
import screenSharingIcon from '../../../../../assets/screen-share.svg';
import stopScreenSharingIcon from '../../../../../assets/stop-screen-share.svg';
import callEnd from '../../../../../assets/call-end.svg';
import useLayout from '../../../../../utilities/hooks/useLayout';
import VideoRoomControl from '../../../VideoRoomControl';
import { useUnmount, useMount } from '../hooks/useUnmount';
import { isIOSMobile, isAndroidOrIOSBrowser } from '../utils/platform';
import ZoomContext from '../context/ZoomContext';

interface VideoFooterProps {
  className?: string;
  selfShareCanvas?: HTMLCanvasElement | HTMLVideoElement | null;
}

const isAudioEnable = typeof AudioWorklet === 'function';
function VideoFooter(props: VideoFooterProps) {
  const { className, selfShareCanvas } = props;
  const { updateChatWindowSettings } = useLayout();
  const { mediaStream, zmClient, meetingData, leaveSession } =
    useContext(ZoomContext);
  const [isStartedAudio, setIsStartedAudio] = useState(
    zmClient.getCurrentUserInfo() && zmClient.getCurrentUserInfo().audio !== '',
  );
  const [isStartedVideo, setIsStartedVideo] = useState(
    zmClient.getCurrentUserInfo()?.bVideoOn,
  );
  const [, setAudio] = useState(zmClient.getCurrentUserInfo()?.audio);
  // const [isMirrored, setIsMirrored] = useState(false);
  const [isBlur] = useState(false);
  const [isMuted, setIsMuted] = useState(
    !!zmClient.getCurrentUserInfo()?.muted,
  );
  const [activeMicrophone, setActiveMicrophone] = useState(
    mediaStream?.getActiveMicrophone(),
  );
  const [, setActiveSpeaker] = useState(mediaStream?.getActiveSpeaker());
  const [, setActiveCamera] = useState(mediaStream?.getActiveCamera());
  const [micList, setMicList] = useState<MediaDevice[]>(
    mediaStream?.getMicList() ?? [],
  );
  const [, setSpeakerList] = useState<MediaDevice[]>(
    mediaStream?.getSpeakerList() ?? [],
  );
  const [, setCameraList] = useState<MediaDevice[]>(
    mediaStream?.getCameraList() ?? [],
  );
  const [, setIsComputerAudioDisabled] = useState(false);
  const [isMicrophoneForbidden, setIsMicrophoneForbidden] = useState(false);

  const onCameraClick = useCallback(async () => {
    if (isStartedVideo) {
      await mediaStream?.stopVideo();
      setIsStartedVideo(false);
    } else {
      const temporaryException = isIOSMobile() && window.crossOriginIsolated; // add ios mobile exception for test backward compatible.
      if (
        mediaStream?.isRenderSelfViewWithVideoElement() &&
        !temporaryException
      ) {
        const videoElement = document.querySelector(
          `#${SELF_VIDEO_ID}`,
        ) as HTMLVideoElement;
        if (videoElement) {
          await mediaStream?.startVideo({ videoElement });
        }
      } else {
        const startVideoOptions = {
          hd: true,
          fullHd: true,
          ptz: mediaStream?.isBrowserSupportPTZ(),
        };
        if (mediaStream?.isSupportVirtualBackground() && isBlur) {
          Object.assign(startVideoOptions, {
            virtualBackground: { imageUrl: 'blur' },
          });
        }
        await mediaStream?.startVideo(startVideoOptions);

        if (!mediaStream?.isSupportMultipleVideos()) {
          const canvasElement = document.querySelector(
            `#${SELF_VIDEO_ID}`,
          ) as HTMLCanvasElement;
          mediaStream?.renderVideo(
            canvasElement,
            zmClient.getSessionInfo().userId,
            canvasElement.width,
            canvasElement.height,
            0,
            0,
            3,
          );
        }
      }

      setIsStartedVideo(true);
    }
  }, [mediaStream, isStartedVideo, zmClient, isBlur]);

  const onMicrophoneClick = useCallback(async () => {
    if (isStartedAudio) {
      if (isMuted) {
        await mediaStream?.unmuteAudio();
      } else {
        await mediaStream?.muteAudio();
      }
    } else {
      try {
        await mediaStream?.startAudio();
      } catch (e: any) {
        if (
          e.type === 'INSUFFICIENT_PRIVILEGES' &&
          e.reason === 'USER_FORBIDDEN_MICROPHONE'
        ) {
          setIsMicrophoneForbidden(true);
        }
        console.warn(e);
      }
      // setIsStartedAudio(true);
    }
  }, [mediaStream, isStartedAudio, isMuted]);

  const onHostAudioMuted = useCallback(
    (payload: any) => {
      const { action, source, type } = payload;
      if (action === AudioChangeAction.Join) {
        setIsStartedAudio(true);
        setAudio(type);
        setTimeout(() => {
          setIsMuted(!!zmClient.getCurrentUserInfo()?.muted);
        }, 1000);
      } else if (action === AudioChangeAction.Leave) {
        setIsStartedAudio(false);
      } else if (action === AudioChangeAction.Muted) {
        setIsMuted(true);
        if (source === MutedSource.PassiveByMuteOne) {
          toast.info('Host muted you');
        }
      } else if (action === AudioChangeAction.Unmuted) {
        setIsMuted(false);
        if (source === 'passive') {
          toast.info('Host unmuted you');
        }
      }
    },
    [zmClient],
  );
  const onScreenShareClick = useCallback(async () => {
    try {
      if (
        mediaStream?.getShareStatus() === ShareStatus.End &&
        selfShareCanvas
      ) {
        await mediaStream?.startShareScreen(selfShareCanvas, {
          requestReadReceipt: true,
        });
      } else {
        mediaStream?.stopShareScreen();
      }
    } catch (error) {
      console.log(error);
    }
  }, [mediaStream, selfShareCanvas]);

  const onLeaveClick = useCallback(async () => {
    try {
      leaveSession();
    } catch (error) {
      console.log('Error when trying to leave session', String(error));
    }
  }, [zmClient]);

  // const onEndClick = useCallback(async () => {
  //   await zmClient.leave(true);
  //   navigate('/sessions');
  // }, [zmClient]);

  const onPassivelyStopShare = useCallback(({ reason }: any) => {
    console.log('passively stop reason:', reason);
  }, []);
  const onDeviceChange = useCallback(() => {
    if (mediaStream) {
      setMicList(mediaStream.getMicList());
      setSpeakerList(mediaStream.getSpeakerList());
      if (!isAndroidOrIOSBrowser()) {
        setCameraList(mediaStream.getCameraList());
      }
      setActiveMicrophone(mediaStream.getActiveMicrophone());
      setActiveSpeaker(mediaStream.getActiveSpeaker());
      setActiveCamera(mediaStream.getActiveCamera());
    }
  }, [mediaStream]);

  const onVideoCaptureChange = useCallback((payload: any) => {
    if (payload.state === VideoCapturingState.Started) {
      setIsStartedVideo(true);
    } else {
      setIsStartedVideo(false);
    }
  }, []);
  const onShareAudioChange = useCallback(
    (payload: any) => {
      const { state } = payload;
      if (state === 'on') {
        if (!mediaStream?.isSupportMicrophoneAndShareAudioSimultaneously()) {
          setIsComputerAudioDisabled(true);
        }
      } else if (state === 'off') {
        setIsComputerAudioDisabled(false);
      }
    },
    [mediaStream],
  );
  const onHostAskToUnmute = useCallback((payload: any) => {
    const { reason } = payload;
    console.log('Host ask to unmute the audio.', reason);
  }, []);

  const onCanSeeMyScreen = useCallback(() => {
    toast.info('Users can now see your screen');
  }, []);

  useEffect(() => {
    zmClient.on('current-audio-change', onHostAudioMuted);
    zmClient.on('passively-stop-share', onPassivelyStopShare);
    zmClient.on('device-change', onDeviceChange);
    zmClient.on('video-capturing-change', onVideoCaptureChange);
    zmClient.on('share-audio-change', onShareAudioChange);
    zmClient.on('host-ask-unmute-audio', onHostAskToUnmute);
    zmClient.on('share-can-see-screen', onCanSeeMyScreen);
    return () => {
      zmClient.off('current-audio-change', onHostAudioMuted);
      zmClient.off('passively-stop-share', onPassivelyStopShare);
      zmClient.off('device-change', onDeviceChange);
      zmClient.off('video-capturing-change', onVideoCaptureChange);
      zmClient.off('share-audio-change', onShareAudioChange);
      zmClient.off('host-ask-unmute-audio', onHostAskToUnmute);
      zmClient.off('share-can-see-screen', onCanSeeMyScreen);
    };
  }, [
    zmClient,
    onHostAudioMuted,
    onPassivelyStopShare,
    onDeviceChange,
    onVideoCaptureChange,
    onShareAudioChange,
    onHostAskToUnmute,
    onCanSeeMyScreen,
  ]);
  useUnmount(() => {
    if (isStartedAudio) {
      mediaStream?.stopAudio();
    }
    if (isStartedVideo) {
      mediaStream?.stopVideo();
    }
    mediaStream?.stopShareScreen();
  });
  useMount(() => {
    if (mediaStream) {
      if (isAndroidOrIOSBrowser()) {
        setCameraList([
          { deviceId: MobileVideoFacingMode.User, label: 'Front-facing' },
          { deviceId: MobileVideoFacingMode.Environment, label: 'Rear-facing' },
        ]);
      }
    }
  });
  return (
    <div className={classNames('video-footer', className)}>
      {isAudioEnable && (
        <MicrophoneButton
          isStartedAudio={isStartedAudio}
          isMuted={isMuted}
          onMicrophoneClick={onMicrophoneClick}
          // onMicrophoneMenuClick={onMicrophoneMenuClick}
          microphoneList={micList}
          // speakerList={speakerList}
          activeMicrophone={activeMicrophone}
          // activeSpeaker={activeSpeaker}
          // disabled={isComputerAudioDisabled}
          isMicrophoneForbidden={isMicrophoneForbidden}
        />
      )}
      <CameraButton
        isStartedVideo={isStartedVideo}
        onCameraClick={onCameraClick}
        // onSwitchCamera={onSwitchCamera}
        // onBlurBackground={onBlurBackground}
        // cameraList={cameraList}
        // activeCamera={activeCamera}
        // isBlur={isBlur}
      />
      {/* <button
        type="button"
        onClick={() => mediaStream?.updateVirtualBackgroundImage('blur')}
      >
        bg
      </button> */}
      {meetingData?.meeting.participants?.length ? (
        <VideoRoomControl
          color="primary"
          altText="chat"
          icon={messageIcon}
          onClick={() =>
            updateChatWindowSettings({
              show: true,
              viewMode: 'chat-view',
              initialUser: Number(meetingData.meeting.participants[0].userId),
            })
          }
        />
      ) : null}

      {zmClient.isHost() ? (
        <VideoRoomControl
          color="primary"
          altText={
            mediaStream?.getShareStatus() === ShareStatus.Sharing
              ? 'Share screen'
              : 'Stop sharing'
          }
          icon={
            mediaStream?.getShareStatus() === ShareStatus.Sharing
              ? stopScreenSharingIcon
              : screenSharingIcon
          }
          onClick={onScreenShareClick}
        />
      ) : null}
      <VideoRoomControl
        id="zoom-leave-meeting-button"
        color="error"
        altText="Leave call"
        icon={callEnd}
        onClick={onLeaveClick}
      />
    </div>
  );
}
export default VideoFooter;
