import moment from 'moment';
import {
  calendarLink,
  calendarList,
  calendarSync,
  calendarUnLink,
} from '../../../shared/redux/actions/provider';
import { useFetch, useMutation } from '../../../utilities/hooks/useFetch';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import useToast from '../../../utilities/hooks/useToast';

function CalendarSync() {
  const toast = useToast();

  const { data: calendarsRes, refetch } = useFetch<{ calendars: any[] }>(
    ['calendars-synced'],
    () => calendarList(),
  );

  const { mutate: getLink } = useMutation(
    () => calendarLink(window.location.href),
    {
      onSuccess: (data) => {
        window.open(data.url, '_blank');
      },
    },
  );

  const { mutate: unlink } = useMutation(
    (calendarType: string) => calendarUnLink(calendarType),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  const { mutate: sync } = useMutation(() => calendarSync(), {
    onSuccess: () => {
      toast.show({ message: 'Sync Started', closeAfter: 2000 });
      setTimeout(() => {
        refetch();
      }, 4000);
    },
  });

  return (
    <div className="p-10">
      <div className="flex justify-between mb-5  items-center">
        <Typography size={30}>Linked Calendars</Typography>
        <Button
          disabled={!!calendarsRes?.calendars?.length}
          className=""
          label="Link New"
          variant="primary"
          onClick={() => {
            getLink();
          }}
        />
      </div>
      <div className="w-full h-[1.5px] bg-gray-400" />
      {calendarsRes?.calendars?.map((c: any) => (
        <div
          key={`calendar-id-${c.calendarType}`}
          className="flex flex-1 justify-between mt-5 items-center"
        >
          <Typography size={14}>
            {c.calendarType === '0' ? 'Google' : 'Unknown'}
          </Typography>
          <Typography size={14}>
            Last synced on:{' '}
            {c.lastSyncedOn &&
              moment(c.lastSyncedOn * 1000).format('DD MMM YY, HH:mm:ss')}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="secondary"
              label="Sync Now"
              onClick={() => sync()}
            />
            <Button
              className="min-w-3"
              width="20px"
              backgroundColor="transparent"
              label="X"
              onClick={() => unlink(c.calendarType)}
              variant="caution"
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default CalendarSync;
