import {
  useRef,
  useContext,
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import ShareBar from './ShareBar';
import ShareIndicationBar from './ShareIndication';
import { useSizeCallback } from '../hooks/useSizeCallback';
import { useMount } from '../hooks/useUnmount';
import { useShare } from '../hooks/useShare';
import ZoomContext from '../context/ZoomContext';

interface ShareViewProps {
  onRecieveSharingChange: (isSharing: boolean) => void;
}
const ShareView = forwardRef((props: ShareViewProps, ref: any) => {
  const { onRecieveSharingChange } = props;
  const { mediaStream, zmClient } = useContext(ZoomContext);
  const selfShareViewRef = useRef<
  (HTMLCanvasElement & HTMLVideoElement) | null
  >(null);
  const shareViewRef = useRef<HTMLCanvasElement | null>(null);
  const shareViewContainerRef = useRef<HTMLDivElement | null>(null);
  const shareViewViewportRef = useRef<HTMLDivElement | null>(null);

  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [shareViewSize, setShareViewSize] = useState({ width: 0, height: 0 });
  // const previousViewType = usePrevious(viewType);
  // const previousShareViewSize = usePrevious(shareViewSize);
  const debounceRef = useRef(_.debounce(setContainerSize, 300));
  const {
    isRecieveSharing,
    sharedContentDimension,
    sharUserList,
    activeSharingId,
  } = useShare(zmClient, mediaStream, shareViewRef);

  const onContainerResize = useCallback(({ width, height }: any) => {
    if (shareViewContainerRef.current) {
      debounceRef.current({ width, height });
    }
  }, []);
  useMount(() => {
    if (shareViewContainerRef.current) {
      const { width, height } =
        shareViewContainerRef.current.getBoundingClientRect();
      setContainerSize({ width, height });
    }
  });
  useSizeCallback(shareViewContainerRef.current, onContainerResize);

  useEffect(() => {
    if (
      isRecieveSharing &&
      shareViewContainerRef.current &&
      containerSize.width > 0 &&
      sharedContentDimension.width > 0
    ) {
      const { width, height } = sharedContentDimension;
      setShareViewSize({
        width,
        height,
      });
    } else {
      setShareViewSize({ width: 0, height: 0 });
    }
  }, [isRecieveSharing, sharedContentDimension, containerSize]);

  // useEffect(() => {
  //   if (
  //     shareViewSize.width > 0 &&
  //     (!isShallowEqual(shareViewSize, previousShareViewSize) ||
  //       (previousViewType !== viewType &&
  //         viewType === ShareViewType.OriginalSize))
  //   ) {
  //     const pixelRatio = window.devicePixelRatio ?? 1;
  //     mediaStream?.updateSharingCanvasDimension(
  //       shareViewSize.width * pixelRatio,
  //       shareViewSize.height * pixelRatio,
  //     );
  //   }
  // }, [
  //   mediaStream,
  //   previousShareViewSize,
  //   shareViewSize,
  //   viewType,
  //   previousViewType,
  // ]);
  useImperativeHandle(
    ref,
    () => ({
      selfShareRef: selfShareViewRef.current,
    }),
    [],
  );
  useEffect(() => {
    onRecieveSharingChange(isRecieveSharing);
  }, [isRecieveSharing, onRecieveSharingChange]);
  return (
    <>
      <ShareBar ref={selfShareViewRef} />
      <div
        className={classnames('share-view', {
          'share-view-in-sharing share-view-original': isRecieveSharing,
        })}
        ref={shareViewContainerRef}
      >
        {isRecieveSharing && (
          <ShareIndicationBar
            sharUserList={sharUserList}
            activeSharingId={activeSharingId}
          />
        )}
        <div
          className="share-view-viewport share-view-viewport-original"
          style={{
            width: `${shareViewSize.width}px`,
            height: `${shareViewSize.height}px`,
          }}
          ref={shareViewViewportRef}
        >
          <canvas className="share-view-canvas" ref={shareViewRef} />
        </div>
      </div>
    </>
  );
});

export default ShareView;
