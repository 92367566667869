import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { twMerge } from 'tailwind-merge';
import creditCardBg from '../../../../../assets/creditCardBg.svg';
import { Button } from '../../../../components/Button';
import { ReactComponent as ChevronRightIcon } from '../../../../../assets/rightChevron.svg';
import { CreditDetailsModal } from './CreditDetailsModal';
import ClientDetailsContext from '../../context/ClientDetailsContext';
import { getClientCreditString } from '../../../../../utilities/common/Clients';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../../../../shared/constant/Analytics';
import useTracking from '../../../../../../utilities/hooks/useTracking';

const localeKeysPerRole = {
  '0': 'COACHING',
  '1': 'CLINICAL',
  '2': 'HELPLINE',
  '4': 'CARENAVIGATOR',
  '5': 'NUTRITION',
  '6': 'FITNESS',
  '7': 'FINANCE',
  '9': 'LEGAL',
};

function CreditDetailsCard() {
  const { t } = useTranslation();
  const { track } = useTracking();

  const { clientDetailData, clientName, encodedClientId } =
    useContext(ClientDetailsContext);

  const [showCreditDetailModal, setShowCreditDetailModal] =
    React.useState(false);

  const areCreditsExpired = moment(
    clientDetailData?.credits?.expiresAt,
  ).isBefore(moment.now());

  return (
    <>
      <div
        className="h-[7.5rem] max-h-[7.5rem] flex flex-col text-primary-900 w-full bg-cover bg-no-repeat rounded-lg"
        style={{
          backgroundImage: `url(${creditCardBg})`,
        }}
      >
        <div
          className="px-3 py-1 rounded-tl-lg text-sm font-medium"
          style={{
            background:
              'linear-gradient(90deg, #B2DDFF 28.51%, rgba(178, 221, 255, 0.00) 73.43%)',
          }}
        >
          {t('COVER_CARD_TITLE', {
            client_name: clientName || encodedClientId,
          })}
        </div>
        <div className="px-5 py-1 flex items-center gap-5 grow">
          <div className="text-displayMd text-primary-950 font-bold">
            {clientDetailData?.credits
              ? getClientCreditString(clientDetailData.credits)
              : null}
          </div>
          <div className="grow">
            <div className="text-displayXs mb-0.5 text-primary-950">
              {t(
                `${
                  localeKeysPerRole[
                    (clientDetailData?.credits
                      ?.type as keyof typeof localeKeysPerRole) || '0'
                  ]
                }_CREDITS_COVER_TITLE`,
              )}
            </div>

            <div
              className={twMerge(
                'text-xs',
                areCreditsExpired && 'text-orange-600',
              )}
            >
              {t(areCreditsExpired ? 'EXPIRED_ON' : 'VALID_TILL_EXPIRY', {
                date: moment(clientDetailData?.credits?.expiresAt).format(
                  'DD MMM YYYY',
                ),
              })}
              {}
            </div>
          </div>
          <Button
            variant="ghost"
            mini
            onClick={() => {
              track(EventNames.creditInfo, {
                eventAction: EventActions.click,
                eventCategory: EventCategories.creditInfo,
                eventLabel: 'credit_info',
              });
              setShowCreditDetailModal(true);
            }}
            endIcon={<ChevronRightIcon className="w-5 text-inherit" />}
          >
            {t('VIEW_DETAILS_CTA')}
          </Button>
        </div>
      </div>
      <CreditDetailsModal
        clientName={clientName}
        userId={clientDetailData?.profile.userId}
        open={showCreditDetailModal}
        handleClose={() => setShowCreditDetailModal(false)}
      />
    </>
  );
}

export default CreditDetailsCard;
