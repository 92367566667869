import './ZoomPermissionError.scss';
import linkIcon from '../../../../../../assets/redirect.svg';
import Button from '../../../../../components/Button';

export function ZoomPermissionError() {
  return (
    <div className="zoom-permission-error-container">
      <div className="error-box">
        <h1>Camera or mic blocked</h1>
        <div className="error-text">
          Make sure to allow access to your microphone and camera in your
          browser&apos;s permissions.
        </div>
        <div className="option-buttons">
          <a
            href="https://support.zoom.com/hc/en/category?id=kb_category&kb_category=31293e9a8720391089a37408dabb35b8"
            target="_blank"
            rel="noreferrer"
          >
            Get help <img src={linkIcon} width={20} alt="link to zoom help" />
          </a>

          <Button variant='blue' label="Try again" onClick={() => window.location.reload()} />
        </div>
      </div>
    </div>
  );
}
